/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { MdSave, MdArrowBack } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import InputMask from 'react-input-mask';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from 'react-md-spinner';

import NavBar from '../../components/NavBar';
import api from '../../services/api';

export default function LojaForm() {
  const history = useHistory();
  const [id, setId] = useState();
  const [EmpresaId, setEmpresaId] = useState();
  const [loja, setLoja] = useState();
  const [tipoLoja, setTipoLoja] = useState();
  const [contato, setContato] = useState();
  const [email, setEmail] = useState();
  const [cep, setCep] = useState();
  const [tipoEndereco, setTipoEndereco] = useState();
  const [logradouro, setLogradouro] = useState();
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();
  const [uf, setUf] = useState();

  const [load, setLoad] = useState(false);
  const [cepErro, setCepErro] = useState(false);
  const [loadCep, setLoadCep] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setCepErro(false);
    }, 3000);
  }, [cepErro]);

  useEffect(() => {
    if (location.state.empresa_id) {
      setEmpresaId(location.state.empresa_id);
    }
  }, []);

  useEffect(() => {
    if (location.state.loja) {
      const {
        id,
        empresa_id,
        loja,
        tipo_loja,
        contato,
        email,
        cep,
        tipo_endereco,
        logradouro,
        bairro,
        cidade,
        uf,
      } = location.state.loja;
      setId(id);
      setEmpresaId(empresa_id);
      setLoja(loja);
      setTipoLoja(tipo_loja);
      setContato(contato);
      setEmail(email);
      setCep(cep);
      setTipoEndereco(tipo_endereco);
      setLogradouro(logradouro);
      setBairro(bairro);
      setCidade(cidade);
      setUf(uf);
    }
  }, []);

  async function handleCep(cep) {
    if (cep) {
      setLoadCep(true);
      const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'fedault',
      };
      await api
        .get(`https://viacep.com.br/ws/${cep}/json/`, options)
        .then((response) => {
          const endereco = response.data;
          setLogradouro(endereco.logradouro);
          setCidade(endereco.localidade);
          setBairro(endereco.bairro);
          setTipoEndereco(endereco.unidade);
          setUf(endereco.uf);
          console.log(endereco);
        })
        .catch((error) => {
          console.log(error);
          setCepErro(true);
        })
        .finally(() => {
          setLoadCep(false);
        });
    }
  }

  async function add() {
    setLoad(true);
    const data = {
      empresa_id: EmpresaId,
      loja,
      tipo_loja: tipoLoja,
      contato: contato.replace(/[^0-9]+/g, ''),
      email,
      cep: cep.replace(/[^0-9]+/g, ''),
      tipo_endereco: tipoEndereco,
      logradouro,
      bairro,
      cidade,
      uf,
    };
    // console.log(data);
    await api
      .post('loja', data)
      .then((response) => {
        history.goBack();
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        console.log('finally');
        setLoad(false);
      });
  }

  async function up(id) {
    setLoad(true);
    await api
      .put(`loja/${id}`, {
        loja,
        tipo_loja: tipoLoja,
        contato,
        email,
        cep,
        tipo_endereco: tipoEndereco,
        logradouro,
        bairro,
        cidade,
        uf,
      })
      .then((response) => {
        history.goBack();
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (id) {
      up(id);
    } else {
      add();
    }
  }

  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-20 p-3">
        <div className="flex justify-between max-w-4xl mx-auto">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="btn btn-pink"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-pink-500">Cadastrar Loja</h1>
          <div className="px-6" />
        </div>

        <form onSubmit={handleSubmit} className="max-w-4xl mx-auto pt-8">
          <div className="sm:flex">
            <div className="sm:w-3/4 px-1">
              <label className="block text-sm text-pink-500">
                Descrição Loja
              </label>
              <input
                type="text"
                placeholder=""
                className="block input-text shadow"
                value={loja}
                onChange={(e) => setLoja(e.target.value)}
              />
            </div>

            <div className="sm:w-1/4 px-1">
              <label className="block text-sm text-pink-500">Tipo Loja</label>
              <input
                type="text"
                placeholder=""
                className="block input-text shadow"
                value={tipoLoja}
                onChange={(e) => setTipoLoja(e.target.value)}
              />
            </div>
          </div>
          <div className="sm:flex">
            <div className="sm:w-2/3 px-1">
              <label className="block text-sm text-pink-500">Contato</label>
              <NumberFormat
                format="(##) #####-####"
                mask="_"
                displayType="number"
                placeholder=""
                className="block input-text shadow"
                value={contato}
                onChange={(e) => setContato(e.target.value)}
              />
            </div>

            <div className="w-full px-1">
              <label className="block text-sm text-pink-500">Email</label>
              <input
                type="email"
                placeholder=""
                className="block input-text shadow"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className=" sm:w-2/5 px-1">
            <label className="block text-sm text-pink-500">CEP</label>
            <div className="flex">
              <div className="relative flex">
                <NumberFormat
                  format="#####-###"
                  mask="_"
                  displayType="number"
                  placeholder=""
                  className={`block input-text shadow border ${
                    cepErro ? 'border-red-400 bg-red-100' : ''
                  }`}
                  value={cep}
                  onBlur={(e) => handleCep(e.target.value)}
                  onChange={(e) => setCep(e.target.value)}
                />
                {!!loadCep && (
                  <div className="absolute right-0 top-0 p-2">
                    <Spinner size={20} singleColor="#fbb6ce" borderSize={3} />
                  </div>
                )}
              </div>
              <button
                type="button"
                onClick={() => handleCep(cep)}
                className="btn btn-pink ml-2 mt-0"
              >
                ok
              </button>
            </div>
          </div>
          <div className="sm:flex">
            <div className="sm:w-2/3 px-1">
              <label className="block text-sm text-pink-500">Logradouro</label>
              <input
                type="text"
                placeholder=""
                className="block input-text shadow"
                value={logradouro}
                onChange={(e) => setLogradouro(e.target.value)}
              />
            </div>

            <div className="sm:w-1/3 px-1">
              <label className="block text-sm text-pink-500">Bairro</label>
              <input
                type="text"
                placeholder=""
                className="block input-text shadow"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
              />
            </div>
          </div>
          <div className="sm:flex">
            <div className="sm:w-1/3 px-1">
              <label className="block text-sm text-pink-500">Tipo</label>
              <input
                type="text"
                placeholder=""
                className="block input-text shadow"
                value={tipoEndereco}
                onChange={(e) => setTipoEndereco(e.target.value)}
              />
            </div>

            <div className="sm:w-2/3 px-1">
              <label className="block text-sm text-pink-500">Cidade</label>
              <input
                type="text"
                placeholder=""
                className="block input-text shadow"
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
              />
            </div>
            <div className="sm:w-1/4 px-1">
              <label className="block text-sm text-pink-500">Uf</label>
              <InputMask
                mask="aa"
                maskChar="_"
                placeholder=""
                className="block input-text shadow"
                value={uf}
                onChange={(e) => setUf(e.target.value.toUpperCase())}
              />
            </div>
          </div>
          <button
            disabled={load}
            type="submit"
            className={`btn btn-pink flex items-center justify-center w-full ${
              load && 'disabled'
            }`}
          >
            {(load && (
              <div className="flex justify-center items-center">
                <Spinner
                  className="mx-1"
                  size={24}
                  singleColor="#fbb6ce"
                  borderSize={4}
                />
                aguarde...
              </div>
            )) || (
              <>
                <MdSave />
                <span>Salvar</span>
              </>
            )}
          </button>
        </form>
      </div>
    </>
  );
}
