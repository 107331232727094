import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import NavBar from '../../components/NavBar';

export default function FormasPagamento() {
  const history = useHistory();
  const [formaPagamento, setFormaPagamento] = useState([]);

  useEffect(() => {
    (async function loadFormaPagamento() {
      await api
        .get('formapagamento')
        .then((response) => {
          setFormaPagamento(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    })();
  }, []);

  async function handleRemove(id) {
    await api
      .delete(`formapagamento/${id}`)
      .then(() => {
        setFormaPagamento(formaPagamento.filter((forma) => forma.id !== id));
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {});
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20 max-w-3xl">
        <button
          type="button"
          onClick={() => history.push('/formaspagamento/forma')}
          className="btn btn-pink flex items-center"
        >
          <MdAdd />
          Novo
        </button>

        <MaterialTable
          title="Formas Paramento"
          columns={[
            {
              title: 'Id',
              field: 'id',
              hidden: true,
            },

            {
              title: 'FormaPag',
              field: 'forma_pagamento',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'MaxParcelas',
              field: 'max_parcelas',
            },
            {
              title: 'Observação',
              field: 'observacao',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Ativo',
              field: 'ativo',
              lookup: { 1: 'Ativo', 0: 'Inativo' },
            },
          ]}
          data={formaPagamento}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Editar Forma Pagamento',
              onClick: (event, rowData) =>
                history.push('/formaspagamento/forma', { data: rowData }),
            },
            /**
            (rowData) => ({
              icon: "delete",
              tooltip: "Remover Forma Pargamento",
              onClick: (event, rowData) => {
                handleRemove(rowData.id);
              },
              disabled: rowData.birthYear < 2000,
            }), */
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: true,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </div>
    </>
  );
}
