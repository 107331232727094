/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import Select from 'react-select';
import { useLocation, useHistory } from 'react-router-dom';
import { MdArrowBack, MdClose, MdAdd, MdSearch } from 'react-icons/md';
import { IoMdBarcode } from 'react-icons/io';
import { BsCardChecklist } from 'react-icons/bs';
import NumberFormat from 'react-number-format';
import MaterialTable from 'material-table';
import Spinner from 'react-md-spinner';

import { useSelector, useDispatch } from 'react-redux';
import { removeProd } from '../../../store/actions/produtoBarCode';

import currencyFormatter from '../../../components/currencyFormatterBrl';
import NavBar from '../../../components/NavBar';
import CardModal from '../../../components/CardModal';
import Alert from '../../../components/Alert';
import Cupom from './Cupom';

import api from '../../../services/api';

export default function Lancamento() {
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  const caixaAberto = useSelector((state) => state.caixaAberto);
  const produtoBarCode = useSelector((state) => state.produtoBarCode);

  const dispatch = useDispatch();

  const [caixaId, setCaixaId] = useState(null);
  const [caixaItemId, setCaixaItemId] = useState(null);
  // const [lojaId, setLojaId] = useState(null);
  const [colaboradorId, setColaboradorId] = useState(null);

  /** comanda */
  const [comandaId, setComandaId] = useState(null);
  // const [observacaoComanda, setObservacaoComanda] = useState('');
  const [comandaAberta, setComandaAberta] = useState(true);

  /** estados do sitem de comanda */
  const [codigo, setCodigo] = useState('');
  const [itemApelido, setItemApelido] = useState('');
  const [errorCode, setErrorCode] = useState('');

  const [estoqueId, setEstoqueId] = useState(null);
  const [quantidade, setQuantidade] = useState(1);
  const [valorUnitario, setValorUnitario] = useState(0);
  const [totalProduto, setTotalProduto] = useState(0);
  const [observacaoItem] = useState(null);
  const [produtoPrecoId, setProdutoPrecoId] = useState(null);
  const [qtdEstoque, setQtdEstoque] = useState(0);
  const [qtdEstoqueAddComanda, setQtdEstoqueAddComanda] = useState(0);
  const [qtdEstoqueActive, setQtdEstoqueActive] = useState(false);

  /** estado de finalização */
  const [promocaoId, setPromocaoId] = useState(null);
  const [formaPagamentoId, setFormaPagamentoId] = useState(null);
  const [clienteId, setClienteId] = useState(null);
  //
  const [valorDescontoPromocao, setValorDescontoPromocao] = useState(0);
  const [valorDesconto, setValorDesconto] = useState(0);
  const [valorPagar, setValorPagar] = useState(0);
  const [valorRecebido, setValorRecebido] = useState(0);
  const [valorTroco, setValorTroco] = useState(0);
  const [observacao, setObservacao] = useState('');

  // valor auxiliar do total pagar
  const [valorPagarAux, setValorPagarAux] = useState(0);

  /** react Select */
  const [clienteSelect, setClienteSelect] = useState({});
  const [produtoSelect, setProdutoSelect] = useState({});
  const [colaboradorSelect, setColaboradorSelect] = useState({});

  /** listas */
  const [promocoes, setPromocoes] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [estoques, setEstoques] = useState([]);
  const [comanda, setComanda] = useState(null);
  const [itemComanda, setItemComanda] = useState(null);
  const [colaboradores, setColaboradores] = useState([]);

  /** caixa modal de pagamentos */
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isClientDialogOpen, setIsClientDialogOpen] = useState(false);
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [isCupomDialogOpen, setIsCupomDialogOpen] = useState(false);

  // const [loadPagamento, setLoadPagamento] = useState(false);
  // const [loadItem, setLoadItem] = useState(false);

  const [loadFirnalizar, setLoadFirnalizar] = useState(false);
  const [loadItemComanda, setLoadItemComanda] = useState(false);
  const [loadRemover, setLoadRemover] = useState(false);

  const [finishDisabled, setFinishDisabled] = useState(true);

  const location = useLocation();

  useLayoutEffect(() => {
    /** ler os dados passados por paramentros */
    if (location.state) {
      setCaixaId(location.state.caixa_id);
      setCaixaItemId(location.state.id);
      setComandaId(location.state.comanda_id);
      setComandaAberta(!!location.state.comanda_status);
      setItemApelido(location.state.item_apelido);
      // console.log('status: '+ !!location.state.comanda_status)
    }
  }, []);

  /** busca o os colaboradores de acordo com a loja lojada */
  useEffect(() => {
    (async function loadColaboradores() {
      await api
        .get(`colaborador/loja/${auth.data.lojaId}`)
        .then((response) => {
          setColaboradores(response.data);

          setColaboradorSelect(
            response.data.filter(
              (colab) => colab.id === auth.data.colaboradorId
            )[0]
          );
          setColaboradorId(auth.data.colaboradorId);

          // console.log(response.data);
        })
        .catch((error) => console.log(error.response.data));
    })();
  }, []);

  // dados do codigo de barras do produto
  useEffect(() => {
    if (produtoBarCode.barCode) {
      setCodigo(produtoBarCode.barCode);
    }
  }, [produtoBarCode]);

  useEffect(() => {
    setTimeout(() => {
      setErrorCode('');
    }, 4000);
  }, [errorCode]);

  /** requesição de comandas pelo comanda */
  async function loadComanda(comandaId) {
    await api
      .get(`comanda/${comandaId}`)
      .then((response) => {
        // console.log("comanda...");
        // console.log(response.data);
        setComanda(response.data);

        setItemComanda(response.data.item_comanda);
        let total = 0;
        response.data.item_comanda.map((e) => {
          // console.log("valor: " + e.sub_total);
          const sub = parseFloat(e.quantidade * e.produto_preco.preco_varejo);
          total += sub;
          // console.log(total);
          setValorPagar(total);
          setValorPagarAux(total);
        });
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        console.log('finally');
      });
  }

  useEffect(() => {
    /** consulta a contanda com o comanda_id disponivel no locasotorange */
    if (location.state) {
      loadComanda(location.state.comanda_id);
    }
  }, []);

  useEffect(() => {
    /** consulta o valor do produto selecionado de acordo com o id fornecido
     * sempre que houver uma mudança no estado de produtoId selecionado
     * */
    if (produtoSelect.produto_id) {
      setCodigo(produtoSelect.codigo_barras);
      // console.log(produtoSelect);
      setEstoqueId(produtoSelect.id);
      setProdutoPrecoId(produtoSelect.produto_preco_id);
      setQtdEstoque(produtoSelect.estoque_total);
      setQtdEstoqueActive(true);
      (async function loadPrecoProduto() {
        await api
          .get(`produtopreco/${produtoSelect.produto_id}`)
          .then((response) => {
            setValorUnitario(response.data.preco_varejo);
            // console.log(response.data)
          })
          .catch((error) => {
            console.log(error.response);
          })
          .finally(() => {
            console.log('finally');
            // console.log(produtoSelect);
          });
      })();
    }
  }, [produtoSelect]);

  /**
   * consulta os clinets cadastrados no sistema
   */

  async function loadClientes() {
    await api
      .get(`/cliente/loja/${auth.data.lojaId}`)
      .then((response) => {
        setClientes(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }
  useEffect(() => {
    loadClientes();
  }, []);

  /**
   * consulta os produtos em estoque disponiveis
   */
  async function loadEstoque() {
    await api
      .get(`estoque/loja/${auth.data.lojaId}`)
      .then((response) => {
        setEstoques(response.data);
        // console.log(response.data);
      })
      .catch((error) => console.log(error.response))
      .finally(() => {});
  }

  useEffect(() => {
    /** lista as promoções disponiveis no sistemas */
    (async function loadPromocoes() {
      await api
        .get(`promocao/loja/${auth.data.lojaId}`)
        .then((response) => {
          setPromocoes(response.data);
          // console.log(response.data);
        })
        .catch((error) => console.log(error))
        .finally(() => {});
    })();

    /** consulta meio de pagamentos */
    (async function loadFormaPagamento() {
      await api
        .get('formapagamento')
        .then((response) => {
          setFormasPagamento(response.data);
          setFormaPagamentoId(response.data[0].id);
          // console.log(response.data);
        })
        .catch((error) => console.log(error))
        .finally(() => {});
    })();

    /** consulta os produtos em estoque disponiveis     */
    loadEstoque();
  }, []);

  /**
   * cria comanda, item comanda e item de caixa com id de comanda
   * */
  async function handleCreateComanda(data) {
    setLoadItemComanda(true);
    await api
      .post('lancamentocaixaitem', data)
      .then((response) => {
        setComandaId(response.data.comanda.id);
        loadComanda(response.data.comanda.id);
        setCaixaItemId(response.data.item_caixa.id);
        dispatch(removeProd());
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        console.log('finally');
        setLoadItemComanda(false);
      });
  }

  /** insere os item da comanda */
  async function handleCreateItemComanda(data) {
    setLoadItemComanda(true);
    await api
      .post('itemcomanda', data)
      .then((response) => {
        loadComanda(response.data.comanda_id);
        setProdutoSelect({});
        setValorUnitario(0);
        setQuantidade(1);
        dispatch(removeProd());
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        console.log('finali');
        setLoadItemComanda(false);
      });
  }

  /** remover os item da comanda */
  async function handleDeletarComanda(id) {
    setLoadRemover(true);
    await api
      .delete(`itemcomanda/${id}`)
      .then(() => {
        loadComanda(comandaId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log('finally');
        setLoadRemover(false);
      });
  }

  /** ler código de barras presente no produto */
  async function handleLerCodigoProduto() {
    history.push('/caixas/caixa/lancamento/barcode');
  }

  /** buscar produto */
  async function handleBuscarProduto(e) {
    if (e) e.preventDefault();
    if (codigo) {
      await api
        .get(`estoque/codigo/${codigo}/loja/${auth.data.lojaId}`)
        .then((response) => {
          setProdutoSelect(response.data);
          setEstoqueId(response.data.id);
          setProdutoPrecoId(response.data.produto.produto_preco.id);
          setQtdEstoque(response.data.estoque_total);
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
          setErrorCode(error.response.data);
          setProdutoSelect({});
          setEstoqueId(null);
          setValorUnitario(0);
          setQtdEstoque(0);
        })
        .finally(() => {
          console.log('finally');
        });
    }
  }

  /** evento do botao de inserção dos item da comanda */
  async function handleSubmitItemComanda(e) {
    e.preventDefault();

    /** se comanda_id exitir na passagem de paramentos então adiciona-se apenas
     * um item, caso contrario adiona-se uma comanda, um item na comanda e um
     * item de caixa com a comanda criada nesse momento */
    if (comandaId) {
      /** item comanda */
      const data = {
        comanda_id: comandaId,
        estoque_id: estoqueId,
        quantidade,
        observacao: observacaoItem,
        produto_preco_id: produtoPrecoId,
      };
      handleCreateItemComanda(data);
    } else {
      /** comanda, item comanda e item caixa com comanda */
      const data = {
        /** */
        loja_id: auth.data.lojaId,
        colaborador_id: colaboradorId,
        observacao_comanda: 'Inicial',
        /** */
        estoque_id: estoqueId,
        quantidade,
        produto_preco_id: produtoPrecoId,
        /** */
        caixa_id: caixaAberto.caixaId,
        item_apelido: itemApelido,
      };
      // console.log(data);

      handleCreateComanda(data);
    }
  }

  /** finalidar comanda voltar */
  async function handleSubmitFinalizar(e) {
    e.preventDefault();
    /** dispara função responsavel de fazer requisição respontavel por finalizar
     *  a compra e realizar um lançamento de retirada no estoque de cada produto
     *  usando na comanda */

    setLoadFirnalizar(true);

    const data = {
      cliente_id: clienteId,
      promocao_id: promocaoId,

      forma_pagamento_id: Number(formaPagamentoId),
      valor: valorPagar,
      valor_desconto: valorDesconto
        ? parseFloat(valorDesconto.replace(/[^\d\,]/g, '').replace(',', '.'))
        : 0,
      valor_desconto_promocao: parseFloat(valorDescontoPromocao),

      /** metodo que acrecentar estoques negatovos dos produtos listados na comanda */
      colaborador_id: colaboradorId,
      apelido: itemApelido,
      caixa_id: caixaId,
      caixa_item_id: caixaItemId,
      nome_cliente: clienteSelect.label ? clienteSelect.label : null,
      item_comanda: itemComanda,
      observacao,
    };

    // console.log(data);

    await api
      .post(`finalizarcomanda/${comandaId}/caixaitem/${caixaItemId}`, data)
      .then((response) => {
        // console.log(response.data);
        setIsDialogOpen(false);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        console.log('finally');
        setLoadFirnalizar(false);
      });
  }

  /** calculos monetários */
  useEffect(() => {
    if (quantidade) {
      const total = quantidade * valorUnitario;

      setTotalProduto(
        total.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      );
    } else {
      setTotalProduto(0);
    }
  }, [valorUnitario, quantidade]);

  /** capturando referencia */
  useEffect(() => {
    /** calculando desconto digitado */
    if (!!valorDesconto || !!valorDescontoPromocao) {
      const desconto = parseFloat(
        valorDesconto
          ? valorDesconto.replace(/[^\d\,]/g, '').replace(',', '.')
          : 0
      );
      const pagar = valorPagar;

      const valorComDesconto = pagar - desconto;

      // console.log("valor com desconto: " + valorComDesconto);

      setValorPagarAux(valorComDesconto);

      /** calculando troco */
      const receber = parseFloat(
        valorRecebido
          ? valorRecebido.replace(/[^\d\,]/g, '').replace(',', '.')
          : 0
      );

      const troco =
        receber > valorComDesconto - parseFloat(valorDescontoPromocao)
          ? receber - (valorComDesconto - parseFloat(valorDescontoPromocao))
          : 0;
      setValorTroco(troco);
      // console.log("valorTroco: " + troco);
    } else {
      setValorPagarAux(parseFloat(valorPagar));
      // setValorTroco(0);

      /** calculando troco */
      const receber = valorRecebido
        ? parseFloat(valorRecebido.replace(/[^\d\,]/g, '').replace(',', '.'))
        : 0;
      setValorTroco(receber > valorPagar ? receber - valorPagar : 0);

      if (receber >= valorPagar) {
        setFinishDisabled(false);
      } else {
        setFinishDisabled(true);
      }
      // console.log(receber);
    }
  }, [valorRecebido, valorDesconto, valorDescontoPromocao]);

  /** seleciona promoção */
  function handleChangePromocao(e) {
    setPromocaoId(e.target.value);

    /** clacula o desconto promocional */
    const id = e.target.value;
    const promocao = promocoes.filter((promo) => promo.id === parseInt(id))[0];

    if (promocao) {
      const { porcentagem, desconto } = promocao;

      const pagar = valorPagar;
      const result = porcentagem
        ? pagar * (desconto / 100)
        : parseFloat(desconto);

      setValorDescontoPromocao(result);
    } else {
      setValorDescontoPromocao(0);
    }
  }

  useEffect(() => {
    const result = Number(qtdEstoque) - quantidade;

    // setQtdEstoqueAddComanda(result);
    if (result < 0 || !quantidade) {
      // não permite
      setQtdEstoqueActive(false);
      // setQtdEstoqueAddComanda(0);
    } else {
      // permite
      setQtdEstoqueActive(true);
    }
  }, [quantidade, qtdEstoque]);

  useEffect(() => {
    if (!!itemComanda && estoqueId) {
      const result = itemComanda.filter(
        (item) => item.estoque_id === estoqueId
      );
      // console.log(result);

      if (result) {
        const soma = result.reduce(
          (acumulador, valorAtual) => acumulador + valorAtual.quantidade,
          0
        );

        // console.log(soma);
        setQtdEstoqueAddComanda(soma);
      } else {
        setQtdEstoqueAddComanda(0);
      }
    }
  }, [produtoSelect, estoqueId, itemComanda]);

  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-20 max-w-5xl flex flex-col p-2">
        {/** cabeçalho da pagina */}
        <div className="flex justify-between items-center">
          <div className="w-10 sm:w-56">
            <button
              onClick={() => history.goBack()}
              type="button"
              className="btn btn-pink"
            >
              <MdArrowBack />
            </button>
          </div>
          <h1 className="text-center text-2xl text-pink-500">Lançamento</h1>

          <div className="w-10 sm:hidden" />
          <div className="w-56 -mt-6 hidden sm:block">
            <label className="text-xs text-pink-500">Vendedor</label>

            <Select
              isSearchable
              loadItemComanda={loadItemComanda}
              className="basic-single"
              classNamePrefix="select"
              options={colaboradores}
              value={colaboradorSelect}
              onChange={(val) => {
                setColaboradorId(val.id);
                setColaboradorSelect({
                  value: val.value,
                  label: val.label,
                  id: val.id,
                });
              }}
            />
          </div>
        </div>

        {/** area de formulario da página */}
        <div className="my-2">
          {!!errorCode && <Alert text={errorCode} type="error" />}

          {/** exibe vusuários da loja */}
          <div className="block sm:hidden">
            <label className="text-xs text-pink-500">Vendedor</label>
            <Select
              isClearable
              isSearchable
              disabled={loadItemComanda}
              className="basic-single"
              classNamePrefix="select"
              options={colaboradores}
              value={colaboradorSelect}
              onChange={(val) => {
                if (val) {
                  setColaboradorId(val.id);
                  setColaboradorSelect({
                    value: val.value,
                    label: val.label,
                    id: val.id,
                  });
                } else {
                  setColaboradorId(null);
                  setColaboradorSelect({});
                }
              }}
            />
          </div>
          <div className="sm:w-1/2 lg:w-1/3">
            <div className="input-text shadow flex items-center mt-2">
              <span>{`${caixaItemId ? `${caixaItemId}_` : ''}`}</span>
              <input
                disabled={!comandaAberta}
                type="text"
                placeholder="Identificador"
                className="focus:outline-none bg-transparent"
                value={itemApelido}
                onChange={(e) => {
                  setItemApelido(e.target.value);
                }}
              />
            </div>
            {/** exibi  numero do codigo do produto representado no codigo de barras */}
            {/** codigo do produto */}
            <label className="text-sm text-pink-500">Código Produto</label>
            <div className="flex w-full mb-2">
              <form onSubmit={handleBuscarProduto} className="relative  w-full">
                <div className="flex">
                  <input
                    type="text"
                    placeholder=""
                    required
                    disabled={loadItemComanda}
                    className="input-text shadow mb-0"
                    value={codigo}
                    onChange={(e) => {
                      setCodigo(e.target.value);
                    }}
                  />
                  <button
                    type="submit"
                    className="p-2 absolute right-0 top-0 button-0 rounded-lg text-2xl text-pink-500 focus:outline-none hover:bg-pink-100 hover:opacity-75"
                  >
                    <MdSearch />
                  </button>
                </div>
              </form>

              {/** ler o codigo de barras pela camera */}
              <div className="ml-2">
                <button
                  onClick={handleLerCodigoProduto}
                  type="button"
                  className={`btn btn-pink m-0 py-3 ${
                    !comandaAberta
                      ? 'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
                      : ''
                  }`}
                  disabled={!comandaAberta}
                >
                  <IoMdBarcode />
                </button>
              </div>
            </div>
          </div>
          {/** códig produto */}

          {/** fomulario de inserção de item na comanda */}
          <form onSubmit={handleSubmitItemComanda}>
            <div className="flex flex-col sm:flex-row">
              <div className="flex w-full sm:w-2/3">
                <div className="w-3/4 mr-2">
                  <label className="text-sm text-pink-500 flex">Produto</label>

                  <div className="flex">
                    <Select
                      isClearable
                      isSearchable
                      disabled={loadItemComanda}
                      placeholder="Selecione"
                      className="basic-single w-full"
                      classNamePrefix="select"
                      value={produtoSelect}
                      onChange={(val) => {
                        if (val) {
                          setEstoqueId(val.id);
                          setProdutoSelect({
                            id: val.id,
                            value: val.value,
                            label: val.label,
                            produto_id: val.produto_id,
                            codigo_barras: val.codigo_barras,
                            produto_preco_id: val.produto.produto_preco.id,
                            estoque_total: val.estoque_total,
                          });
                          setQtdEstoqueActive(true);
                        } else {
                          setEstoqueId(null);
                          setProdutoSelect({});
                          setQtdEstoqueActive(false);
                        }
                      }}
                      options={estoques}
                    />
                    <button
                      onClick={() => {
                        setIsProductDialogOpen(!isProductDialogOpen);
                      }}
                      type="button"
                      className="btn btn-pink flex items-center justify-center text-xl mt-0 ml-1"
                    >
                      <BsCardChecklist />
                    </button>
                  </div>
                </div>
                <div className="w-1/4 sm:mr-2">
                  <label className="text-sm text-pink-500 flex">ValorUni</label>

                  <input
                    disabled
                    type="text"
                    placeholder=""
                    className="input-text shadow"
                    value={parseFloat(valorUnitario).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  />
                </div>
              </div>
              <div className="flex w-full sm:w-1/3">
                <div className=" mr-2">
                  <label className="text-sm text-pink-500 flex justify-between items-center">
                    <span>Qtd.</span>
                    <span className="rounded shadow px-1 text-sm text-green-500 bg-white min-w-16 text-center font-semibold">
                      {`+ ${
                        (qtdEstoqueAddComanda &&
                          qtdEstoque - qtdEstoqueAddComanda - quantidade) ||
                        (qtdEstoque && qtdEstoque - quantidade) ||
                        0
                      }`}
                      <span className="text-xs text-blue-500">
                        {` | ${qtdEstoque - qtdEstoqueAddComanda}`}
                      </span>
                    </span>
                  </label>

                  <NumberFormat
                    allowNegative={false}
                    displayType="number"
                    type="text"
                    disabled={loadItemComanda}
                    placeholder=""
                    className="input-text shadow"
                    value={quantidade}
                    onChange={(e) => {
                      setQuantidade(e.target.value);
                    }}
                  />
                </div>
                <div className=" sm:mr-2">
                  <label className="text-sm text-pink-500 flex">Total</label>

                  <input
                    disabled
                    type="text"
                    placeholder=""
                    className="input-text shadow"
                    value={totalProduto}
                  />
                </div>
              </div>
              <div className="sm:pt-4">
                <button
                  type="submit"
                  disabled={
                    !qtdEstoqueActive || !comandaAberta || loadItemComanda
                  }
                  className={`btn btn-pink w-full mt-1 sm:py-3 md:py-2 md:w-24 flex items-center justify-center ${
                    !qtdEstoqueActive || !comandaAberta || loadItemComanda
                      ? 'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
                      : ''
                  }`}
                >
                  {(loadItemComanda && (
                    <div className="flex justify-center items-center">
                      <Spinner
                        className="mx-1"
                        size={24}
                        singleColor="#fbb6ce"
                        borderSize={4}
                      />
                      aguarde
                    </div>
                  )) || (
                    <>
                      <MdAdd className="sm:text-md" />
                      <span className=" block sm:hidden md:block">Inserir</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
          {/** ./form item comanda */}

          {/**
           *
           *
           * bloco de exibição dos produtos adicionados e valor total */}

          <div className="bg-white rounded shadow h-full px-2 py-3 ">
            <div className="flex justify-between">
              <h2 className="px-2 text-gray-800">Compras</h2>
              <div className="flex justify-between items-center py-1 px-2 w-48">
                <span className="text-sm">Total: </span>
                <span className="text-2xl text-green-600">
                  {comanda
                    ? valorPagar.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : 'R$ 0,00'}
                </span>
              </div>
            </div>

            <div className="overflow-x-auto h-48 w-full mt-3">
              <table className="w-full">
                <thead>
                  <tr className="border text-gray-800 border-gray-600 border-t-0 border-l-0 border-r-0 text-xs">
                    <td className="pr-2">Cod</td>
                    <td className="pr-2">Prod</td>
                    <td className="pr-2">Tam</td>
                    <td className="pr-2 text-right">Qtd</td>
                    <td className="pr-2 text-right">ValorUni</td>
                    <td className="pr-2 text-right">Subtotal</td>
                    <td className="pr-2" />
                  </tr>
                </thead>
                <tbody>
                  {!!itemComanda &&
                    itemComanda.map((item) => (
                      <tr key={item.id} className="text-sm text-indigo-700">
                        <td className="pr-2">
                          {item.estoques.produto.codigo_barras}
                        </td>
                        <td className="pr-2 truncate">
                          <p className="w-48 truncate">
                            {item.estoques.produto.produto}
                          </p>
                        </td>
                        <td className="pr-2 truncate">
                          <p className="w-48 truncate">
                            {item.estoques.produto.tamanho}
                          </p>
                        </td>
                        <td className="pr-2 text-right">{item.quantidade}</td>
                        <td className="pr-2 text-right">
                          {parseFloat(
                            item.produto_preco.preco_varejo
                          ).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td className="pr-2 text-right">
                          {parseFloat(
                            item.quantidade * item.produto_preco.preco_varejo
                          ).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td className="text-lg text-red-500">
                          <button
                            onClick={() => handleDeletarComanda(item.id)}
                            type="button"
                            disabled={!comandaAberta || loadRemover}
                            className={`rounded-full p-1 hover:bg-pink-100 focus:bg-pink-100 ${
                              !comandaAberta || loadRemover
                                ? 'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
                                : ''
                            }`}
                          >
                            <MdClose />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/** ./bloco de exibir produtos */}
        </div>

        {/** */}
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => setIsCupomDialogOpen(!isCupomDialogOpen)}
            disabled={comandaAberta}
            className={`btn btn-pink whitespace-no-wrap mx-2 flex items-center justify-center ${
              comandaAberta
                ? 'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
                : ''
            }`}
          >
            Visualizar Cupom
          </button>

          <button
            onClick={() => setIsDialogOpen(true)}
            type="button"
            disabled={!comandaAberta || loadItemComanda || loadRemover}
            className={`btn btn-pink whitespace-no-wrap ${
              !comandaAberta || loadItemComanda || loadRemover
                ? 'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
                : ''
            }`}
          >
            Finalizar Venda
          </button>
        </div>
      </div>
      {/**
       *
       *
       * modal de finalizar comprar */}
      <CardModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={() => setIsDialogOpen(!isDialogOpen)}
        titulo="Finalizar Compra"
        style="max-w-2xl mt-10"
      >
        <form onSubmit={handleSubmitFinalizar} className="w-full p-2">
          {/** 
           * feedbacks interação
          <p className="text-center text-red-600 text-base p-2">{caixaError}</p>
          <p className="text-center text-red-500 text-base p-2">{`Mensagem error`}</p>
        */}

          {/** forms com demais dados pra finalizar a venda */}

          <div className="px-2 py-1 border-b">
            <p className="text-2xl text-right">
              {comanda
                ? valorPagar.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 'R$ 0,00'}
            </p>
          </div>

          {/** */}

          <div className="w-2/3 flex">
            <div className="mr-2 w-full">
              <label className="text-xs text-pink-500">Cliente</label>
              <Select
                disabled={loadFirnalizar}
                isClearable
                isSearchable
                placeholder="Selecione"
                className="basic-single"
                classNamePrefix="select"
                value={clienteSelect}
                onChange={(val) => {
                  if (val) {
                    setClienteSelect({ value: val.value, label: val.label });
                    setClienteId(val.value);
                  } else {
                    setClienteSelect({});
                    setClienteId(null);
                  }
                }}
                options={clientes}
              />
            </div>

            <div className="pt-4">
              <button
                disabled={loadFirnalizar}
                onClick={() => setIsClientDialogOpen(!isClientDialogOpen)}
                type="button"
                className="btn btn-pink flex items-center justify-center text-xl"
              >
                <BsCardChecklist />
              </button>
            </div>
          </div>

          <div className="flex py-2">
            {/** */}
            <div className="mr-2 w-1/2">
              <label className="text-xs text-pink-500">Forma Pagamento</label>
              <div className="relative w-full">
                <select
                  disabled={loadFirnalizar}
                  className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 pt-3 pb-2 px-4 pr-8 leading-tight focus:outline-none "
                  id="grid-state"
                  value={formaPagamentoId}
                  onChange={(e) => setFormaPagamentoId(e.target.value)}
                  onSelect={(e) => setFormaPagamentoId(e.target.value)}
                >
                  {formasPagamento.map((forma) => (
                    <option key={forma.id} value={forma.id}>
                      {forma.forma_pagamento}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            {/** */}

            <div className="w-1/2">
              <label className="text-xs text-pink-500">Promoção</label>
              <div className="relative w-full">
                <select
                  disabled={loadFirnalizar}
                  className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 pt-3 pb-2 px-4 pr-8 leading-tight focus:outline-none "
                  id="grid-state"
                  value={promocaoId}
                  onChange={handleChangePromocao}
                >
                  <option />
                  {promocoes.map(
                    (promocao) =>
                      !!promocao.ativo && (
                        <option key={promocao.id} value={promocao.id}>
                          {promocao.descricao}
                        </option>
                      )
                  )}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div>
            <label className="text-xs text-pink-500">Observação</label>
            <textarea
              rows="4"
              cols="50"
              placeholder=""
              className="block resize-none input-text shadow h-24 min-h-20"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
            />
          </div>

          {/**   */}

          <div className="flex flex-col sm:flex-row pt-2">
            <div className="flex flex-row sm:flex-col sm:w-1/3 sm:pr-2">
              <div className="mr-2">
                <label className="text-xs text-gray-700">Valor Recebido</label>
                <NumberFormat
                  disabled={loadFirnalizar}
                  allowNegative={false}
                  displayType="number"
                  format={currencyFormatter}
                  required
                  value={valorRecebido}
                  onChange={(e) => setValorRecebido(e.target.value)}
                  className="input-text shadow text-xl border"
                />
              </div>
              <div className="mr-2">
                <label className="text-xs text-gray-700">Desconto</label>
                <NumberFormat
                  disabled={loadFirnalizar}
                  allowNegative={false}
                  displayType="number"
                  format={currencyFormatter}
                  value={valorDesconto}
                  onChange={(e) => setValorDesconto(e.target.value)}
                  className="input-text shadow text-xl border"
                />
              </div>
            </div>

            <div className="sm:w-2/3 p-2 sm:pl-12">
              <div className="flex pb-6">
                <div className="mr-2 w-1/2">
                  <label
                    htmlFor="desconto_promocao"
                    className="text-xs text-pink-500"
                  >
                    Desconto Promoção
                  </label>
                  <p id="desconto_promocao" className="text-xl font-medium">
                    {valorDescontoPromocao.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </p>
                </div>

                <div className=" w-1/2">
                  <label htmlFor="troco" className="text-xs text-pink-500">
                    Troco
                  </label>
                  <p id="troco" className="text-xl font-medium">
                    {valorTroco.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </p>
                </div>
              </div>

              <div className="flex">
                <div className="w-1/2" />
                <div className="w-1/2">
                  <label htmlFor="subtotal" className="text-xs text-pink-500">
                    SubTotal
                  </label>
                  <p id="subtotal" className="text-xl font-medium">
                    {(valorPagarAux - valorDescontoPromocao).toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end my-2">
            <button
              type="submit"
              disabled={loadFirnalizar || finishDisabled}
              className={`btn btn-pink w-full sm:w-40 flex items-center justify-center ${
                loadFirnalizar || finishDisabled
                  ? 'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
                  : ''
              }`}
            >
              {(loadFirnalizar && (
                <div className="flex justify-center items-center">
                  <Spinner
                    className="mx-1"
                    size={24}
                    singleColor="#fbb6ce"
                    borderSize={4}
                  />
                  aguarde...
                </div>
              )) || (
                <>
                  <span>Finalizar</span>
                </>
              )}
            </button>
          </div>

          {/** form finalizar */}
        </form>
      </CardModal>

      {/**
       *
       *
       * modal de listagem de clientes */}
      <CardModal
        isDialogOpen={isClientDialogOpen}
        setIsDialogOpen={() => setIsClientDialogOpen(!isClientDialogOpen)}
        titulo="Clientes"
        style="max-w-2xl mt-10 p-0 w-full"
      >
        <MaterialTable
          title=""
          columns={[
            {
              title: 'Id',
              field: 'id',
              hidden: true,
            },
            {
              title: 'Loja Id',
              field: 'loja_id',
              hidden: true,
            },
            {
              title: 'Nome Responsavel',
              field: 'nome_responsavel',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Razão Social',
              field: 'razao_social',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Nome Fantasia',
              field: 'nome_fantasia',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Pessoa',
              field: 'pessoa',
            },
            {
              title: 'CNPJ',
              field: 'cnpj',
            },
            {
              title: 'CPF',
              field: 'cpf',
            },
            {
              title: 'Incrição Estadual',
              field: 'inscricao_estadual',
            },

            {
              title: 'Cidade',
              field: 'cidade',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },

            {
              title: 'Uf',
              field: 'uf',
            },

            {
              title: 'Contato',
              field: 'contato1',
            },

            {
              title: 'Email',
              field: 'email',
            },
          ]}
          data={clientes}
          actions={[
            (rowData) => ({
              icon: 'checked',
              tooltip: 'Selecionar Cliente',

              onClick: () => {
                setClienteSelect({
                  value: rowData.value,
                  label: rowData.label,
                });
                setClienteId(rowData.value);

                setIsClientDialogOpen(!isClientDialogOpen);
              },
            }),

            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => loadClientes(),
            },
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: false,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </CardModal>

      {/**
       *
       *
       * modal de listagem de produtos */}
      <CardModal
        isDialogOpen={isProductDialogOpen}
        setIsDialogOpen={() => setIsProductDialogOpen(!isProductDialogOpen)}
        titulo="Produtos"
        style="max-w-2xl mt-10 p-0 w-full"
      >
        <div className="w-full">
          <MaterialTable
            title=""
            columns={[
              {
                title: 'Id',
                field: 'produto.id',
                hidden: false,
              },
              {
                title: 'Loja Id',
                field: 'loja_id',
                hidden: true,
              },
              {
                title: 'Preço',
                field: 'produto.produto_preco.preco_varejo',
                type: 'currency',
                currencySetting: { locale: 'br', currencyCode: 'brl' },
              },
              {
                title: 'Produto',
                field: 'produto.produto',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: 'Fabricante',
                field: 'produto.fabricante',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: 'Marca',
                field: 'produto.marca',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
            ]}
            data={estoques}
            actions={[
              (rowData) => ({
                icon: 'checked',
                tooltip: 'Selecionar Produtos',

                onClick: () => {
                  setProdutoSelect({
                    value: rowData.value,
                    label: rowData.label,
                  });
                  setEstoqueId(rowData.id);
                  setProdutoPrecoId(rowData.produto.produto_preco.id);
                  setCodigo(rowData.produto.codigo_barras);
                  setValorUnitario(rowData.produto.produto_preco.preco_varejo);
                  setQtdEstoque(rowData.estoque_total);
                  console.log(rowData.estoque_total);

                  setIsProductDialogOpen(!isProductDialogOpen);
                },
              }),

              {
                icon: 'refresh',
                tooltip: 'Atualizar',
                isFreeAction: true,
                onClick: () => loadEstoque(),
              },
            ]}
            options={{
              actionsColumnIndex: 0,
              exportButton: false,
            }}
            // other props
            localization={{
              pagination: {
                labelRowsSelect: 'Linhas',
                labelDisplayedRows: '{from}-{to} de {count}',
                firstTooltip: 'Primeiro',
                previousTooltip: 'Voltar',
                nextTooltip: 'Próxima',
                lastTooltip: 'Ultimo',
              },
              toolbar: {
                exportTitle: 'Exportar',
                exportName: 'Exportar CSV',
                searchPlaceholder: 'Buscar',
                nRowsSelected: '{0} linha(s) selecionada(s)',
              },
              header: {
                actions: 'Ações',
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro',
                },
              },
            }}
          />
        </div>
      </CardModal>

      {/**  */}
      <Cupom
        comandaId={!!location.state && location.state.comanda_id}
        lojaId={auth.data.lojaId}
        isOpen={isCupomDialogOpen}
        setIsOpen={setIsCupomDialogOpen}
      />
    </>
  );
}
