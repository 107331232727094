import React, { memo } from 'react';

const Alert = ({ text = '', type = 'success' }) => (
  <div
    className={`my-2 shadow max-w-md mx-auto border rounded-lg p-2 flex justify-center items-center text-gray-700 
${
  type === 'success'
    ? 'border-green-500 bg-green-100'
    : 'border-red-500 bg-red-100'
}`}
  >
    <p className="text-center">{text}</p>
  </div>
);

export default memo(Alert);
