import React, { useState, useEffect, useLayoutEffect } from 'react';
// import Spinner from "react-spinner-material";
import Spinner from 'react-md-spinner';
import { useHistory } from 'react-router-dom';

// redux
import { useDispatch } from 'react-redux';
import { addAuth } from '../store/actions/auth';

import api from '../services/api';

import Avatar from '../components/Avatar';

import perfil from '../assets/user-woman.svg';

export default function Login() {
  const history = useHistory();

  const [foto, setFoto] = useState();
  const [fotoView, setFotoView] = useState();
  const [colaboradorId, setColabordorId] = useState();
  const [colaboradorLojaId, setColabordorLojaId] = useState();
  const [nivelAcesso, setNivelAcesso] = useState();

  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [erroLogin, setErroLogin] = useState('');

  const [lojas, setLojas] = useState([]);
  const [lojaId, setLojaId] = useState();
  const [loja, setLoja] = useState();
  /** */
  const [loadLogin, setLoadLogin] = useState(false);
  const [loadUser, setLoadUser] = useState(false);

  /** redux */
  const dispatch = useDispatch();

  async function handleLoadUser(userName = 0) {
    if (userName) {
      setLoadUser(true);
      await api
        .get(`user/${userName}`)
        .then(async (response) => {
          console.log(response.data);
          if (response.data) {
            setFoto(response.data.colaborador.foto);
            setColabordorId(response.data.colaborador_id);
            setColabordorLojaId(response.data.colaborador.loja_id);
            setNivelAcesso(response.data.colaborador.cargos.nivel_acesso);
            await api
              .get(`image/${response.data.colaborador_id}`)
              .then((image) => {
                setFotoView(image.data.path);
              })
              .catch((error) => {
                console.log(error.response);
              });
          } else {
            setFotoView(perfil);
          }
        })
        .catch((error) => {
          // console.log(error.response);
          if (!error.response) {
            setErroLogin('Por favor, verifique sua conexão à internet.');
          } else {
            console.log(error.response);
            setErroLogin(error.response.data);
          }
        })
        .finally(() => {
          setLoadUser(false);
        });
    } else {
      setFotoView(perfil);
    }
  }
  useLayoutEffect(() => {
    (async function loadLojas() {
      await api
        .get('lojas')
        .then((response) => {
          setLojas(response.data);
          if (response.data.length >= 1) {
            setLojaId(response.data[0].id);
            setLoja(response.data[0].loja);
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          console.log('finally');
        });
    })();
  }, []);

  useEffect(() => {
    const lojaAux = lojas.filter((rest) => Number(rest.id) === lojaId)[0];
    setLoja(!!lojaAux && lojaAux.loja);
  }, [lojaId]);

  useLayoutEffect(() => {
    (async function exitesUsuario() {
      await api
        .get('user/exists')
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {});
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setErroLogin(false);
    }, 3000);
  }, [erroLogin]);

  async function handlerSubmit(e) {
    e.preventDefault();

    if (Number(nivelAcesso) !== 2) {
      if (Number(lojaId) !== Number(colaboradorLojaId)) {
        setErroLogin('Usuário não tem permição de acessar essa loja!');
        return;
      }
    }

    setLoadLogin(true);
    await api
      .post('login', {
        user_name: userName,
        password,
      })
      .then((response) => {
        dispatch(
          addAuth({
            token: response.data.token,
            autenticated: true,
            data: {
              colaboradorId,
              userName,
              foto,
              lojaId,
              loja,
              nivelAcesso,
            },
          })
        );

        history.push('/');
      })
      .catch((error) => {
        console.log(error.response);
        setErroLogin('Usuário e senha não correspondem!');
        setLoadLogin(false);
      })
      .finally(() => setLoadLogin(false));
  }

  return (
    <div className="bg-contain bg-no-repeat bg-center bg-pink-350">
      <div className="bg-opacity-75 bg-pink-200 h-screen w-screen py-20">
        {/** */}
        <div className="container sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm mx-auto">
          {/** foto o ferfil */}
          <div className="relative">
            <Avatar
              perfil={fotoView || perfil}
              wh="h-32 w-32"
              brd="border-4"
              mx="mx-auto"
            />
            <div className="absolute top-0 flex w-full">
              {loadUser && (
                <Spinner
                  className="mx-auto"
                  size={128}
                  borderSize={5}
                  singleColor="#fbb6ce"
                />
              )}
            </div>
          </div>
          {/** */}
          <form onSubmit={handlerSubmit} className="flex-col p-6">
            <p className="text-center text-red-600 text-base p-2">
              {erroLogin}
            </p>

            {lojas.length > 1 && (
              <div className="relative w-full mb-2">
                <select
                  className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                  id="grid-state"
                  value={lojaId}
                  onChange={(e) => setLojaId(e.target.value)}
                >
                  {lojas.map((loja) => (
                    <option key={loja.id} value={loja.id}>
                      {loja.loja}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            )}
            <input
              type="text"
              placeholder="Usuário"
              className="input-text shadow-lg"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              onBlur={(e) => handleLoadUser(e.target.value)}
            />
            <input
              type="password"
              placeholder="Senha"
              className="input-text shadow-lg"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              disabled={loadLogin}
              type="submit"
              className={`btn btn-pink w-full shadow-lg relative ${
                loadLogin && 'disabled'
              }`}
            >
              {(loadLogin && (
                <div className="flex justify-center items-center">
                  <Spinner
                    className="mx-1"
                    size={24}
                    singleColor="#fbb6ce"
                    borderSize={4}
                  />
                  aguarde...
                </div>
              )) || <p className="text-center">Entrar</p>}
            </button>
          </form>
        </div>
        {/** */}
      </div>
    </div>
  );
}
