import React from 'react';
import Produtos from './Produtos';
import NavBar from '../../components/NavBar';

export default function Relatorios() {
  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-20">
        <div>dashiboard</div>
        <Produtos />
      </div>
    </>
  );
}
