/* eslint-disable radix */
import React, { memo } from 'react';

function StepsIndicatorsProd({ setStep = () => {}, step = 1, form = '' }) {
  return (
    <div className="relative h-12 my-4 px-6 max-w-md mx-auto sm:px-10">
      <div className="flex justify-between  items-center font-medium">
        <div className="flex flex-col justify-center ">
          <button
            value={1}
            disabled={(step <= 1 && true) || false}
            onClick={(e) => setStep(parseInt(e.target.value))}
            type="button"
            className={`ml-2 w-8 h-8 flex items-center justify-center text-base font-semibold rounded-full border-2 focus:outline-none focus:border-pink-500 
              ${
                step >= 1
                  ? 'text-white bg-pink-500 border-pink-500'
                  : 'bg-white border-pink-500 text-pink-500'
              }  
            `}
          >
            1
          </button>
          <p className="text-xs text-gray-301">{form}</p>
        </div>
        <div className="flex flex-col justify-center">
          <button
            value={2}
            disabled={(step <= 2 && true) || false}
            onClick={(e) => setStep(parseInt(e.target.value))}
            type="button"
            className={`ml-2 w-8 h-8 flex items-center justify-center text-base font-semibold rounded-full border-2 focus:outline-none focus:border-pink-500 
              ${
                step >= 2
                  ? 'text-white bg-pink-500 border-pink-500'
                  : 'bg-white border-pink-500 text-pink-500'
              }  
            `}
          >
            2
          </button>
          <p className="text-xs text-gray-301">Estoque</p>
        </div>
      </div>
      <div className="-mt-8 px-6">
        <div className="w-full h-0 border-b-2 border-pink-400" />
      </div>
    </div>
  );
}

export default memo(StepsIndicatorsProd);
