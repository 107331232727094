/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { MdArrowBack } from 'react-icons/md';
import Spinner from 'react-md-spinner';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Avatar from '../../components/Avatar';
import CardModal from '../../components/CardModal';

import perfil from '../../assets/user-woman.svg';

export default function Perfil() {
  const history = useHistory();
  const [colaborador, setColaborador] = useState([]);
  const [cargo, setCargo] = useState([]);
  const [user, setUser] = useState([]);

  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [load, setLoad] = useState('');

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    (async function loadColaborador() {
      await api
        .get(`colaborador/${auth.data.colaboradorId}`)
        .then((response) => {
          console.log(response.data);
          setColaborador(response.data);
          setUser(response.data.users);
          setCargo(response.data.cargos);
        });
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError('');
    }, 2000);
  }, [error]);

  async function handleAlterarSenhaSubmit(e) {
    e.preventDefault();

    setLoad(true);
    const data = {
      password_current: passwordCurrent,
      password,
      password_confirmation: passwordConfirmation,
    };
    await api
      .put(`users/${user.id}`, data)
      .then((response) => {
        if (response.data === 'Senha incorreta!') {
          setError(response.data);
        } else if (response.data === 'Senha incorreta!') {
          setError(response.data);
        } else {
          alert('Senha alterada com sucesso!');
          setIsDialogOpen(false);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        // setError(err.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-20 p-2">
        <div className="flex justify-between items-center mb-8 max-w-4xl mx-auto">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="flex btn btn-pink items-center"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-center text-pink-500 font-semibold -ml-16">
            Perfil
          </h1>
          <div />
        </div>

        <div className="flex flex-col sm:flex-row py-4 max-w-4xl mx-auto bg-white shadow rounded-sm">
          <div className="w-full sm:w-1/3">
            <Avatar
              perfil={
                colaborador.foto
                  ? `${process.env.REACT_APP_BASE_URL_STORAGE}/${colaborador.foto}`
                  : perfil
              }
              wh="w-40 h-40"
              brd="border-4"
              mx="mx-auto"
            />
          </div>
          <div className="w-full sm:2/3 text-gray-700">
            <div className="px-3 mb-3">
              <div className="flex justify-between">
                <div>
                  <label className="text-xs font-bold">Nome</label>
                  <p className="text-base leading-none pb-2">
                    {colaborador.nome}
                  </p>
                </div>
                <button
                  type="button"
                  disabled
                  className="btn btn-pink py-1 disabled hover:opacity-50 opacity-50"
                >
                  Editar
                </button>
              </div>
              <label className="text-xs font-bold">Endereço</label>
              <p className="text-base leading-none pb-2">
                {colaborador.endereco}
              </p>
              <div className="flex">
                <div className="pr-4">
                  <label className="text-xs font-bold">Contato 1</label>
                  <p className="text-base leading-none pb-2">
                    {colaborador.contato1}
                  </p>
                </div>
                <div className="pr-4">
                  <label className="text-xs font-bold">Contato 2</label>
                  <p className="text-base leading-none pb-2">
                    {colaborador.contato1}
                  </p>
                </div>
              </div>
              <label className="text-xs font-bold">Observação</label>
              <p className="text-base leading-none pb-2">
                {colaborador.observacao}
              </p>
            </div>
            <div className="flex justify-between items-center border border-pink-500 border-l-0 border-r-0 border-b-0 p-3">
              <div>
                <p className="text-xs">
                  Cargo:
                  <span className="text-base ml-2">{cargo.cargo}</span>
                </p>
                <p className="text-xs">
                  Status:
                  <span className="text-base ml-2">
                    {(Number(colaborador.status) === 0 && 'Inativo') ||
                      (Number(colaborador.status) === 1 && 'Ativo') ||
                      (Number(colaborador.status) === 2 && 'Linceça') ||
                      ''}
                  </span>
                </p>
              </div>
              {/** 
              <button disabled className="btn btn-pink py-1 disabled hover:opacity-50">
                Editar
              </button>
              */}
            </div>
            <div className="flex justify-between items-center border border-pink-500 border-l-0 border-r-0 border-b-0 p-3">
              <div>
                <p className="text-xs">
                  Usuário:
                  <span className="text-base ml-2">{user.user_name}</span>
                </p>
                <p className="text-xs">
                  <span className="text-base">Senha: ******</span>
                </p>
              </div>
              <button
                type="button"
                onClick={() => {
                  setIsDialogOpen(
                    auth.data.nivelAcesso ? !isDialogOpen : false
                  );
                }}
                className="btn btn-pink py-1"
              >
                Editar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/** modal de abertura de caixa */}
      <CardModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={() => setIsDialogOpen(!isDialogOpen)}
        titulo={user.user_name}
        style="max-w-lg mt-20 "
      >
        <form onSubmit={handleAlterarSenhaSubmit} className="px-3 py-2">
          <p className="text-center text-red-600 text-base p-2">{error}</p>

          <div className="">
            <label className="block text-sm text-pink-500">Senha atual</label>
            <input
              disabled={load}
              type="password"
              placeholder=""
              className="block input-text shadow"
              value={passwordCurrent}
              onChange={(e) => setPasswordCurrent(e.target.value)}
            />
          </div>
          <div className="">
            <label className="block text-sm text-pink-500">Senha</label>
            <input
              disabled={load}
              type="password"
              placeholder=""
              className="block input-text shadow"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="">
            <label className="block text-sm text-pink-500">
              Confirmar Senha
            </label>
            <input
              disabled={load}
              type="password"
              placeholder=""
              className="block input-text shadow"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>

          <div className="flex justify-end mt-8">

            <button
              disabled={load}
              type="submit"
              className={`btn btn-pink flex items-center justify-center ${
                load && 'disabled'
              }`}
            >
              {(load && (
                <div className="flex justify-center items-center">
                  <Spinner
                    className="mx-1"
                    size={24}
                    singleColor="#fbb6ce"
                    borderSize={4}
                  />
                  aguarde...
                </div>
              )) || (
                <>
                  <span>Alterar</span>
                </>
              )}
            </button>
          </div>
        </form>
      </CardModal>
    </>
  );
}
