import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Avatar from '../../components/Avatar';
import perfil from '../../assets/em-branco.png';

export default function Colaboradores() {
  const history = useHistory();
  const [colaboradores, setColaboradores] = useState([]);

  const auth = useSelector((state) => state.auth);

  async function loadColaboradores() {
    await api
      .get(`colaborador/loja/${auth.data.lojaId}`)
      .then((response) => {
        setColaboradores(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        console.log('finally');
      });
  }

  useEffect(() => {
    loadColaboradores();
  }, []);

  async function handleRemover(id) {
    await api
      .delete(`colaborador/${id}`)
      .then((response) => {
        console.log(response.data);
        setColaboradores(
          colaboradores.filter((colaborador) => colaborador.id !== id)
        );
      })
      .catch((error) => console.log(error));
  }

  return (
    <div>
      <NavBar />

      <div className="container mx-auto p-2 pt-20">
        <button
          type="button"
          onClick={() => history.push('/colaboradores/colaborador')}
          className="btn btn-pink my-2 flex items-center"
        >
          <MdAdd />
          Novo
        </button>

        <MaterialTable
          title="Funcionários"
          columns={[
            {
              title: 'Id',
              field: 'id',
              // hidden: true,
            },
            {
              title: 'Foto',
              field: 'foto',
              render: (rowData) => (
                <Avatar
                  perfil={
                    rowData.foto
                      ? `${process.env.REACT_APP_BASE_URL_STORAGE}/${rowData.foto}`
                      : perfil
                  }
                  wh="w-10 h-10"
                  brd="border-2"
                  mx="mx-auto"
                />
              ),
              cellStyle: {
                textAlign: 'center',
                padding: 0,
              },
            },
            {
              title: 'Nome',
              field: 'nome',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Cargo',
              field: 'cargos.cargo',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Contato',
              field: 'contato1',
            },

            {
              title: 'Email',
              field: 'email',
            },
            {
              title: 'Usuário',
              field: 'users.user_name',
            },
            {
              title: 'Tipo',
              field: 'cargos.nivel_acesso',
              lookup: { 2: 'Admin', 1: 'Vendedor' },
            },
            {
              title: 'Status',
              field: 'status',
              lookup: { 0: 'Inativo', 1: 'Ativo', 2: 'Linceça' },
            },
          ]}
          data={colaboradores}
          actions={[
            (rowData) => ({
              icon: 'edit',
              tooltip: 'Editar Usuário',
              onClick: (event, rowData) =>
                history.push('/colaboradores/colaborador', { data: rowData }),
              disabled: rowData.users.user_name === auth.data.userName,
            }),
            /** 
            (rowData) => ({
              icon: "delete",
              tooltip: "Remover Colaborador",
              onClick: (event, rowData) => {
                handleRemover(rowData.id);
              },

              disabled: rowData.users.user_name === auth.data.userName,
            }), */
            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => loadColaboradores(),
            },
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: true,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </div>
    </div>
  );
}
