import React, { useState, useEffect, memo } from 'react';

import api from '../../../services/api';

import CardModal from '../../../components/CardModal';

function Cupom({
  comandaId = null,
  lojaId = null,
  isOpen = false,
  setIsOpen = () => {},
}) {
  const [loja, setLoja] = useState({});
  const [empresa, setEmpresa] = useState({});
  const [comanda, setComanda] = useState({});
  const [caixaItem, setCaixaItem] = useState({});
  const [itemComanda, setItemComanda] = useState([]);
  const [nomeColaborador, setNomeColaborador] = useState('');

  useEffect(() => {
    (async function loadLojas() {
      await api
        .get(`loja/${lojaId}`)
        .then((response) => {
          setLoja(response.data);
          setEmpresa(response.data.empresas[0]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          console.log('finally');
        });
    })();
  }, []);

  async function loadColaborador(colaboradorId) {
    await api
      .get(`colaborador/${colaboradorId}`)
      .then((response) => {
        setNomeColaborador(response.data.nome);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log('finally');
      });
  }

  useEffect(() => {
    /** requesição de comandas pelo comanda */
    (async function loadComanda() {
      await api
        .get(`comanda/${comandaId}`)
        .then((response) => {
          // console.log('comanda...');
          // console.log(response.data);
          setComanda(response.data);

          setItemComanda(response.data.item_comanda);
          setCaixaItem(response.data.caixa_item);

          loadColaborador(response.data.colaborador_id);
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          console.log('finally');
        });
    })();
  }, []);

  const formato = {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  };

  return (
    <>
      {/**
       * modal de cupom de compra
       * */}
      <CardModal
        isDialogOpen={isOpen}
        setIsDialogOpen={() => setIsOpen(!isOpen)}
        titulo="Cupom de Compras"
        style="max-w-2xl p-0 w-full -mt-1 md:mt-10"
      >
        <div className="max-w-2xs w-full mb-6 mx-auto">
          <div className=" bg-white border border-dashed p-4 pb-6 text-center text-xxs">
            <div className="divisor-pontilhado py-3">
              <h1 className="my-2 text-base font-medium">
                {empresa.nome_fantasia}
              </h1>
              <div>
                <p>Obrigado pela preferência</p>
              </div>
            </div>

            <div className="divisor-pontilhado py-3  font-medium">
              <p>Cupom não fiscal</p>
            </div>

            <div className="divisor-pontilhado px-2 py-3 text-left">
              <div className="flex items-center justify-between ">
                <span>{new Date(comanda.fechamento).toLocaleString()}</span>
                <span>{`Lo.: ${loja.loja}`}</span>
              </div>
              <p>{`Op.: ${nomeColaborador}`}</p>
            </div>

            <div className="divisor-pontilhado px-2 py-3 text-left">
              <table>
                <thead>
                  <tr>
                    <td className="p-1">Item </td>
                    {/* <td className="p-1">Cod. </td> */}
                    <td className="p-1">Descricao </td>
                    <td className="p-1">Qtde. </td>
                    <td className="p-1">Vl.unit </td>
                    <td className="p-2" />
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  {itemComanda.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      {/* <td>{item.estoques.produto.codigo_barras}</td> */}
                      <td>{item.estoques.produto.produto}</td>
                      <td className="text-right">{item.quantidade}</td>
                      <td className="text-right">
                        {parseFloat(
                          item.estoques.produto.produto_preco.preco_varejo
                        ).toLocaleString('pt-BR', formato)}
                      </td>
                      <td />
                      <td className="text-right">
                        {(
                          parseFloat(
                            item.estoques.produto.produto_preco.preco_varejo
                          ) * parseFloat(item.quantidade)
                        ).toLocaleString('pt-BR', formato)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="divisor-pontilhado px-2 py-3 text-xs text-left">
              <div className="flex justify-between items-center font-medium">
                <span>Valor: </span>
                <span>
                  {(
                    parseFloat(caixaItem.valor) +
                    (parseFloat(caixaItem.valor_desconto) +
                      parseFloat(caixaItem.valor_desconto_promocao))
                  ).toLocaleString('pt-BR', formato)}
                </span>
              </div>
              <div className="flex justify-between items-center font-medium">
                <span>Desconto: </span>
                <span>
                  {(
                    parseFloat(caixaItem.valor_desconto) +
                    parseFloat(caixaItem.valor_desconto_promocao)
                  ).toLocaleString('pt-BR', formato)}
                </span>
              </div>
              <div className="flex justify-between items-center font-medium">
                <span>Total Valor: </span>
                <span>
                  {parseFloat(caixaItem.valor).toLocaleString('pt-BR', formato)}
                </span>
              </div>
            </div>

            <div className="p-3 py-3 font-medium uppercase">
              <p>
                {`Consumidor: ${
                  comanda.cliente
                    ? comanda.cliente.nome_responsavel
                    : 'NÃO IDENTIFICADO'
                }`}
              </p>
            </div>
          </div>
        </div>
      </CardModal>
    </>
  );
}

export default memo(Cupom);
