/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MdSave, MdArrowBack } from 'react-icons/md';

import NavBar from '../../../components/NavBar';

import api from '../../../services/api';

export default function EstoqueEdit() {
  const history = useHistory();
  const location = useLocation();

  const [id, setId] = useState(null);
  const [codigoBarras, setCodigoBarras] = useState('');
  const [produto, setProduto] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [descricao, setDescricao] = useState('');
  const [quantidadeMin, setQuantidadeMin] = useState('');
  const [quantidadeMax, setQuantidadeMax] = useState('');
  const [localArmazenamento, setLocalArmazenamento] = useState('');
  const [observacao, setObservacao] = useState('');

  useEffect(() => {
    if (location.state) {
      const { estoque, produto, codigo_barras } = location.state;
      setProduto(produto);
      setCodigoBarras(codigo_barras);
      setId(estoque.id);
      setAtivo(estoque.ativo);
      setDescricao(estoque.descricao);
      setQuantidadeMin(estoque.quantidade_min);
      setQuantidadeMax(estoque.quantidade_max);
      setLocalArmazenamento(estoque.local_armazenamento);
      setObservacao(estoque.observacao);
    }
  }, []);

  async function up(id) {
    await api
      .put(`estoque/${id}`, {
        descricao,
        quantidade_min: quantidadeMin,
        quantidade_max: quantidadeMax,
        local_armazenamento: localArmazenamento,
        ativo,
        observacao,
      })
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => console.log(error));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (id) {
      up(id);
    }
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20">
        <div className="flex justify-between max-w-2xl mx-auto">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="btn btn-pink"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-pink-500">Estitar Estoque</h1>
          <div className="mx-8" />
        </div>

        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto mt-5">
          {/** */}
          <div className="flex ">
            <div className="mb-2 w-1/3">
              <label className="text-sm text-pink-500">Código</label>
              <p>{codigoBarras}</p>
            </div>
            <div className="mb-2 w-2/3">
              <label className="text-sm text-pink-500">Produto</label>
              <p>{produto}</p>
            </div>
          </div>

          <div>
            <label className="block text-sm text-pink-500">
              Descriçao Estoque
            </label>
            <input
              type="text"
              placeholder=""
              className="block input-text shadow"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </div>

          <div className="flex sm:w-3/4">
            <div className="w-1/2 pr-2">
              <label className="block text-sm text-pink-500">
                Quantidade minimo
              </label>
              <input
                type="text"
                placeholder=""
                className="block input-text shadow"
                value={quantidadeMin}
                onChange={(e) => setQuantidadeMin(e.target.value)}
              />
            </div>

            <div className="w-1/2">
              <label className="block text-sm text-pink-500">
                Quantidade máximo
              </label>
              <input
                type="text"
                placeholder=""
                className="block input-text shadow"
                value={quantidadeMax}
                onChange={(e) => setQuantidadeMax(e.target.value)}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm text-pink-500">
              Local armazenamento
            </label>
            <input
              type="text"
              placeholder=""
              className="block input-text shadow"
              value={localArmazenamento}
              onChange={(e) => setLocalArmazenamento(e.target.value)}
            />
          </div>

          <div className="flex xl:max-w-3xl">
            <div className="w-1/2 mx-1 my-2">
              <label className="block text-sm text-pink-500">Status</label>

              <div className="relative w-full">
                <select
                  className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                  id="grid-state"
                  value={ativo}
                  onChange={(e) => setAtivo(e.target.value)}
                >
                  <option value="1">Ativo</option>
                  <option value="0">Inativo</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div>
            <label className="text-sm text-pink-500">Descrição</label>
            <textarea
              rows="4"
              cols="50"
              placeholder=""
              className="block resize-none input-text shadow mb-3 h-24 min-h-20"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
            />
          </div>

          <button
            type="submit"
            className="btn btn-pink flex items-center justify-center w-full"
          >
            <MdSave />
            <span> Salvar</span>
          </button>
        </form>
      </div>
    </>
  );
}
