/* eslint-disable no-shadow */
import React, { useState, useLayoutEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import NavBAr from '../../components/NavBar';
import StepsIndicators from '../../components/StepsIndicators';

import Cliente from './Cliente';
import Endereco from './Endereco';
import Contato from './Contato';

export default function ClienteForm() {
  const history = useHistory();

  /** estado no redux */
  const auth = useSelector((state) => state.auth);

  const [step, setStep] = useState(1);

  const [cliente, setCliente] = useState({});
  const [endereco, setEndereco] = useState({});
  const [contato, setContato] = useState({});

  const [load, setLoad] = useState(false);

  const [edit, setEdit] = useState();

  const location = useLocation();

  useLayoutEffect(() => {
    if (location.state) {
      const data = location.state;
      setEdit(data.edit);
      console.log(data.edit, data.data);

      switch (data.edit) {
        case 'cliente':
          setCliente(data.data);
          setStep(1);
          break;
        case 'endereco':
          setEndereco(data.data);
          setStep(2);
          break;
        case 'contato':
          setContato(data.data);
          setStep(3);
          break;

        default:
          break;
      }
    }
  }, []);

  function nextStep() {
    setStep(step + 1);
  }

  async function handleClienteSubmit(contato) {
    setLoad(true);

    const data = {
      loja_id: auth.data.lojaId,
      nome_responsavel: cliente.nomeResponsavel,
      razao_social: cliente.razaoSocial,
      nome_fantasia: cliente.nomeFantasia,
      pessoa: cliente.pessoa,
      cnpj: !!cliente.cnpj && cliente.cnpj.replace(/[^0-9]+/g, ''),
      cpf: !!cliente.cpf && cliente.cpf.replace(/[^0-9]+/g, ''),
      inscricao_estadual: cliente.inscricaoEstadual,
      observacao: cliente.observacao,
      cep: Number(endereco.cep.replace(/[^0-9]+/g, '')),
      tipo_endereco: endereco.tipoEndereco,
      cidade: endereco.cidade,
      bairro: endereco.bairro,
      logradouro: endereco.logradouro,
      uf: endereco.uf,
      complemento: endereco.complemento,
      email: contato.email,
      contato1: Number(contato.contato1.replace(/[^0-9]+/g, '')),
      contato1_whatsapp: contato.contato1Whatsapp,
      contato1_telegram: contato.contato1Telegram,
      contato2: contato.contato2
        ? Number(contato.contato2.replace(/[^0-9]+/g, ''))
        : null,
      contato2_whatsapp: contato.contato2Whatsapp,
      contato2_telegram: contato.contato2Telegram,
    };

    // console.log(data);

    await api
      .post('/cliente', data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        console.log('finally');
        setLoad(false);
      });
  }

  function Steps() {
    switch (step) {
      case 1:
        return (
          <Cliente
            setCliente={setCliente}
            cliente={cliente}
            nextStep={nextStep}
            history={history}
          />
        );
      case 2:
        return (
          <Endereco
            setEndereco={setEndereco}
            endereco={endereco}
            nextStep={nextStep}
            history={history}
          />
        );
      case 3:
        return (
          <Contato
            setContato={setContato}
            contato={contato}
            load={load}
            setLoad={setLoad}
            handleClienteSubmit={handleClienteSubmit}
            history={history}
          />
        );
      default:
        break;
    }
  }

  return (
    <>
      <NavBAr />
      <div className="container mx-auto pt-20 max-w-4xl">
        <h1 className="text-2xl text-pink-500 text-center">
          Cadastrar Cliente
        </h1>
        {!edit && (
          <StepsIndicators step={step} setStep={setStep} form="Cliente" />
        )}

        <Steps />
      </div>
    </>
  );
}
