/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect, memo } from 'react';
import { MdSave, MdNavigateNext } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Spinner from 'react-md-spinner';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

function Cliente({ setCliente = () => {}, cliente = {}, nextStep = () => {} }) {
  const history = useHistory();
  const [nomeResponsavel, setNomeResponsavel] = useState();
  const [nomeFantasia, setNomeFantasia] = useState();
  const [razaoSocial, setRazaoSocial] = useState();
  const [pessoa, setPessoa] = useState();
  const [cpf, setCpf] = useState();
  const [cnpj, setCnpj] = useState();
  const [inscricaoEstadual, setInscricaoEstadual] = useState();
  const [observacao, setObservacao] = useState();
  const [load, setLoad] = useState(false);

  useLayoutEffect(() => {
    setNomeResponsavel((!!cliente && cliente.nomeResponsavel) || '');
    setNomeFantasia((!!cliente && cliente.nomeFantasia) || '');
    setRazaoSocial((!!cliente && cliente.razaoSocial) || '');
    setPessoa((!!cliente && cliente.pessoa) || 'fisica');
    setCpf((!!cliente && cliente.cpf) || '');
    setCnpj((!!cliente && cliente.cnpj) || '');
    setInscricaoEstadual((!!cliente && cliente.inscricaoEstadual) || '');
    setObservacao((!!cliente && cliente.observacao) || '');
  }, []);

  async function up(id) {
    setLoad(true);
    const data = {
      nome_responsavel: nomeResponsavel,
      razao_social: razaoSocial,
      nome_fantasia: nomeFantasia,
      pessoa,
      cnpj: cnpj.replace(/[^0-9]+/g, ''),
      cpf: cpf.replace(/[^0-9]+/g, ''),
      inscricao_estadual: inscricaoEstadual,
      observacao,
    };

    await api
      .put(`/cliente/${id}`, data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!cliente.id) {
      setCliente({
        nomeResponsavel,
        nomeFantasia,
        razaoSocial,
        pessoa,
        cpf,
        cnpj,
        inscricaoEstadual,
        observacao,
      });
      nextStep();
    } else {
      up(cliente.id);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="px-2 py-4">
      <div className="w-1/2 md:w-1/3 pb-2">
        <label className="text-sm text-pink-500">Pessoa</label>
        <div className="relative w-full">
          <select
            className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 pt-3 pb-2 px-4 pr-8 leading-tight focus:outline-none "
            id="grid-state"
            value={pessoa}
            onChange={(e) => setPessoa(e.target.value)}
          >
            <option value="fisica">Física</option>
            <option value="juridica">Jurídica</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      {(pessoa === 'fisica' && (
        <>
          <label className="text-sm text-pink-500">Nome Cliente</label>
          <input
            type="text"
            placeholder=""
            required
            className="input-text shadow"
            value={nomeResponsavel}
            onChange={(e) => setNomeResponsavel(e.target.value)}
          />
          <div className="mr-2 w-1/2 md:w-1/3">
            <label className="text-sm text-pink-500">CPF</label>
            <NumberFormat
              format="###.###.###-##"
              mask="_"
              displayType="number"
              placeholder=""
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              className="input-text shadow"
            />
          </div>
        </>
      )) || (
        <>
          <label className="text-sm text-pink-500">Nome Responsável</label>
          <input
            type="text"
            placeholder=""
            required
            className="input-text shadow"
            value={nomeResponsavel}
            onChange={(e) => setNomeResponsavel(e.target.value)}
          />
          <label className="text-sm text-pink-500">Nome Fantasia</label>
          <input
            type="text"
            placeholder=""
            required
            className="input-text shadow"
            value={nomeFantasia}
            onChange={(e) => setNomeFantasia(e.target.value)}
          />
          <label className="text-sm text-pink-500">Razão Social</label>
          <input
            type="text"
            placeholder=""
            required
            className="input-text shadow"
            value={razaoSocial}
            onChange={(e) => setRazaoSocial(e.target.value)}
          />
          <div className="flex">
            <div className="mr-2 w-1/3 md:w-1/4">
              <label className="text-sm text-pink-500">CNPJ</label>
              <NumberFormat
                format="##.###.###/####-##"
                mask="_"
                required
                displayType="number"
                placeholder=""
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                className="input-text shadow"
              />
            </div>
            <div className="mr-2 w-1/3 md:w-1/4">
              <label className="text-sm text-pink-500">
                Inscricao Estadual
              </label>
              <input
                type="number"
                placeholder=""
                required
                value={inscricaoEstadual}
                onChange={(e) => setInscricaoEstadual(e.target.value)}
                className="input-text shadow"
              />
            </div>
          </div>
        </>
      )}
      <div>
        <label className="text-sm text-pink-500">Observação</label>
        <textarea
          rows="4"
          cols="50"
          placeholder=""
          className="block resize-none input-text shadow mb-3 h-24 min-h-20"
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
        />
      </div>
      <div>
        <button
          disabled={load}
          type="submit"
          className={`btn btn-pink flex items-center justify-center w-full ${
            load && 'disabled'
          }`}
        >
          {cliente.id ? (
            <div className="flex justify-center items-center">
              {(load && (
                <>
                  <Spinner
                    className="mx-1"
                    size={24}
                    singleColor="#fbb6ce"
                    borderSize={4}
                  />
                  aguarde...
                </>
              )) || (
                <>
                  <MdSave />
                  <span>Salvar</span>
                </>
              )}
            </div>
          ) : (
            <>
              <span>Continuar </span>
              <MdNavigateNext />
            </>
          )}
        </button>
      </div>
    </form>
  );
}

export default memo(Cliente);
