const INITIAL_STATE = {
  caixaId: null,
  status: false,
};

export default function caixaAberto(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_CAIXA':
      return action.data;
    case 'REMOVE_CAIXA':
      return INITIAL_STATE;
    default:
      return state;
  }
}
