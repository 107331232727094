import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { MdAdd } from 'react-icons/md';
// import Spinner from 'react-md-spinner';

import api from '../../services/api';

import NavBar from '../../components/NavBar';

export default function Produtos() {
  const history = useHistory();

  // state off redux
  const auth = useSelector((state) => state.auth);

  const [produtos, setProdutos] = useState();
  // const [load, setLoad] = useState(false);

  async function loadProdutos() {
    await api
      .get(`/produto/loja/${auth.data.lojaId}`)
      .then((response) => {
        setProdutos(response.data);
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    loadProdutos();
  }, []);

  /* 
  async function handleRemover(id) {
    setLoad(true);
    await api
      .delete(`/produto/${id}`)
      .then((response) => {
        console.log(response.data);
        setProdutos(produtos.filter((produto) => produto.id !== id));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoad(false);
      });
  }
  */

  return (
    <div>
      <NavBar />

      <div className="container mx-auto p-2 pt-20">
        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => history.push('/produtos/produto')}
            className="btn btn-pink my-2 flex items-center"
          >
            <MdAdd />
            Novo
          </button>
          <button
            type="button"
            onClick={() => history.push('/produtos/grupos')}
            className="btn btn-pink my-2 flex items-center"
          >
            <MdAdd />
            Grupos
          </button>
        </div>

        <MaterialTable
          title="Produtos"
          columns={[
            {
              title: 'Id',
              field: 'id',
              hidden: true,
            },
            {
              title: 'Código',
              field: 'codigo_barras',
              // hidden: true,
            },
            {
              title: 'Produto',
              field: 'produto',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Tamanho',
              field: 'tamanho',
              // hidden: true,
            },
            {
              title: 'Fabricante',
              field: 'fabricante',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Marca',
              field: 'marca',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },

            {
              title: 'Fornecendor',
              field: 'fornecedor.nome_fantasia',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Grupo',
              field: 'grupo_produto.grupo',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Status',
              field: 'status',
              lookup: { 0: 'Inativo', 1: 'Ativo' },
            },
          ]}
          data={produtos}
          actions={[
            (rowData) => ({
              icon: 'assignment',
              tooltip: 'Editar Estoque',
              onClick: () =>
                history.push('/produtos/estoque', { id: rowData.id }),
            }),
            (rowData) => ({
              icon: 'visibility',
              tooltip: 'Visualidar',
              onClick: () =>
                history.push('/produtos/detalhes', { id: rowData.id }),
            }),
            /**  
            (rowData) => ({
              icon: "delete",
              tooltip: "Remover",
              onClick: () => {
                handleRemover(rowData.id);
              },
              disabled: load,
            }), */
            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => loadProdutos(),
            },
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: true,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </div>
    </div>
  );
}
