/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import NavBar from '../../components/NavBar';
import api from '../../services/api';
import CardItemDetalhes from '../../components/CardItemDetalhes';

export default function RegCompras() {
  const history = useHistory();
  const [comandas, setComandas] = useState([]);
  const [cliente, setCliente] = useState('');

  const location = useLocation();

  async function loadComandas(clienteId) {
    await api
      .get(`comanda/cliente/${clienteId}`)
      .then((response) => {
        setComandas(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        console.log('finally');
      });
  }
  useEffect(() => {
    if (location.state) {
      const {
        cliente_id,
        pessoa,
        nome_responsavel,
        razao_social,
      } = location.state;
      loadComandas(cliente_id);
      setCliente(pessoa === 'fisica' ? nome_responsavel : razao_social);
    }
  }, []);
  return (
    <div>
      <NavBar />

      <div className="container mx-auto p-2 pt-20">
        <div className="flex justify-between items-center mb-4 max-w-4xl mx-auto">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="flex btn btn-pink items-center"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-center text-pink-500 font-semibold -ml-16">
            Compras Clientes
          </h1>
          <div />
        </div>
        {/* component */}

        <div className="max-w-4xl mx-auto overflow-auto p-2">
          <h1 className="text-lg text-gray-700 p-1">{cliente}</h1>
          <div className=" mx-1 shadow px-3 bg-red-100 flex justify-between items-center rounded">
            <div className="p-4" />
            <div className="w-full px-2 flex justify-between items-center divide-x divide-gray-400">
              <span className="w-full px-2">data</span>
              <span className="w-full px-2">valor</span>
              <span className="w-full px-2">promocao</span>
              <span className="w-full px-2">desconto</span>
            </div>
          </div>
          <ul>
            {comandas.map((comanda) => (
              <CardItemDetalhes
                key={comandas.id}
                data={comanda}
                history={history}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
