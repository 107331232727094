import React, { useState, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from '../../services/api';

import NavBAr from '../../components/NavBar';
import StepsIndicators from '../../components/StepsIndicators';

import Fornecedor from './Fornecedor';
import Endereco from './Endereco';
import Contato from './Contato';

export default function FornecedorForm() {
  const history = useHistory();
  const [step, setStep] = useState(1);

  const [fornecedor, setFornecedor] = useState({});
  const [endereco, setEndereco] = useState({});
  const [contato, setContato] = useState({});

  const [load, setLoad] = useState(false);

  const [edit, setEdit] = useState();

  const auth = useSelector((state) => state.auth);

  const location = useLocation();

  useLayoutEffect(() => {
    if (location.state) {
      const data = location.state;
      setEdit(data.edit);
      // console.log(data.edit, data.data);

      switch (data.edit) {
        case 'fornecedor':
          setFornecedor(data.data);
          setStep(1);
          break;
        case 'endereco':
          setEndereco(data.data);
          setStep(2);
          break;
        case 'contato':
          setContato(data.data);
          setStep(3);
          break;

        default:
          break;
      }
    }
  }, []);

  function nextStep() {
    setStep(step + 1);
  }

  async function handleClienteSubmit(contatoCliente = {}) {
    setLoad(true);
    const data = {
      loja_id: auth.data.lojaId,
      nome_responsavel: fornecedor.nomeResponsavel,
      razao_social: fornecedor.razaoSocial,
      descricao: fornecedor.descricao,
      nome_fantasia: fornecedor.nomeFantasia,
      pessoa: fornecedor.pessoa,
      cnpj: !!fornecedor.cnpj && fornecedor.cnpj.replace(/[^0-9]+/g, ''),
      cpf: !!fornecedor.cpf && fornecedor.cpf.replace(/[^0-9]+/g, ''),
      inscricao_estadual: fornecedor.inscricaoEstadual,
      observacao: fornecedor.observacao,
      ativo: fornecedor.ativo,
      cep: endereco.cep.replace(/[^0-9]+/g, ''),
      tipo_endereco: endereco.tipoEndereco,
      cidade: endereco.cidade,
      bairro: endereco.bairro,
      logradouro: endereco.logradouro,
      uf: endereco.uf,
      complemento: endereco.complemento,
      email: contatoCliente.email,
      contato1: contatoCliente.contato1.replace(/[^0-9]+/g, ''),
      contato1_whatsapp: contatoCliente.contato1Whatsapp,
      contato1_telegram: contatoCliente.contato1Telegram,
      contato2:
        contatoCliente.contato2 &&
        contatoCliente.contato2.replace(/[^0-9]+/g, ''),
      contato2_whatsapp: contatoCliente.contato2Whatsapp,
      contato2_telegram: contatoCliente.contato2Telegram,
    };

    console.log('fornecedor');
    console.log(data);

    await api
      .post('/fornecedor', data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function Steps() {
    switch (step) {
      case 1:
        return (
          <Fornecedor
            setFornecedor={setFornecedor}
            fornecedor={fornecedor}
            nextStep={nextStep}
            history={history}
          />
        );
      case 2:
        return (
          <Endereco
            setEndereco={setEndereco}
            endereco={endereco}
            nextStep={nextStep}
            history={history}
          />
        );
      case 3:
        return (
          <Contato
            setContato={setContato}
            contato={contato}
            handleClienteSubmit={handleClienteSubmit}
            history={history}
            load={load}
          />
        );
      default:
        break;
    }
  }

  return (
    <>
      <NavBAr />
      <div className="container mx-auto pt-20 max-w-3xl">
        <h1 className="text-2xl text-pink-500 text-center">
          Cadastrar fornecedor
        </h1>
        {!edit && (
          <StepsIndicators step={step} setStep={setStep} form="Fornecedor" />
        )}

        <Steps />
      </div>
    </>
  );
}
