/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Spinner from 'react-md-spinner';
import { useSelector } from 'react-redux';
import { MdSave, MdArrowBack } from 'react-icons/md';

import NavBar from '../../components/NavBar';
import numberFormatterBr from '../../components/numberFormatterBr';

import api from '../../services/api';

export default function PromocaoForm() {
  const history = useHistory();
  const [id, setId] = useState(null);
  const [descricao, setDescricao] = useState('');
  const [porcentagem, setPorcentagem] = useState(false);
  const [desconto, setDesconto] = useState(0);
  const [abertura, setAbertura] = useState(new Date().toLocaleDateString());
  const [fechamento, setFechamento] = useState('');
  const [observacao, setObservacao] = useState('');
  const [ativo, setAtivo] = useState(true);

  const [formErro, setFormErro] = useState('');
  const [load, setLoad] = useState(false);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      setFormErro(false);
    }, 3000);
  }, [formErro]);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const {
        id,
        descricao,
        porcentagem,
        desconto,
        abertura,
        fechamento,
        observacao,
        ativo,
      } = location.state.data;
      setId(id);
      setDescricao(descricao);
      setPorcentagem(porcentagem);
      setDesconto(desconto);
      setAbertura(moment(abertura, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      setFechamento(moment(fechamento, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      setObservacao(observacao);
      setAtivo(ativo);
    }
  }, []);

  async function add(data) {
    setLoad(true);
    await api
      .post(`promocao`, data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        console.log('finally');
        setLoad(false);
      });
  }

  async function up(id, data) {
    setLoad(true);
    await api
      .put(`promocao/${id}`, data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        console.log('finally');
        setLoad(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const dataAbertura = moment(abertura, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const dataFechamento = moment(fechamento, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    );
    /*
    console.log(dataAbertura);
    console.log(dataFechamento);
    */
    if (dataAbertura == 'Invalid date' || dataFechamento == 'Invalid date') {
      setFormErro('Formato de data icorreto!');
    } else {
      const data = {
        loja_id: auth.data.lojaId,
        descricao,
        porcentagem,
        desconto: isFinite(desconto)
          ? desconto
          : parseFloat(desconto.replace(/[^\d\,]/g, '').replace(',', '.')),
        abertura: moment(abertura, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        fechamento: moment(fechamento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        observacao,
        ativo,
      };

      if (id) {
        up(id, data);
      } else {
        add(data);
      }
    }
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20">
        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="btn btn-pink"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-pink-500">Promoção</h1>
          <div className="mx-8" />
        </div>
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
          <p className="text-red-500 text-base text-center">{formErro}</p>
          <label className="block text-sm text-pink-500">Descrição</label>
          <input
            type="text"
            placeholder=""
            required
            className="block input-text shadow"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />

          <div className="flex flex-col sm:flex-row xl:max-w-3xl">
            <div className="sm:w-1/2 mx-1 my-2">
              <label className="block text-sm text-pink-500">Desconto</label>
              <div className="flex">
                <div className="relative w-32 mr-1">
                  <select
                    className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-1 pr-3 leading-tight focus:outline-none "
                    id="grid-state"
                    value={porcentagem}
                    onChange={(e) => setPorcentagem(e.target.value)}
                  >
                    <option value={0}>R$</option>
                    <option value={1}>%</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <NumberFormat
                  allowNegative={false}
                  displayType="number"
                  required
                  format={numberFormatterBr}
                  className="block input-text shadow m-0"
                  value={desconto}
                  onChange={(e) => setDesconto(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="flex xl:max-w-3xl">
            <div className="w-1/2 mx-1 my-2">
              <label className="block text-sm text-pink-500">Abertura</label>

              <NumberFormat
                format="##/##/####"
                placeholder="00/00/0000"
                mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                className="block input-text shadow m-0"
                required
                value={abertura}
                onChange={(e) => setAbertura(e.target.value)}
              />
            </div>
            <div className="w-1/2 mx-1 my-2">
              <label className="block text-sm text-pink-500">Fechamento</label>
              <NumberFormat
                format="##/##/####"
                placeholder="00/00/0000"
                required
                mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                className="block input-text shadow m-0"
                value={fechamento}
                onChange={(e) => setFechamento(e.target.value)}
              />
            </div>
          </div>
          <div className="w-1/2 mx-1 my-2">
            <label className="block text-sm text-pink-500">Ativo</label>

            <div className="relative w-full">
              <select
                className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                id="grid-state"
                value={ativo}
                onChange={(e) => setAtivo(e.target.value)}
              >
                <option value="1">Ativo</option>
                <option value="0">inativo</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <label className="block text-sm text-pink-500">Observação</label>
          <textarea
            rows="4"
            cols="50"
            placeholder=""
            className="block resize-none input-text shadow mb-3 h-24 min-h-20"
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />
          <button
            type="submit"
            disabled={load}
            className={`btn btn-pink flex items-center justify-center w-full ${
              load && 'disabled'
            }`}
          >
            {(load && (
              <div className="flex justify-center items-center">
                <Spinner
                  className="mx-1"
                  size={24}
                  singleColor="#fbb6ce"
                  borderSize={4}
                />
                aguarde...
              </div>
            )) || (
              <>
                <span>{`${(<MdSave />)} Salvar`}</span>
              </>
            )}
          </button>
        </form>
      </div>
    </>
  );
}
