/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useLayoutEffect, memo } from 'react';
import { MdSave, MdNavigateNext } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Spinner from 'react-md-spinner';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

function Endereco({
  setEndereco = () => {},
  endereco = {},
  nextStep = () => {},
}) {
  const history = useHistory();
  const [cep, setCep] = useState();
  const [logradouro, setLogradouro] = useState();
  const [tipoEndereco, setTipoEndereco] = useState();
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();
  const [uf, setUf] = useState();
  const [complemento, setComplemento] = useState();

  const [load, setLoad] = useState(false);
  const [loadCep, setLoadCep] = useState(false);

  const [cepErro, setCepErro] = useState(false);

  useLayoutEffect(() => {
    setCep((!!endereco && endereco.cep) || '');
    setLogradouro((!!endereco && endereco.logradouro) || '');
    setTipoEndereco((!!endereco && endereco.tipoEndereco) || 'residencial');
    setBairro((!!endereco && endereco.bairro) || '');
    setCidade((!!endereco && endereco.cidade) || '');
    setUf((!!endereco && endereco.uf) || '');
    setComplemento((!!endereco && endereco.complemento) || '');
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCepErro(false);
    }, 3000);
  }, [cepErro]);

  async function handleCep(cep) {
    if (cep) {
      setLoadCep(true);
      const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'fedault',
      };
      await api
        .get(`https://viacep.com.br/ws/${cep}/json/`, options)
        .then((response) => {
          const endereco = response.data;
          setLogradouro(endereco.logradouro);
          setCidade(endereco.localidade);
          setBairro(endereco.bairro);
          setUf(endereco.uf);
          // console.log(endereco);
        })
        .catch((error) => {
          console.log(error);
          setCepErro(true);
        })
        .finally(() => {
          setLoadCep(false);
        });
    }
  }

  async function up(id) {
    setLoad(true);
    const data = {
      cep,
      logradouro,
      tipo_endereco: tipoEndereco,
      bairro,
      cidade,
      uf,
      complemento,
    };

    await api
      .put(`/cliente/${id}`, data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!endereco.id) {
      setEndereco({
        cep,
        logradouro,
        tipoEndereco,
        bairro,
        cidade,
        uf,
        complemento,
      });
      nextStep();
    } else {
      up(endereco.id);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="px-2 py-4">
      <div className="">
        <label className="text-sm text-pink-500">CEP</label>
        <div className="flex w-full sm:w-1/2 md:w-1/3">
          <div className="relative flex">
            <NumberFormat
              format="#####-###"
              mask="_"
              displayType="number"
              placeholder=""
              required
              className={`block input-text shadow border ${
                cepErro ? 'border-red-400 bg-red-100' : ''
              }`}
              value={cep}
              onBlur={(e) => handleCep(e.target.value)}
              onChange={(e) => setCep(e.target.value)}
            />
            {!!loadCep && (
              <div className="absolute right-0 top-0 p-2">
                <Spinner size={20} singleColor="#fbb6ce" borderSize={3} />
              </div>
            )}
          </div>
          <button
            type="button"
            onClick={() => handleCep(cep)}
            className="btn btn-pink ml-2 mt-0"
          >
            ok
          </button>
        </div>

        <div className="mr-2 mb-2 w-full sm:w-1/2 md:w-1/3">
          <label className="text-sm text-pink-500">Tipo Endereço</label>
          <div className="relative w-full">
            <select
              className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 pt-3 pb-2 px-4 pr-8 leading-tight focus:outline-none "
              id="grid-state"
              value={tipoEndereco}
              onChange={(e) => setTipoEndereco(e.target.value)}
            >
              <option value="residencial">Residecial</option>
              <option value="comercial">Comercial</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row">
          <div className="mr-2 w-full sm:w-1/2">
            <label className="text-sm text-pink-500">Logradoudo</label>
            <input
              type="text"
              placeholder=""
              className="input-text shadow"
              value={logradouro}
              onChange={(e) => setLogradouro(e.target.value)}
            />
          </div>
          <div className="mr-2 w-full sm:w-1/3">
            <label className="text-sm text-pink-500">Bairro</label>
            <input
              type="text"
              placeholder=""
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
              className="input-text shadow"
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="mr-2 w-full sm:w-1/2">
            <label className="text-sm text-pink-500">Cidade</label>
            <input
              type="text"
              placeholder=""
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
              className="input-text shadow"
            />
          </div>
          <div className="mr-2 w-full sm:w-1/4">
            <label className="text-sm text-pink-500">UF</label>
            <div className="relative w-full">
              <select
                className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 pt-3 pb-2 px-4 pr-8 leading-tight focus:outline-none "
                id="grid-state"
                value={uf}
                onChange={(e) => setUf(e.target.value)}
              >
                <option value="AC"> AC </option>
                <option value="AL"> AL </option>
                <option value="AP"> AP </option>
                <option value="AM"> AM </option>
                <option value="BA"> BA </option>
                <option value="CE"> CE </option>
                <option value="DF"> DF </option>
                <option value="ES"> ES </option>
                <option value="GO"> GO </option>
                <option value="MA"> MA </option>
                <option value="MT"> MT </option>
                <option value="MS"> MS </option>
                <option value="MG"> MG </option>
                <option value="PA"> PA </option>
                <option value="PB"> PB </option>
                <option value="PR"> PR </option>
                <option value="PE"> PE </option>
                <option value="PI"> PI </option>
                <option value="RJ"> RJ </option>
                <option value="RN"> RN </option>
                <option value="RS"> RS </option>
                <option value="RO"> RO </option>
                <option value="RR"> RR </option>
                <option value="SC"> SC </option>
                <option value="SP"> SP </option>
                <option value="SE"> SE </option>
                <option value="TO"> TO </option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label className="text-sm text-pink-500">Complemento</label>
          <textarea
            rows="4"
            cols="50"
            placeholder=""
            className="block resize-none input-text shadow mb-3 h-24 min-h-20"
            value={complemento}
            onChange={(e) => setComplemento(e.target.value)}
          />
        </div>
        <div>
          <button
            disabled={load}
            type="submit"
            className={`btn btn-pink flex items-center justify-center w-full ${
              load && 'disabled'
            }`}
          >
            {endereco.id ? (
              <div className="flex justify-center items-center">
                {(load && (
                  <>
                    <Spinner
                      className="mx-1"
                      size={24}
                      singleColor="#fbb6ce"
                      borderSize={4}
                    />
                    aguarde...
                  </>
                )) || (
                  <>
                    <MdSave />
                    <span>Salvar</span>
                  </>
                )}
              </div>
            ) : (
              <>
                <span>Continuar</span> <MdNavigateNext />
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

export default memo(Endereco);
