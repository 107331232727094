import React from 'react';

const Toast = ({ text = '', active = false }) => (
  <>
    {active && (
      <div className="w-full flex items-center justify-center">
        <div className=" fixed z-50 top-auto">
          <div className="bg-black text-white text-center text-sm py-1 px-8 rounded-lg shadow-xl bg-opacity-75">
            <p>{text}</p>
          </div>
        </div>
      </div>
    )}
  </>
);

export default Toast;
