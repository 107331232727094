/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { MdEdit, MdRefresh, MdAdd, MdArrowBack } from 'react-icons/md';
import NumberFormat from 'react-number-format';

import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Avatar from '../../components/Avatar';

import perfil from '../../assets/em-branco.png';

export default function Definicoes() {
  const history = useHistory();
  const [empresa, setEmpresa] = useState({});
  const [lojas, setLojas] = useState([]);

  useEffect(() => {
    (async function loadEmpresa() {
      await api
        .get(`empresa/${1}`)
        .then((response) => setEmpresa(response.data))
        .catch((error) => {
          console.log(error);
        });
    })();
  }, []);

  async function loadLojas() {
    await api
      .get('loja')
      .then((response) => setLojas(response.data))
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadLojas();
  }, []);

  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-20 text-gray-700 p-2">
        <div className="flex justify-between max-w-4xl mx-auto items-center">
          <Link to="/" className="flex btn btn-pink items-center">
            <MdArrowBack />
          </Link>
          <h1 className="text-2xl text-center text-pink-500 font-semibold -ml-16">
            Definições
          </h1>
          <div />
        </div>

        <div className="flex flex-col sm:flex-row my-4 max-w-4xl mx-auto bg-white shadow rounded-sm p-2">
          <div className="p-4 w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/4">
            <Avatar
              perfil={
                empresa.logo
                  ? `${process.env.REACT_APP_BASE_URL_STORAGE}/${empresa.logo}`
                  : '' || perfil
              }
              wh="w-32 h-32 xl:w-40 xl:h-40"
              brd="border-4"
              mx="mx-auto"
            />
          </div>

          <div className="p-4 w-full xl:max-w-4xl">
            <div className="flex justify-between w-full">
              <div>
                <label className="block text-sm text-pink-500">
                  Nome Fantasia
                </label>
                <p>{empresa.nome_fantasia}</p>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => history.push('/empresaform', { empresa })}
                  className="flex btn btn-pink text-base font-normal items-center px-3 py-1"
                >
                  <MdEdit />
                  <span>Editar</span>
                </button>
              </div>
            </div>

            <label className="block text-sm text-pink-500">Razão Sócial</label>
            <p>{empresa.razao_social}</p>

            <div className="flex  border-pink-400 ">
              <div className="w-1/2 mr-2">
                <label className="block text-sm text-pink-500">CNPJ</label>
                <NumberFormat
                  disabled
                  format="##.###.###/####-##"
                  className="bg-transparent w-full"
                  value={empresa.cnpj}
                />
              </div>
              <div className="w-1/2 ">
                <label className="block text-sm text-pink-500">
                  Inscrição Estadual
                </label>

                <NumberFormat
                  disabled
                  className="bg-transparent w-full"
                  value={empresa.inscricao_estadual}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row">
        <div className="p-4 w-full xl:max-w-3xl mx-auto border-pink-500">
          <div className="flex justify-between">
            <h1 className="text-xl text-pink-500">Lojas</h1>
            <div className="flex">
              <button
                type="button"
                onClick={() =>
                  history.push('/lojaform', { empresa_id: empresa.id })
                }
                className="flex btn mx-2 btn-pink text-base font-normal items-center px-3 py-1"
              >
                <MdAdd />
                <span>Novo</span>
              </button>
              <button
                type="button"
                onClick={() => loadLojas()}
                className="flex btn btn-pink text-base font-normal items-center px-3 py-1"
              >
                <MdRefresh />
                <span>Atualizar</span>
              </button>
            </div>
          </div>

          {lojas.map((loja) => (
            <div
              key={loja.id}
              className="bg-white flex justify-between rounded-lg w-full p-4 shadow-lg mt-2"
            >
              <div>
                <p className="py-2">{loja.loja}</p>

                <label className="block text-xs text-pink-500">Endereco</label>
                <p className="text-sm">
                  {`${loja.logradouro}, ${loja.bairro}, ${loja.cep}, ${loja.cidade}, ${loja.uf}`}
                </p>
                <label className="block text-xs text-pink-500">Tipo</label>
                <p className="text-sm">{loja.tipo_endereco}</p>
                <div className="flex ">
                  <div className="w-1/2">
                    <label className="block text-xs text-pink-500">
                      Contato
                    </label>
                    <NumberFormat
                      disabled
                      mask="(##) #####-####"
                      placeholder=""
                      className="bg-transparent w-full text-sm"
                      value={loja.contato.toString()}
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block text-xs text-pink-500">Email</label>
                    <p className="text-sm">{loja.email}</p>
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => history.push('lojaform', { loja })}
                  className="flex text-sm font-normal items-center p-2 hover:opacity-75 focus:opacity-75"
                >
                  <MdEdit />
                  <span>Editar</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
