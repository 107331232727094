/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { FiExternalLink } from 'react-icons/fi';

import api from '../../services/api';
import NavBar from '../../components/NavBar';

export default function Detalhes() {
  const history = useHistory();

  const [id, setId] = useState();
  const [produto, setProduto] = useState({});
  const [grupo, setGrupo] = useState({});
  const [fornecedor, setFornecedor] = useState({});
  const [produtoPreco, setProdutoPreco] = useState({});

  const location = useLocation();
  useLayoutEffect(() => {
    if (location.state) {
      // console.log(location.state.id);
      setId(location.state.id);
    }
  }, []);

  useEffect(() => {
    (async function loadProduto() {
      if (id) {
        await api
          .get(`/produto/${id}`)
          .then((response) => {
            setProduto(response.data);
            setGrupo(response.data.grupo_produto);
            setFornecedor(response.data.fornecedor);
            setProdutoPreco(response.data.produto_preco);
            // console.log(response.data);
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }
    })();
  }, [id]);

  function editar(edit) {
    let data = {};
    switch (edit) {
      case 'produto':
        // console.log(produto.cpf.toString().length);
        data = {
          id: produto.id,
          fornecedorId: produto.fornecedor_id,
          grupoProdutoId: produto.grupo_produto_id,
          codigoBarras: produto.codigo_barras,
          produto: produto.produto,
          descricao: produto.descricao,
          observacao: produto.observacao,
          status: produto.status,
          fabricante: produto.fabricante,
          marca: produto.marca,
          tamanho: produto.tamanho,

          precoCusto: produtoPreco.preco_custo,
          precoVarejo: produtoPreco.preco_varejo,
          precoAtacado: produtoPreco.preco_atacado,
        };
        // console.log(data);
        // console.log(produtoPreco);
        break;

      default:
        break;
    }
    history.push('/produtos/produto', { edit, data });
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20 ">
        <div className="flex justify-between items-center mb-10 max-w-4xl mx-auto">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="flex btn btn-pink items-center"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-center text-pink-500 font-semibold -ml-16">
            Dados produto
          </h1>
          <div />
        </div>
        <div className="max-w-4xl mx-auto p-3 bg-white shadow rounded-sm">
          <div className="py-3">
            <div className="px-2">
              <div className="flex justify-between pb-2">
                <div>
                  <label className="block text-sm text-pink-500">Código</label>
                  <p>{produto.codigo_barras}</p>
                </div>
                <button
                  type="button"
                  onClick={() => editar('produto')}
                  className="btn btn-pink py-1"
                >
                  Editar
                </button>
              </div>
              <div className="pb-2">
                <label className="block text-sm text-pink-500">Produto</label>
                <p>{produto.produto}</p>
              </div>
              <div className="pb-2">
                <label className="block text-sm text-pink-500">Tamanho</label>
                <p>{produto.tamanho}</p>
              </div>
              <div className="pb-2">
                <label className="block text-sm text-pink-500">Grupo</label>
                <p>{grupo.grupo}</p>
              </div>
              <div className="pb-2">
                <label className="block text-sm text-pink-500">Descrição</label>
                <p>{produto.descricao}</p>
              </div>

              <div className="pb-2">
                <label className="block text-sm text-pink-500">Status</label>
                <p>{Number(produto.status) === 1 ? 'Ativo' : 'Inativo'}</p>
              </div>

              <div className="pb-2">
                <label className="block text-sm text-pink-500">
                  Observacao
                </label>
                <p>{produto.observacao}</p>
              </div>

              <div className="flex">
                <div className="pb-2 pr-4  w-1/3">
                  <label className="block text-sm text-pink-500">
                    Preço Custo
                  </label>
                  <p>
                    {parseFloat(produtoPreco.preco_custo).toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </p>
                </div>
                <div className="pb-2 pr-4 w-1/3">
                  <label className="block text-sm text-pink-500">
                    Preço Venda
                  </label>
                  <p>
                    {parseFloat(produtoPreco.preco_varejo).toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </p>
                </div>
                <div className="pb-2 pr-4 w-1/3">
                  <label className="block text-sm text-pink-500">
                    Preço Revenda
                  </label>
                  <p>
                    {parseFloat(produtoPreco.preco_atacado).toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className=" py-3">
            <h1 className="text-xl text-pink-500 py-2 border border-pink-500 border-l-0 border-r-0 border-t-0">
              Fornecedor
            </h1>
            <div className="py-6 px-2">
              <div className="flex justify-between pb-2">
                <div>
                  <label className="block text-sm text-pink-500">
                    Responsável
                  </label>
                  <p>{fornecedor.nome_responsavel}</p>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    history.push('/fornecedores/detalhes', {
                      id: fornecedor.id,
                    })
                  }
                  className="flex items-center text text-pink-500 p-1 -mt-4"
                >
                  <FiExternalLink />
                  <span className="mx-2">Detalhes</span>
                </button>
              </div>

              <div className=" pb-2">
                <label className="block text-sm text-pink-500">
                  Razão Social
                </label>
                <p>{fornecedor.razao_social}</p>
              </div>
              <div className=" pb-2">
                <label className="text-sm text-pink-500">Nome Fantasia</label>
                <p>{fornecedor.nome_fantasia}</p>
              </div>
              <div className="pb-2">
                <label className="text-sm text-pink-500">Descrição</label>
                <p>{fornecedor.descricao}</p>
              </div>

              <div className="pb-2">
                <label className="text-sm text-pink-500">Site</label>
                <p>{fornecedor.site}</p>
              </div>
              <div className="pb-2">
                <label className="text-sm text-pink-500">Cidade</label>
                <p>{`${fornecedor.cidade}, ${fornecedor.uf}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
