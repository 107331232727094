export function addProd(data) {
  return {
    type: 'ADD_PROD',
    data,
  };
}
export function removeProd() {
  return {
    type: 'REMOVE_PROD',
  };
}
