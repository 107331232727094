export default function numberFormatterBr(value) {
  if (!Number(value)) return '';

  const amount = (value / 100).toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
  });

  return `${amount}`;
}
