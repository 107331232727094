import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import NavBar from "../../../components/NavBar";
import { MdAdd, MdSave, MdArrowBack } from "react-icons/md";

import api from "../../../services/api";

export default function GrupoForm({ history }) {
  const [id, setId] = useState();
  const [grupo, setGrupo] = useState("");
  const [ativo, setAtivo] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const {
        id,
        grupo,
        ativo,
      } = location.state.data;
      setId(id);
      setGrupo(grupo);
      setAtivo(ativo);
    }
  }, []);

  async function add() {
    await api
      .post("grupoproduto", {
        grupo,
        ativo,
      })
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => console.log(error));
  }
  async function up(id) {
    await api
      .put(`grupoproduto/${id}`, {
        grupo,
        ativo,
      })
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => console.log(error));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!!id) {
      up(id);
    } else {
      add();
    }
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20">
        <div className="flex justify-between max-w-xl mx-auto">
          <button onClick={() => history.goBack()} className="btn btn-pink">
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-pink-500">Cadastrar Grupo</h1>
          <div className="mx-8" />
        </div>
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto mt-5">
          <label className="block text-sm text-pink-500">Grupo</label>
          <input
            type="text"
            placeholder=""
            className="block input-text shadow"
            value={grupo}
            onChange={(e) => setGrupo(e.target.value)}
          />

          <div className="flex xl:max-w-3xl">

            <div className="w-1/2 mx-1 my-2">
              <label className="block text-sm text-pink-500">Ativo</label>

              <div className="relative w-full">
                <select
                  className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                  id="grid-state"
                  value={ativo}
                  onChange={(e) => setAtivo(e.target.value)}
                >
                  <option value="1">Ativo</option>
                  <option value="0">Inativo</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-pink flex items-center justify-center w-full">
            <MdSave /> <span>Salvar</span>
          </button>
        </form>
      </div>
    </>
  );
}
