export function addCaixa(data) {
  return {
    type: 'ADD_CAIXA',
    data,
  };
}
export function removeCaixa() {
  return {
    type: 'REMOVE_CAIXA',
  };
}
