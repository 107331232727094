/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable radix */
import React, { useState, useLayoutEffect, useEffect, memo } from 'react';
import { MdSave } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import Spinner from 'react-md-spinner';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

function EstoqueInicial({ produto = {}, estoque = {} }) {
  const history = useHistory();

  /** state off redux */
  const auth = useSelector((state) => state.auth);

  const [id, setId] = useState(null);
  const [produtoId, setProdutoId] = useState(null);
  const [quantidadeMin, setQuantidadeMin] = useState(null);
  const [quantidadeMax, setQuantidadeMax] = useState(null);
  const [localArmazenamento, setLocalArmazenamento] = useState('');
  const [observacao, setObservacao] = useState('');
  const [quantidade, setQuantidade] = useState(null);

  const [load, setLoad] = useState(false);

  useLayoutEffect(() => {
    setProdutoId((!!produto && produto[0].id) || null);
    setId((!!estoque && estoque.id) || (!!produto[2] && produto[2].id) || null);
    setQuantidadeMin((!!estoque && estoque.quantidade_min) || null);
    setQuantidadeMax((!!estoque && estoque.quantidade_max) || null);
    setLocalArmazenamento((!!estoque && estoque.local_armazenamento) || '');
    setObservacao((!!estoque && estoque.observacao) || '');
    setQuantidade((!!estoque && estoque.quantidade) || null);
  }, []);

  async function add(data) {
    // console.log(data);
    setLoad(true);
    await api
      .post('estoque', data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  // eslint-disable-next-line no-shadow
  async function up(id, data) {
    // console.log(data);
    setLoad(true);
    await api
      .put(`estoque/${id}`, data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      produto_id: parseInt(produtoId),
      loja_id: parseInt(auth.data.lojaId),
      colaborador_id: parseInt(auth.data.colaboradorId),
      quantidade_max: parseInt(quantidadeMax),
      quantidade_min: parseInt(quantidadeMin),
      observacao,
      local_armazenamento: localArmazenamento,
      quantidade: parseInt(quantidade),
      tipo_movimento: 'entrada',
      descricao: 'Estoque inicial',
      inicial: !!produto && !!produto.estoque,
    };

    // console.log(data);

    if (!id) {
      //   add(data);
    } else {
      up(id, data);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="px-2 py-4">
      <div className="mb-2">
        <label className="text-sm text-pink-500">Produto</label>
        <p>
          {(!!produto && !!id ? produto.produto : produto.produto) || 'produto'}
        </p>
      </div>
      <div className="mb-2">
        <label className="text-sm text-pink-500">Descricao</label>
        <p>
          {(!!produto && !!id ? produto.descricao : produto.descricao) ||
            'descrição'}
        </p>
      </div>

      <div className="w-full sm:w-1/3">
        <label className="text-sm text-pink-500">Estoque Atual</label>
        <NumberFormat
          allowNegative={false}
          displayType="number"
          placeholder=""
          className="input-text shadow"
          value={quantidade}
          onChange={(e) => setQuantidade(e.target.value)}
        />
      </div>

      <div className="flex flex-col sm:flex-row">
        <div className="mr-2 w-full sm:w-1/3">
          <label className="text-sm text-pink-500">Quantidade min</label>
          <NumberFormat
            allowNegative={false}
            displayType="number"
            required
            placeholder=""
            className="input-text shadow"
            value={quantidadeMin}
            onChange={(e) => setQuantidadeMin(e.target.value)}
          />
        </div>
        <div className="mr-2 w-full sm:w-1/3">
          <label className="text-sm text-pink-500">Quantidade max</label>
          <NumberFormat
            allowNegative={false}
            displayType="number"
            required
            placeholder=""
            className="input-text shadow"
            value={quantidadeMax}
            onChange={(e) => setQuantidadeMax(e.target.value)}
          />
        </div>
      </div>

      <div>
        <label className="text-sm text-pink-500">Local Armazenamento</label>
        <input
          type="text"
          placeholder=""
          className="input-text shadow"
          value={localArmazenamento}
          onChange={(e) => setLocalArmazenamento(e.target.value)}
        />
      </div>

      <div>
        <label className="text-sm text-pink-500">Observação</label>
        <textarea
          rows="4"
          cols="50"
          placeholder=""
          className="block resize-none input-text shadow mb-3 h-24 min-h-20"
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
        />
      </div>

      <button
        disabled={load}
        type="submit"
        className={`btn btn-pink flex items-center justify-center w-full ${
          load && 'disabled'
        }`}
      >
        {(load && (
          <div className="flex justify-center items-center">
            <Spinner
              className="mx-1"
              size={24}
              singleColor="#fbb6ce"
              borderSize={4}
            />
            aguarde...
          </div>
        )) || (
          <>
            <MdSave />
            <span>Salvar</span>
          </>
        )}
      </button>
    </form>
  );
}

export default memo(EstoqueInicial);
