import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import NavBar from '../../components/NavBar';

export default function Clientes() {
  const history = useHistory();
  const [clientes, setClientes] = useState();
  const auth = useSelector((state) => state.auth);

  async function loadClientes() {
    await api
      .get(`/cliente/loja/${auth.data.lojaId}`)
      .then((response) => {
        setClientes(response.data);
        console.log(response.data);
      })
      .catch((error) => console.log(error.response.data));
  }

  useEffect(() => {
    loadClientes();
  }, []);

  async function handleRemover(id) {
    await api
      .delete(`/cliente/${id}`)
      .then((response) => {
        console.log(response.data);
        setClientes(clientes.filter((cliente) => cliente.id !== id));
      })
      .catch((error) => console.log(error.response.data));
  }

  return (
    <div>
      <NavBar />

      <div className="container mx-auto p-2 pt-20">
        <button
          type="button"
          onClick={() => history.push('/clientes/cliente')}
          className="btn btn-pink my-2 flex items-center"
        >
          <MdAdd />
          Novo
        </button>

        <MaterialTable
          title="Clientes"
          columns={[
            {
              title: 'Id',
              field: 'id',
              // hidden: true,
            },
            {
              title: 'Loja Id',
              field: 'loja_id',
              hidden: true,
            },
            {
              title: 'Nome Responsavel',
              field: 'nome_responsavel',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Razão Social',
              field: 'razao_social',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Nome Fantasia',
              field: 'nome_fantasia',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Pessoa',
              field: 'pessoa',
            },
            {
              title: 'CNPJ',
              field: 'cnpj',
            },
            {
              title: 'CPF',
              field: 'cpf',
              render: (rowData) =>
                rowData.cpf.toString().length > 1 && rowData.cpf.toString() < 11
                  ? `0${rowData.cpf}`
                  : rowData.cpf,
            },
            {
              title: 'Incrição Estadual',
              field: 'inscricao_estadual',
            },

            {
              title: 'Cidade',
              field: 'cidade',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },

            {
              title: 'Uf',
              field: 'uf',
            },

            {
              title: 'Contato',
              field: 'contato1',
            },

            {
              title: 'Email',
              field: 'email',
            },
          ]}
          data={clientes}
          actions={[
            () => ({
              icon: 'visibility',
              tooltip: 'Visualidar Cliente',
              onClick: (event, rowData) =>
                history.push('/clientes/detalhes', { id: rowData.id }),
            }),
            () => ({
              icon: 'shopping_cart',
              tooltip: 'Compras do Cliente',
              onClick: (event, rowData) =>
                history.push('/clientes/compras', {
                  cliente_id: rowData.id,
                  pessoa: rowData.pessoa,
                  nome_responsavel: rowData.nome_responsavel,
                  razao_social: rowData.razao_social,
                }),
            }),
            /** 
            (rowData) => ({
              icon: "delete",
              tooltip: "Remover Cliente",
              onClick: (event, rowData) => {
                handleRemover(rowData.id);
              },

              disabled: false,
            }), */
            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => loadClientes(),
            },
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: true,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </div>
    </div>
  );
}
