export function addAuth(data) {
  return {
    type: 'ADD_AUTH',
    data,
  };
}
export function removeAuth() {
  return {
    type: 'REMOVE_AUTH',
  };
}
