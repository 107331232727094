import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import NavBar from '../../components/NavBar';

export default function Fornecedores() {
  const history = useHistory();
  const [fornecedores, setFornecedores] = useState();

  async function loadFornecedores() {
    await api
      .get('/fornecedor')
      .then((response) => {
        setFornecedores(response.data);
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    loadFornecedores();
  }, []);

  /** 
  async function handleRemover(id) {
    await api
      .delete(`/fornecedor/${id}`)
      .then((response) => {
        console.log(response.data);
        setFornecedores(
          fornecedores.filter((fornecedor) => fornecedor.id !== id)
        );
      })
      .catch((error) => console.log(error));
  }
  */
 
  return (
    <div>
      <NavBar />

      <div className="container mx-auto p-2 pt-20">
        <button
          type="button"
          onClick={() => history.push('/fornecedores/fornecedor')}
          className="btn btn-pink my-2 flex items-center"
        >
          <MdAdd />
          Novo
        </button>

        <MaterialTable
          title="Fornecedores"
          columns={[
            {
              title: 'Id',
              field: 'id',
              // hidden: true,
            },
            {
              title: 'Loja Id',
              field: 'loja_id',
              hidden: true,
            },
            {
              title: 'Nome Responsavel',
              field: 'nome_responsavel',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Razão Social',
              field: 'razao_social',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Nome Fantasia',
              field: 'nome_fantasia',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Pessoa',
              field: 'pessoa',
            },
            {
              title: 'CNPJ',
              field: 'cnpj',
            },
            {
              title: 'CPF',
              field: 'cpf',
              render: (rowData) =>
                rowData.cpf.toString().length > 1 && rowData.cpf.toString() < 11
                  ? `0${rowData.cpf}`
                  : rowData.cpf,
            },
            {
              title: 'Incrição Estadual',
              field: 'inscricao_estadual',
            },

            {
              title: 'Cidade',
              field: 'cidade',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },

            {
              title: 'Uf',
              field: 'uf',
            },
            {
              title: 'Site',
              field: 'site',
            },

            {
              title: 'Contato',
              field: 'contato1',
            },

            {
              title: 'Email',
              field: 'email',
            },
            {
              title: 'Ativo',
              field: 'ativo',
              lookup: { 0: 'Inativo', 1: 'Ativo' },
            },
          ]}
          data={fornecedores}
          actions={[
            () => ({
              icon: 'visibility',
              tooltip: 'Visualidar',
              onClick: (event, rowData) =>
                history.push('/fornecedores/detalhes', { id: rowData.id }),
            }),
            /**
            (rowData) => ({
              icon: "delete",
              tooltip: "Remover",
              onClick: (event, rowData) => {
                handleRemover(rowData.id);
              },

              disabled: false,
            }), */
            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => loadFornecedores(),
            },
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: true,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </div>
    </div>
  );
}
