/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useLayoutEffect, memo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { FaTelegramPlane } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';

import api from '../../services/api';
import NavBar from '../../components/NavBar';

function Detalhes() {
  const history = useHistory();
  const [id, setId] = useState();
  const [fornecedor, setFornecedor] = useState([]);

  const location = useLocation();
  useLayoutEffect(() => {
    if (location.state) {
      // console.log(location.state.data);
      setId(location.state.id);
    }
  }, []);

  useEffect(() => {
    (async function loadFornecedor() {
      if (id) {
        await api
          .get(`/fornecedor/${id}`)
          .then((response) => {
            setFornecedor(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })();
  }, [id]);

  function editar(edit) {
    let data = {};
    switch (edit) {
      case 'fornecedor':
        // console.log(fornecedor.cpf.toString().length);
        data = {
          id: fornecedor.id,
          nomeResponsavel: fornecedor.nome_responsavel,
          descricao: fornecedor.descricao,
          razaoSocial: fornecedor.razao_social,
          nomeFantasia: fornecedor.nome_fantasia,
          pessoa: fornecedor.pessoa,
          cnpj: fornecedor.cnpj,
          cpf: fornecedor.cpf,
          inscricaoEstadual: fornecedor.inscricao_estadual,
          observacao: fornecedor.observacao,
          ativo: fornecedor.ativo,
        };
        break;
      case 'endereco':
        data = {
          id: fornecedor.id,
          cep: fornecedor.cep,
          tipoEndereco: fornecedor.tipo_endereco,
          cidade: fornecedor.cidade,
          bairro: fornecedor.bairro,
          logradouro: fornecedor.logradouro,
          uf: fornecedor.uf,
          complemento: fornecedor.complemento,
        };
        break;
      case 'contato':
        data = {
          id: fornecedor.id,
          site: fornecedor.site,
          email: fornecedor.email,
          contato1: fornecedor.contato1,
          contato1Whatsapp: fornecedor.contato1_whatsapp,
          contato1Telegram: fornecedor.contato1_telegram,
          contato2: fornecedor.contato2,
          contato2Whatsapp: fornecedor.contato2_whatsapp,
          contato2Telegram: fornecedor.contato2_telegram,
        };
        break;

      default:
        break;
    }
    history.push('/fornecedores/fornecedor', { edit, data });
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20 ">
        <div className="flex justify-between items-center mb-10 max-w-4xl mx-auto">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="flex btn btn-pink items-center"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-center text-pink-500 font-semibold -ml-16">
            Dados Fornecedor
          </h1>
          <div />
        </div>
        <div className="max-w-4xl mx-auto p-3 bg-white shadow rounded-sm">
          <div className="py-3">
            <div className="px-2">
              <div className="flex justify-between pb-2">
                <div>
                  <label className="block text-sm text-pink-500">
                    Nome Responsavel
                  </label>
                  <p>{fornecedor.nome_responsavel}</p>
                </div>
                <button
                  type="button"
                  onClick={() => editar('fornecedor')}
                  className="btn btn-pink py-1"
                >
                  Editar
                </button>
              </div>
              <div className="pb-2">
                <label className="block text-sm text-pink-500">
                  Nome Fantasia
                </label>
                <p>{fornecedor.nome_fantasia}</p>
              </div>
              <div className="pb-2">
                <label className="block text-sm text-pink-500">
                  Razão Social
                </label>
                <p>{fornecedor.razao_social}</p>
              </div>
              <div className="flex flex-col sm:flex-row items-center pb-2">
                <div className="w-full sm:w-1/5 pb-2">
                  <label className="block text-sm text-pink-500">Pessoa</label>
                  <p>{fornecedor.pessoa}</p>
                </div>
                <div className="flex w-full">
                  <div className="w-1/2 sm:w-1/3">
                    <label className="block text-sm text-pink-500">
                      CPF/CNPJ
                    </label>
                    <p>
                      {(!!fornecedor.cpf &&
                        fornecedor.cpf.toString().length < 11 &&
                        `0${fornecedor.cpf}`) ||
                        fornecedor.cpf ||
                        fornecedor.cnpj}
                    </p>
                  </div>
                  <div className="w-1/2 sm:w-1/4">
                    <label className="block text-sm text-pink-500">
                      Inscrição Estadual
                    </label>
                    <p>{fornecedor.inscricao_estadual}</p>
                  </div>
                </div>
              </div>

              <div className="pb-2">
                <label className="block text-sm text-pink-500">Decrição</label>
                <p>{fornecedor.descricao}</p>
              </div>

              <div className="pb-2">
                <label className="block text-sm text-pink-500">Ativo</label>
                <p>{fornecedor.ativo === 1 ? 'Ativo' : 'Inativo'}</p>
              </div>

              <div className="pb-2">
                <label className="block text-sm text-pink-500">
                  Observacao
                </label>
                <p>{fornecedor.observacao}</p>
              </div>
            </div>
          </div>

          <div className="py-3">
            <h1 className="text-xl text-pink-500 py-2 border border-pink-500 border-l-0 border-r-0 border-t-0">
              Endereço
            </h1>
            <div className="py-6 px-2">
              <div className="flex justify-between pb-2">
                <div>
                  <label className="text-sm text-pink-500">CEP</label>
                  <p>{fornecedor.cep}</p>
                </div>
                <button
                  type="button"
                  onClick={() => editar('endereco')}
                  className="btn btn-pink py-1"
                >
                  Editar
                </button>
              </div>
              <div className="flex pb-2">
                <div className="w-1/2 ">
                  <label className="text-sm text-pink-500">Logradoudo</label>
                  <p>{fornecedor.logradouro}</p>
                </div>
                <div className="w-1/2">
                  <label className="text-sm text-pink-500">Bairro</label>
                  <p>{fornecedor.bairro}</p>
                </div>
              </div>
              <div className="flex pb-2">
                <div className="w-1/2 ">
                  <label className="text-sm text-pink-500">Cidade</label>
                  <p>{fornecedor.cidade}</p>
                </div>
                <div className="w-1/2">
                  <label className="text-sm text-pink-500">UF</label>
                  <p>{fornecedor.uf}</p>
                </div>
              </div>
              <div>
                <label className="text-sm text-pink-500">complemento</label>
                <p>{fornecedor.complemento}</p>
              </div>
            </div>
          </div>

          <div className="py-3">
            <h1 className="text-xl text-pink-500 py-2 border border-pink-500 border-l-0 border-r-0 border-t-0">
              Contatos
            </h1>
            <div className="py-6 px-2">
              <div className="flex justify-between pb-2">
                <div>
                  <label className="text-sm text-pink-500">Site</label>
                  <p>{fornecedor.site}</p>
                </div>

                <button
                  type="button"
                  onClick={() => editar('contato')}
                  className="btn btn-pink py-1"
                >
                  Editar
                </button>
              </div>

              <div className="mb-2">
                <label className="text-sm text-pink-500">Email</label>
                <p>{fornecedor.email}</p>
              </div>

              <div className="pb-2">
                <label className="text-sm text-pink-500">Contato 1</label>
                <p className="flex">
                  {fornecedor.contato1}
                  <span className="pl-2">
                    {(fornecedor.contato1_whatsapp && (
                      <IoLogoWhatsapp title="Whatsapp" />
                    )) ||
                      ''}
                  </span>
                  <span className="pl-2">
                    {(fornecedor.contato1_telegram && (
                      <FaTelegramPlane title="Telegram" />
                    )) ||
                      ''}
                  </span>
                </p>
              </div>
              <div className="pb-2">
                <label className="text-sm text-pink-500">Contato 2</label>
                <p className="flex">
                  {fornecedor.contato2}
                  <span className="pl-2">
                    {(fornecedor.contato2_whatsapp && (
                      <IoLogoWhatsapp title="Whatsapp" />
                    )) ||
                      ''}
                  </span>
                  <span className="pl-2">
                    {(fornecedor.contato2_telegram && (
                      <FaTelegramPlane title="Telegram" />
                    )) ||
                      ''}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(Detalhes);
