import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import NavBar from '../../components/NavBar';

export default function Cargos() {
  const history = useHistory();
  const [cargos, setCargos] = useState([]);

  useEffect(() => {
    (async function loadCargos() {
      await api
        .get('cargo')
        .then((response) => setCargos(response.data))
        .catch((error) => console.log(error.response.data))
        .finally(() => console.log('finally'));
    })();
  }, []);

  async function handleRemove(id) {
    await api
      .delete(`cargo/${id}`)
      .then(() => {
        setCargos(cargos.filter((cargo) => cargo.id !== id));
      })
      .catch((error) => console.log(error));
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20 max-w-4xl">
        <button
          type="button"
          onClick={() => history.push('/cargos/cargo')}
          className="btn btn-pink flex items-center"
        >
          <MdAdd />
          Novo
        </button>

        <MaterialTable
          title="Cargos"
          columns={[
            {
              title: 'Id',
              field: 'id',
              hidden: true,
            },

            {
              title: 'Cargo',
              field: 'cargo',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Descrição',
              field: 'descricao',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Função',
              field: 'funcao',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },

            {
              title: 'Nível Acesso',
              field: 'nivel_acesso',
              lookup: { 2: 'Admin', 1: 'Vendedor' },
            },
            {
              title: 'Ativo',
              field: 'ativo',
              lookup: { 1: 'Ativo', 0: 'Inativo' },
            },
          ]}
          data={cargos}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Editar Usuário',
              onClick: (event, rowData) =>
                history.push('/cargos/cargo', { data: rowData }),
            },
            /** 
            (rowData) => ({
              icon: "delete",
              tooltip: "Remover Usuário",
              onClick: (event, rowData) => {
                handleRemove(rowData.id);
              },
              disabled: rowData.birthYear < 2000,
            }), */
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: true,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </div>
    </>
  );
}
