/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { MdArrowBack, MdAdd, MdSearch, MdClose } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Spinner from 'react-md-spinner';
import { useHistory } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { addCaixa } from '../../store/actions/caixaAberto';

import currencyFormatter from '../../components/currencyFormatterBrl';

import NavBar from '../../components/NavBar';
import CardModal from '../../components/CardModal';

import api from '../../services/api';
import Toast from '../../components/Toast';

export default function Caixas() {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const caixaAberto = useSelector((state) => state.caixaAberto);

  const [caixas, setCaixas] = useState([]);
  const [formaPagamento, setFormasPagamento] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Abertura de caixa
  const [caixaId, setCaixaId] = useState(null);
  const [lojaId, setLojaId] = useState(null);
  const [colaboradorId, setColaboradorId] = useState(null);
  const [valor, setValor] = useState(0);
  const [formaPagamentoId, setFormasPagamentoId] = useState(null);

  const [loadAbertura, setLoadAbertura] = useState(false);
  const [caixaOk, setCaixaOk] = useState('');
  const [caixaError, setCaixaError] = useState('');

  // perido busca caixa
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [buscaAtiva, setBuscaAtiva] = useState(false);

  useEffect(() => {
    (async function loadFormaPagamento() {
      await api
        .get('formapagamento')
        .then((response) => {
          setFormasPagamento(response.data);
          if (response.data) {
            setFormasPagamentoId(response.data[0].id);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => console.log('loadFormaPagamento finalizado'));
    })();
  }, []);

  async function loadCaixas() {
    await api
      .get(`caixa/loja/${auth.data.lojaId}`)
      .then((response) => {
        if (auth.data.nivelAcesso === 1) {
          const caixaAux = response.data.filter(
            (rest) => Number(rest.id) === Number(caixaAberto.caixaId)
          );
          // console.log(caixaAux);
          setCaixas(caixaAux);
        } else {
          setCaixas(response.data);
          // console.log(response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    loadCaixas();
  }, []);

  async function loadPeriodoCaixas() {
    await api
      .get(`caixa/loja/${auth.data.lojaId}/${dataInicial}/${dataFinal}`)
      .then((response) => {
        setCaixas(response.data);
        // console.log(response.data);
        setBuscaAtiva(true);
      })
      .catch((error) => console.log(error));
  }

  async function handlerBuscar(e) {
    e.preventDefault();
    // console.log(e);

    if (!buscaAtiva) {
      loadPeriodoCaixas();
    } else {
      setBuscaAtiva(false);
      setDataInicial('');
      setDataFinal('');
      loadCaixas();
    }
  }

  async function handleSubmitAbrirCaixa(e) {
    e.preventDefault();
    setLoadAbertura(true);
    const data = {
      loja_id: auth.data.lojaId,
      colaborador_id: auth.data.colaboradorId,
      valor:
        valor !== 0 &&
        parseFloat(valor.replace(/[^\d\,]/g, '').replace(',', '.')),
      forma_pagamento_id: formaPagamentoId,
      tipo_movimento: 'entrada',
      descricao: 'abertura caixa',
    };
    // console.log(data);
    await api
      .post('/caixa', data)
      .then(
        (response) => {
          // console.log(response.data);

          const status = true;
          const caixaId = response.data[0].id;
          dispatch(addCaixa({ caixaId, status }));

          setCaixaOk('Caixa Aberto!');
          setTimeout(() => {
            history.push('/caixas/caixa');
          }, 1000);
        },
        (error) => {
          console.log(error.response.data);
        }
      )
      .catch((error) => {
        // console.log(error);
        setCaixaError('Erro ao Abrir Caixa!');
      })
      .finally(() => {
        setLoadAbertura(false);
      });
  }

  return (
    <>
      <NavBar />

      <div className="container mx-auto p-2 pt-16 max-w-5xl">
        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="btn btn-pink"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-center text-pink-500 text-2xl">Caixas</h1>
          <div className="px-6" />
        </div>

        <div className="flex flex-col md:flex-row justify-between">
          <div>
            <button
              type="button"
              disabled={!!caixaAberto.status}
              onClick={() => setIsDialogOpen(!isDialogOpen)}
              className={`btn border border-green-500 my-2 flex items-center text-green-500 bg-white focus:bg-green-500 focus:text-white ${
                !!caixaAberto.status &&
                'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
              }`}
            >
              <MdAdd />
              Abrir Caixa
            </button>
          </div>

          {/** formuladio de busca por périodo de data */}
          {auth.data.nivelAcesso === 2 && (
            <form onSubmit={handlerBuscar} className="flex">
              <input
                type="date"
                required
                placeholder="Data Inicial"
                className="input-text shadow mr-2"
                value={dataInicial}
                onChange={(e) => setDataInicial(e.target.value)}
              />
              <input
                type="date"
                required
                placeholder="Data Final"
                className="input-text shadow mr-2"
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
              />
              <button type="submit" className="btn btn-pink mt-0">
                {(buscaAtiva && <MdClose />) || <MdSearch />}
              </button>
            </form>
          )}
          {/** */}
        </div>

        {/** sessão de modal */}

        <MaterialTable
          title=""
          columns={[
            {
              title: 'Id',
              field: 'id',
              // hidden: true,
            },
            {
              title: 'Loja Id',
              field: 'loja_id',
              hidden: true,
            },
            {
              title: 'Abertura',
              field: 'abertura',
              type: 'datetime',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Status',
              field: 'status',
              lookup: { 0: 'Fechado', 1: 'Aberto' },
            },

            {
              title: 'Total Entrada',
              field: 'caixa_total_entrada',
              // type: "numeric",
              type: 'currency',
              currencySetting: { locale: 'br', currencyCode: 'brl' },
            },
            {
              title: 'Total Saida',
              field: 'caixa_total_saida',
              // type: "numeric",
              type: 'currency',
              currencySetting: { locale: 'br', currencyCode: 'brl' },
            },
            {
              title: 'Valor Caixa',
              field: 'caixa_total',
              // type: "numeric",
              type: 'currency',
              currencySetting: { locale: 'br', currencyCode: 'brl' },
            },

            {
              title: 'Fechamento',
              field: 'fechamento',
              type: 'datetime',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
          ]}
          data={caixas}
          actions={[
            (rowData) => ({
              icon: 'account_balance_wallet',
              tooltip: 'Abrir Caixa',
              onClick: () => history.push('/caixas/caixa', { id: rowData.id }),
            }),
            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => loadCaixas(),
            },
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: true,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </div>

      {/** modal de abertura de caixa */}
      <CardModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={() => setIsDialogOpen(!isDialogOpen)}
        titulo="Abrir Caixa"
        style="max-w-lg mt-20"
      >
        <form onSubmit={handleSubmitAbrirCaixa} className="m-3">
          <p className="text-center text-red-600 text-base p-2">{caixaError}</p>
          {/* <p className="text-center text-green-500 text-base p-2">{caixaOk}</p> */}

          <Toast text={caixaOk} active={!!caixaOk} />

          <div className="">
            <label className="text-xs text-gray-700">Valor Inicial Caixa</label>
            <NumberFormat
              disabled={loadAbertura}
              allowNegative={false}
              displayType="number"
              format={currencyFormatter}
              placeholder=""
              value={valor}
              onChange={(e) => setValor(e.target.value)}
              className="input-text shadow text-2xl border"
            />
          </div>
          <div>
            <label className="text-xs text-gray-700">Forma Pagamento</label>
            <div className="relative w-full">
              <select
                disabled={loadAbertura}
                className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                id="grid-state"
                value={formaPagamentoId}
                onChange={(e) => setFormasPagamentoId(e.target.value)}
              >
                {formaPagamento.map((forma) => (
                  <option key={forma.id} value={forma.id}>
                    {forma.forma_pagamento}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-8">
            <button
              onClick={() => setIsDialogOpen(!isDialogOpen)}
              type="button"
              className=" mx-2 btn btn-pink-outline"
            >
              Cancelar
            </button>

            <button
              disabled={loadAbertura}
              type="submit"
              className={`btn btn-pink flex items-center justify-center ${
                loadAbertura && 'disabled'
              }`}
            >
              {(loadAbertura && (
                <div className="flex justify-center items-center">
                  <Spinner
                    className="mx-1"
                    size={24}
                    singleColor="#fbb6ce"
                    borderSize={4}
                  />
                  aguarde...
                </div>
              )) || (
                <>
                  <span>Abrir Caixa</span>
                </>
              )}
            </button>
          </div>
        </form>
      </CardModal>
    </>
  );
}
