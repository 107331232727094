/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { MdAdd, MdRemove, MdSave, MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';
import Spinner from 'react-md-spinner';

import NavBar from '../../../components/NavBar';
import api from '../../../services/api';

export default function EstoqueLancamento() {
  const history = useHistory();

  const [codigoBarras, setCodigoBarras] = useState(null);
  const [estoqueId, setEstoqueId] = useState(null);
  const [produto, setProduto] = useState('');
  const [tipoMovimento, setTipoMovimento] = useState('entrada');
  const [quantidade, setQuantidade] = useState(0);
  const [descricao, setDescricao] = useState('');
  const [load, setLoad] = useState(false);

  const auth = useSelector((state) => state.auth);

  const location = useLocation();

  useLayoutEffect(() => {
    if (location.state) {
      setCodigoBarras(location.state.codigo_barras);
      setEstoqueId(location.state.estoque_id);
      setProduto(location.state.produto);
    }
  }, []);

  async function add(data) {
    setLoad(true);
    await api
      .post('itemestoque', data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      estoque_id: estoqueId,
      colaborador_id: auth.data.colaboradorId,
      tipo_movimento: tipoMovimento,
      quantidade,
      descricao,
    };
    add(data);
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20">
        <div className="flex justify-between max-w-xl mx-auto">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="btn btn-pink"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-pink-500">Lançamento Estoque</h1>
          <div className="mx-8" />
        </div>
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto mt-5">
          {/** */}
          <div className="flex ">
            <div className="mb-2 w-1/3">
              <label className="text-sm text-pink-500">Código</label>
              <p>{codigoBarras}</p>
            </div>
            <div className="mb-2 w-2/3">
              <label className="text-sm text-pink-500">Produto</label>
              <p>{produto}</p>
            </div>
          </div>

          <div className="flex xl:max-w-3xl">
            <div className="w-1/2 mx-1 my-2">
              <label className="block text-sm text-pink-500">
                Tipo Movimento
              </label>

              <div className="relative w-full">
                <select
                  className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                  id="grid-state"
                  value={tipoMovimento}
                  onChange={(e) => setTipoMovimento(e.target.value)}
                >
                  <option value="entrada">Entrada</option>
                  <option value="saida">Saida</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div>
              <label className="block text-sm text-pink-500">Quantidade</label>
              <NumberFormat
                allowNegative={false}
                displayType="number"
                placeholder=""
                className="block input-text shadow"
                value={quantidade}
                onChange={(e) => setQuantidade(e.target.value)}
              />
            </div>
            <div className="pt-5 ml-1">
              <button
                type="button"
                disabled={quantidade === 0}
                onClick={() => setQuantidade(Number(quantidade) - 1)}
                className="btn btn-pink rounded-full p-3 mt-0 mx-1"
              >
                <MdRemove />
              </button>
              <button
                type="button"
                onClick={() => setQuantidade(Number(quantidade) + 1)}
                className="btn btn-pink rounded-full p-3 mt-0 mx-1"
              >
                <MdAdd />
              </button>
            </div>
          </div>
          <div>
            <label className="text-sm text-pink-500">Descrição</label>
            <textarea
              rows="4"
              cols="50"
              placeholder=""
              className="block resize-none input-text shadow mb-3 h-24 min-h-20"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </div>
          <button
            type="submit"
            disabled={load}
            className={`btn btn-pink flex items-center justify-center w-full ${
              load && 'disabled'
            }`}
          >
            {(load && (
              <div className="flex justify-center items-center">
                <Spinner
                  className="mx-1"
                  size={24}
                  singleColor="#fbb6ce"
                  borderSize={4}
                />
                aguarde...
              </div>
            )) || (
              <>
                <MdSave />
                <span> Lançar</span>
              </>
            )}
          </button>
        </form>
      </div>
    </>
  );
}
