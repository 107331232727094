/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, memo } from 'react';
import { MdAdd, MdClose, MdLink } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

function CardItemDetalhes({ data = {} }) {
  const history = useHistory();

  const [openItem, setOpenItem] = useState(false);
  const [totalPedido, setTotalPedido] = useState(0);

  useEffect(() => {
    setTotalPedido(
      parseFloat(data.caixa_item.valor) -
        parseFloat(data.caixa_item.valor_desconto) -
        parseFloat(data.caixa_item.valor_desconto_promocao)
    );
  }, []);

  return (
    <li className="m-1">
      <div className=" py-2 px-3 bg-white rounded shadow flex justify-between items-center">
        <div className="flex">
          <button
            type="button"
            onClick={() => setOpenItem(!openItem)}
            className="p-2 rounded-full hover:bg-gray-100 focus:bg-gray-100"
          >
            {openItem ? <MdClose /> : <MdAdd />}
          </button>
          <button
            type="button"
            onClick={() => {
              history.push('/caixas/caixa/lancamento', {
                id: data.caixa_item.id,
                caixa_id: data.caixa_item.caixa_id,
                comanda_id: data.id,
                comanda_status: data.status,
                item_apelido: data.caixa_item.item_apelido,
              });
            }}
            className="p-2 rounded-full hover:bg-gray-100 focus:bg-gray-100"
          >
            <MdLink />
          </button>
        </div>
        <div className="w-full px-2 flex justify-between items-center divide-x divide-gray-400">
          <span className="w-full px-2 whitespace-no-wrap">
            {data.abertura}
          </span>
          <span className="w-full px-2">
            {totalPedido.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
          <span className="w-full px-2">
            {parseFloat(data.caixa_item.valor_desconto_promocao).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              }
            )}
          </span>
          <span className="w-full px-2">
            {parseFloat(data.caixa_item.valor_desconto).toLocaleString(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              }
            )}
          </span>
        </div>
      </div>
      {openItem &&
        data.item_comanda.map((item, index) => (
          <ul
            key={index}
            className="ml-4 py-2 px-3 bg-white rounded shadow mt-1 divide-y divide-gray-400"
          >
            <li className="p-2 flex items-center justify-between divide-x divide-gray-400">
              <span className="w-16 px-2">{item.estoques.produto.id}</span>
              <span className="w-full px-2">
                {item.estoques.produto.produto}
              </span>
            </li>
          </ul>
        ))}
    </li>
  );
}

export default memo(CardItemDetalhes);
