/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { MdArrowBack, MdClose, MdAdd } from 'react-icons/md';
import MaterialTable from 'material-table';
import Spinner from 'react-md-spinner';
import { useLocation, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { useSelector, useDispatch } from 'react-redux';
import api from '../../../services/api';
import NavBar from '../../../components/NavBar';
import CardModal from '../../../components/CardModal';
import currencyFormatter from '../../../components/currencyFormatterBrl';

// redux
import { removeCaixa } from '../../../store/actions/caixaAberto';

function Caixa() {
  const history = useHistory();

  const dispatch = useDispatch();
  const caixaAberto = useSelector((state) => state.caixaAberto);

  const [caixa, setCaixa] = useState({});
  const [caixaItem, setCaixaItem] = useState([]);
  const [caixaInicial, setCaixaInicial] = useState(0);

  /** formulario no cardModal */
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [valorRetirada, setValorRetirada] = useState(0);
  const [formaPagamentoId, setFormaPagamentoId] = useState(null);
  const [descricao, setDescricao] = useState('');

  const [formasPagamento, setFormasPagamento] = useState([]);
  const [loadRetirada, setLoadRetirada] = useState(false);
  const [loadFechar, setLoadfechar] = useState(false);

  const location = useLocation();

  /** busca forma de pagamentos */
  useEffect(() => {
    (async function loadFormaPagamento() {
      await api
        .get('formapagamento')
        .then((response) => {
          setFormasPagamento(response.data);
          if (response.data) {
            setFormaPagamentoId(response.data[0].id);
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          console.log('loadFormaPagamento finalizado');
        });
    })();
  }, []);

  /** busca ciaxas dados do caixa aberto */
  async function loadCaixa() {
    await api
      .get(`caixa/${location.state ? location.state.id : caixaAberto.caixaId}`)
      .then((response) => {
        const countItem = response.data.caixa_item.length;
        // console.log(countItem);
        setCaixa(response.data);
        setCaixaItem(response.data.caixa_item);
        setCaixaInicial(response.data.caixa_item[countItem - 1].valor_final);

        // console.log('caixa...');
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        console.log('finally');
      });
  }

  useEffect(() => {
    loadCaixa();
  }, []);

  /** funções de fechamento de caixa */
  async function handleFecharCaixa() {
    setLoadfechar(true);
    await api
      .post(`fecharcaixa/${caixaAberto.caixaId}`)
      .then((response) => {
        // console.log(response.data);

        dispatch(removeCaixa());
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoadfechar(false);
      });
  }

  async function buttonFecharCaixa() {
    const result = caixaItem.filter(
      (item) => item.comanda !== null && Number(item.comanda.status) !== 0
    );

    if (result.length > 0) {
      alert('Por favor feche todas as comandas!');
    } else {
      // alert("fechando caixa");
      setIsCloseDialogOpen(!isCloseDialogOpen);
    }
    //
  }
  /** */

  /** functions de retirada de valor do caixa */
  async function handleSubmitRetiradaCaixa(e) {
    e.preventDefault();

    setLoadRetirada(true);

    const data = {
      caixa_id: caixaAberto.caixaId,
      comanda_id: null,
      forma_pagamento_id: formaPagamentoId,
      valor: parseFloat(
        valorRetirada.replace(/[^\d\,]/g, '').replace(',', '.')
      ),
      tipo_movimento: 'saida',
      descricao,
    };

    await api
      .post('caixaitens', data)
      .then((response) => {
        loadCaixa();
        // console.log(response.data);
        setIsDialogOpen(!isDialogOpen);
      })
      .catch((error) => {
        if (!error.response) {
          console.log('erro com a conexão!');
        } else {
          console.log(error.response.data);
        }
      })
      .finally(() => {
        console.log('finally');
        setLoadRetirada(false);
      });
  }
  /** */

  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-20 px-2 pb-2 max-w-5xl">
        <div className="flex justify-between items-center content-center">
          <div className="fixed top-0 right-0 z-20 mr-12 mt-2">
            <div
              className={`border-2 ${
                Number(caixa.status) === 1
                  ? 'border-green-600 px-3'
                  : 'border-red-400 p-2'
              } px-3 mt-px py-1 bg-white rounded mx-2 sm:hidden block`}
            >
              <p>
                {Number(caixa.status) ? 'Aberto' : 'Caixa Fechado'}
                <span className="mx-1">
                  {Number(caixa.status)
                    ? new Date(caixa.abertura).toLocaleDateString()
                    : ''}
                </span>
              </p>
            </div>
          </div>

          <div className="flex">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="btn btn-pink py-2  m-0"
            >
              <MdArrowBack />
            </button>

            <div
              className={`border-2 ${
                Number(caixa.status) === 1
                  ? 'border-green-600 px-3'
                  : 'border-red-400 p-2'
              } py-1 bg-white rounded mx-2 hidden sm:block`}
            >
              <p>
                {Number(caixa.status) ? 'Aberto' : 'Caixa Fechado'}
                <span className="mx-1">
                  {Number(caixa.status)
                    ? new Date(caixa.abertura).toLocaleDateString()
                    : ''}
                </span>
              </p>
            </div>
          </div>

          <h1 className="text-2xl text-pink-500">Caixas</h1>
          <div className="sm:pl-32">
            <button
              type="button"
              onClick={buttonFecharCaixa}
              disabled={!Number(caixa.status)}
              className={`btn btn-red mt-0 py-2 flex items-center ${
                !Number(caixa.status) &&
                'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
              }`}
              title="Fechar Caixa"
            >
              <MdClose className="text-lg" />
              <span className="hidden sm:block">Fechar</span>
            </button>
          </div>
        </div>
        <div className="flex justify-center md:justify-between pt-6 my-2">
          <div className="md:flex flex-col justify-between hidden md:visible">
            <div />
            <button
              type="button"
              onClick={() => history.push('/caixas/caixa/lancamento')}
              disabled={!Number(caixa.status)}
              className={`btn btn-pink flex items-center 
              ${
                !Number(caixa.status) &&
                'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
              }`}
            >
              <MdAdd />
              Novo lançamento
            </button>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="m-1 w-64">
              <div className="h-16 bg-white rounded shadow mb-1 pt-1 pb-2 px-3">
                <label htmlFor="caixa_entrada" className="text-xs">
                  Entrada
                </label>
                <p
                  id="caixa_entrada"
                  className="text-2xl text-blue-500 text-center leading-4"
                >
                  {parseFloat(
                    caixa.caixa_total_entrada ? caixa.caixa_total_entrada : 0
                  ).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </p>
              </div>
              <div className="h-16 bg-white rounded shadow pt-1 pb-2 px-3">
                <div className="flex justify-between">
                  <label htmlFor="caixa_saida" className="text-xs">
                    Saida
                  </label>
                  <button
                    onClick={() => setIsDialogOpen(true)}
                    type="button"
                    disabled={Number(caixa.status) === 0}
                    className={`p-1 text-sm text-red-500 facus:outline-none hover:opacity-75 
                    ${
                      Number(caixa.status) === 0 &&
                      'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
                    }`}
                  >
                    Retirar
                  </button>
                </div>
                <p
                  id="caixa_saida"
                  className="text-2xl text-red-400 text-center leading-4"
                >
                  {parseFloat(
                    caixa.caixa_total_saida ? caixa.caixa_total_saida : 0
                  ).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </p>
              </div>
            </div>
            <div className="bg-white rounded shadow w-64 p-2 m-1 py-1 px-3">
              <div className="flex justify-between">
                <div className="py-2">
                  <label htmlFor="caixa_total" className="text-base">
                    Total caixa
                  </label>
                </div>
                <div>
                  <label htmlFor="caixa_saida" className="text-xs">
                    Inicial
                  </label>
                  <p
                    id="caixa_saida"
                    className="text-xs text-gray-600 text-center leading-3 font-semibold"
                  >
                    {parseFloat(caixaInicial).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </p>
                </div>
              </div>
              <p
                id="caixa_total"
                className="text-4xl text-green-500 text-center "
              >
                {parseFloat(
                  caixa.caixa_total ? caixa.caixa_total : 0
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={() => history.push('/caixas/caixa/lancamento')}
          disabled={!Number(caixa.status)}
          className={`btn btn-pink flex items-center visible md:hidden ${
            !Number(caixa.status) &&
            'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
          }`}
        >
          <MdAdd />
          Novo lançamento
        </button>

        <MaterialTable
          title="Lançamentos"
          columns={[
            {
              title: 'Id',
              field: 'id',
              hidden: true,
            },
            {
              title: 'Caixa Id',
              field: 'caixa_id',
              hidden: true,
            },

            {
              title: '',
              field: 'tipo_movimento',
              type: 'numeric',
              render: (rowData) => (
                <div>
                  {(rowData.tipo_movimento === 'entrada' && (
                    <p className="flex content-center">
                      <BsArrowDown
                        title="Entrada estoque"
                        className="text-green-600 text-xl font-bold"
                      />
                      <span className="pl-1">
                        {/* rowData.tipo_movimento */}
                      </span>
                    </p>
                  )) || (
                    <p className="flex content-center">
                      <BsArrowUp
                        title="Saida estoque"
                        className="text-red-600 text-xl font-bold"
                      />
                      <span className="pl-1">
                        {/* rowData.tipo_movimento */}
                      </span>
                    </p>
                  )}
                </div>
              ),
            },
            {
              title: 'Ref',
              field: 'item_apelido',
              type: 'numeric',
              render: (rowData) => (
                <p>
                  {`${rowData.id}${
                    rowData.item_apelido ? `_${rowData.item_apelido}` : ''
                  }`}
                </p>
              ),
              cellStyle: {
                textAlign: 'right',
                whiteSpace: 'nowrap',
              },
            },

            {
              title: 'Status',
              field: 'comanda.status',
              lookup: { 0: 'Concluído', 1: 'Aberto' },
            },
            {
              title: 'Valor',
              field: 'valor',
              // type: "numeric",
              type: 'currency',
              currencySetting: { locale: 'br', currencyCode: 'brl' },
            },
            {
              title: 'Desconto',
              field: 'valor_desconto',
              // type: "numeric",
              type: 'currency',
              currencySetting: { locale: 'br', currencyCode: 'brl' },
            },
            {
              title: 'Promoção',
              field: 'valor_desconto_promocao',
              // type: "numeric",
              type: 'currency',
              currencySetting: { locale: 'br', currencyCode: 'brl' },
            },
            {
              title: 'Final',
              field: 'valor_final',
              // type: "numeric",
              type: 'currency',
              currencySetting: { locale: 'br', currencyCode: 'brl' },
            },

            {
              title: 'Forma',
              field: 'forma_pagamento.forma_pagamento',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Descrição',
              field: 'descricao',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Observacao',
              field: 'observacao',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },

            {
              title: 'Data',
              field: 'created_at',
              type: 'datetime',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
          ]}
          data={caixaItem}
          actions={[
            (rowData) => ({
              icon: 'receipt_long',
              tooltip: 'Abrir venda',
              onClick: () => {
                history.push('/caixas/caixa/lancamento', {
                  id: rowData.id,
                  caixa_id: rowData.id,
                  comanda_id: rowData.comanda_id,
                  comanda_status: Number(rowData.comanda.status),
                  item_apelido: rowData.item_apelido,
                });
                // console.log(rowData);
              },
              disabled: !rowData.comanda_id,
            }),

            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => loadCaixa(),
            },
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: true,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </div>

      {/** modal de lançamento de retirada do caixa */}
      <CardModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={() => setIsDialogOpen(!isDialogOpen)}
        titulo="Retirada Caixa"
        style={`"max-w-lg"`}
      >
        <form onSubmit={handleSubmitRetiradaCaixa} className="">
          <p className="text-center text-red-600 text-base ">
            {/* caixaError */}
          </p>
          <p className="text-center text-green-500 text-base ">
            {/* caixaOk */}
          </p>

          <div className="">
            <label className="text-xs text-gray-700">Valor Movimento</label>
            <NumberFormat
              allowNegative={false}
              displayType="number"
              format={currencyFormatter}
              placeholder=""
              value={valorRetirada}
              onChange={(e) => setValorRetirada(e.target.value)}
              className="input-text shadow text-2xl border"
            />
          </div>

          <div>
            <label className="text-xs text-gray-700">Forma Pagamento</label>
            <div className="relative w-full mb-2">
              <select
                className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                id="grid-state"
                value={formaPagamentoId}
                onChange={(e) => setFormaPagamentoId(e.target.value)}
              >
                {formasPagamento.map((forma) => (
                  <option key={forma.id} value={forma.id}>
                    {forma.forma_pagamento}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div>
            <label className="text-xs text-gray-700">Descrição</label>
            <textarea
              rows="4"
              cols="50"
              placeholder=""
              className="block resize-none input-text shadow mb-3 h-24 min-h-20"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </div>
          {/** botoes */}
          <div className="flex justify-end mt-8">
            <button
              onClick={() => setIsDialogOpen(!isDialogOpen)}
              type="button"
              className=" mx-2 btn btn-pink-outline"
            >
              Cancelar
            </button>

            <button
              disabled={loadRetirada}
              type="submit"
              className={`btn btn-pink flex items-center justify-center  ${
                loadRetirada && 'disabled'
              }`}
            >
              {(loadRetirada && (
                <div className="flex justify-center items-center">
                  <Spinner
                    className="mx-1"
                    size={24}
                    singleColor="#fbb6ce"
                    borderSize={4}
                  />
                  aguarde...
                </div>
              )) || (
                <>
                  <span>Lançar</span>
                </>
              )}
            </button>
          </div>
        </form>
      </CardModal>

      {/** dialogo de fechamto de caixa */}
      <CardModal
        isDialogOpen={isCloseDialogOpen}
        setIsDialogOpen={() => setIsCloseDialogOpen(!isCloseDialogOpen)}
        titulo="fechar Caixa"
      >
        <div className="px-2">
          <p className="flex justify-center items-center">
            Você está prestes a fechar o caixa atual, desenha continuar?
          </p>

          <div className="flex justify-end mt-8">
            <button
              onClick={() => setIsCloseDialogOpen(!isCloseDialogOpen)}
              type="button"
              className=" mx-2 btn btn-pink-outline"
            >
              Não
            </button>

            <button
              onClick={handleFecharCaixa}
              disabled={loadFechar}
              type="button"
              className={`btn btn-pink flex items-center justify-center  ${
                loadFechar && 'disabled'
              }`}
            >
              {(loadFechar && (
                <div className="flex justify-center items-center">
                  <Spinner
                    className="mx-1"
                    size={24}
                    singleColor="#fbb6ce"
                    borderSize={4}
                  />
                  aguarde...
                </div>
              )) || (
                <>
                  <span>Sim</span>
                </>
              )}
            </button>
          </div>
        </div>
      </CardModal>
    </>
  );
}

export default Caixa;
