/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useLayoutEffect, memo } from 'react';
import { MdSave, MdNavigateNext, MdAdd, MdList } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Spinner from 'react-md-spinner';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IoMdBarcode } from 'react-icons/io';

import currencyFormatter from '../../components/currencyFormatterBrl';
import CardModal from '../../components/CardModal';

import api from '../../services/api';

function Produto({
  setProdutoForm = () => {},
  produtoForm = {},
  nextStep = () => {},
}) {
  const history = useHistory();
  /** state off redux */
  const auth = useSelector((state) => state.auth);
  const produtoBarCode = useSelector((state) => state.produtoBarCode);

  const [id, setId] = useState();
  const [codigoBarras, setCodigoBarras] = useState();
  const [fornecedorId, setFornecedorId] = useState();
  const [grupoProdutoId, setGrupoProdutoId] = useState();
  const [produto, setProduto] = useState();
  const [descricao, setDescricao] = useState();
  const [observacao, setObservacao] = useState();
  const [status, setStatus] = useState();
  const [fabricante, setFabricante] = useState();
  const [marca, setMarca] = useState();
  const [tamanho, setTamanho] = useState();
  const [precoCusto, setPrecoCusto] = useState();
  const [precoAtacado, setPrecoAtacado] = useState();
  const [precoVarejo, setPrecoVarejo] = useState();

  const [fornecedores, setFornecedores] = useState([]);
  const [grupos, setGrupos] = useState([]);

  const [load, setLoad] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useLayoutEffect(() => {
    console.log(produtoForm);
    setId((!!produtoForm && produtoForm.id) || null);
    setFornecedorId((!!produtoForm && produtoForm.fornecedorId) || '');
    setGrupoProdutoId((!!produtoForm && produtoForm.grupoProdutoId) || '');
    setCodigoBarras((!!produtoForm && produtoForm.codigoBarras) || '');
    setProduto((!!produtoForm && produtoForm.produto) || '');
    setDescricao((!!produtoForm && produtoForm.descricao) || '');
    setObservacao((!!produtoForm && produtoForm.observacao) || '');
    setStatus((!!produtoForm && produtoForm.status) || true);
    setFabricante((!!produtoForm && produtoForm.fabricante) || '');
    setMarca((!!produtoForm && produtoForm.tamanho) || '');
    setTamanho((!!produtoForm && produtoForm.marca) || '');
    setPrecoCusto((!!produtoForm && produtoForm.precoCusto) || 0);
    setPrecoAtacado((!!produtoForm && produtoForm.precoAtacado) || 0);
    setPrecoVarejo((!!produtoForm && produtoForm.precoVarejo) || 0);
  }, []);

  async function loadFornecedores() {
    await api
      .get('fornecedor')
      .then((response) => {
        setFornecedores(response.data);
        // console.log(response.data);
      })
      .catch((error) => console.log(error.response));
  }

  useEffect(() => {
    (async function loadGrupos() {
      await api
        .get('grupoproduto')
        .then((response) => setGrupos(response.data))
        .catch((error) => console.log(error.response));
    })();

    loadFornecedores();
  }, []);

  // dados do codigo de barras do produto
  useEffect(() => {
    if (produtoBarCode.barCode) {
      setCodigoBarras(produtoBarCode.barCode);
    }
  }, [produtoBarCode]);

  /** ler código de barras presente no produto */
  async function handleLerCodigoProduto() {
    history.push('/produtos/produto/barcode');
  }

  async function add(data) {
    // console.log(data);
    setLoad(true);

    await api
      .post(`/produto`, data)
      .then((response) => {
        // console.log(response.data);
        setProdutoForm(response.data);
        nextStep();
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  async function up(id, data) {
    // console.log(data);
    setLoad(true);
    await api
      .put(`/produto/${id}`, data)
      .then(() => {
        // console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      fornecedor_id: fornecedorId,
      grupo_produto_id: grupoProdutoId,
      codigo_barras: codigoBarras,
      produto,
      descricao,
      observacao,
      status,
      fabricante,
      marca,
      tamanho,
      preco_custo: !isNaN(precoCusto)
        ? precoCusto
        : parseFloat(precoCusto.replace(/[^\d\,]/g, '').replace(',', '.')),
      preco_atacado: !isNaN(precoAtacado)
        ? precoAtacado
        : parseFloat(precoAtacado.replace(/[^\d\,]/g, '').replace(',', '.')),
      preco_varejo: !isNaN(precoVarejo)
        ? precoVarejo
        : parseFloat(precoVarejo.replace(/[^\d\,]/g, '').replace(',', '.')),
      loja_id: Number(auth.data.lojaId),
    };

    if (!produtoForm.id) {
      add(data);
      // nextStep();
    } else {
      up(produtoForm.id, data);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="px-2 py-4">
        <div>
          <div className="w-full flex items-end sm:w-1/2">
            <div>
              <label className="text-sm text-pink-500">Código de Barras</label>
              <input
                type="text"
                placeholder=""
                required
                className="input-text shadow"
                value={codigoBarras}
                onChange={(e) => setCodigoBarras(e.target.value)}
              />
            </div>
            <div className="ml-2 my-2">
              <button
                onClick={handleLerCodigoProduto}
                type="button"
                className="btn btn-pink m-0 py-3"
              >
                <IoMdBarcode />
              </button>
            </div>
          </div>
          <label className="text-sm text-pink-500">Produto</label>
          <input
            type="text"
            placeholder=""
            required
            className="input-text shadow"
            value={produto}
            onChange={(e) => setProduto(e.target.value)}
          />
          <div>
            <label className="text-sm text-pink-500">Descrição Completa</label>
            <textarea
              rows="4"
              cols="50"
              placeholder=""
              required
              className="block resize-none input-text shadow mb-3 h-24 min-h-20"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="w-full sm:w-2/4 mr-2">
              <label className="text-sm text-pink-500">Fabricante</label>
              <input
                type="text"
                placeholder=""
                required
                className="input-text shadow"
                value={fabricante}
                onChange={(e) => setFabricante(e.target.value)}
              />
            </div>
            <div className="w-full sm:w-2/4 mr-2">
              <label className="text-sm text-pink-500">Marca</label>
              <input
                type="text"
                placeholder=""
                required
                className="input-text shadow"
                value={marca}
                onChange={(e) => setMarca(e.target.value)}
              />
            </div>
            <div className="w-full sm:w-1/4">
              <label className="text-sm text-pink-500">Tamanho</label>
              <input
                type="text"
                placeholder=""
                className="input-text shadow"
                value={tamanho}
                onChange={(e) => setTamanho(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col sm:flex-row md:w-4/5">
              <div className="sm:mr-2 sm:w-1/2 mb-2">
                <label className="text-sm text-pink-500">Fornecedor*</label>
                <div className="flex items-center">
                  <div className="relative w-full">
                    <select
                      className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 pt-3 pb-2 px-4 pr-8 leading-tight focus:outline-none "
                      id="grid-state"
                      required
                      value={fornecedorId}
                      onChange={(e) => setFornecedorId(e.target.value)}
                    >
                      <option />
                      {fornecedores.map((fornecedor) => (
                        <option key={fornecedor.id} value={fornecedor.id}>
                          {fornecedor.pessoa === 'fisica'
                            ? fornecedor.nome_responsavel
                            : fornecedor.nome_fantasia}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => setIsDialogOpen(!isDialogOpen)}
                    className="btn btn-pink py-3 my-0 ml-1"
                  >
                    <MdList />
                  </button>
                </div>
              </div>
              <div className="sm:mr-2 sm:w-1/2 mb-2">
                <label className="text-sm text-pink-500">Grupo*</label>
                <div className="flex items-center">
                  <div className="relative w-full">
                    <select
                      className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 pt-3 pb-2 px-4 pr-8 leading-tight focus:outline-none "
                      id="grid-state"
                      value={grupoProdutoId}
                      onChange={(e) => setGrupoProdutoId(e.target.value)}
                    >
                      <option />
                      {grupos.map((grupo) => (
                        <option key={grupo.id} value={grupo.id}>
                          {grupo.grupo}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => history.push('/produtos/grupos')}
                    className="btn btn-pink py-3 my-0 ml-1"
                  >
                    <MdAdd />
                  </button>
                </div>
              </div>
            </div>
            <div className="md:pr-0 w-1/2 md:w-1/5 mb-2">
              <label className="text-sm text-pink-500">Status</label>

              <div className="relative w-full">
                <select
                  className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 pt-3 pb-2 px-4 pr-8 leading-tight focus:outline-none "
                  id="grid-state"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value={1}>Ativo</option>
                  <option value={0}>inativo</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row py-2 my-2 border border-l-0 border-r-0 border-pink-500">
            <div className="mr-2 w-1/2 sm:w-1/3">
              <label className="text-sm text-pink-500">Preço Custo*</label>
              <NumberFormat
                allowNegative={false}
                displayType="number"
                format={currencyFormatter}
                placeholder=""
                required
                value={precoCusto}
                onChange={(e) => setPrecoCusto(e.target.value)}
                className="input-text shadow"
              />
            </div>
            <div className=" mr-2 w-1/2 sm:w-1/3">
              <label className="text-sm text-pink-500">Preço Revenda</label>
              <NumberFormat
                allowNegative={false}
                displayType="number"
                format={currencyFormatter}
                placeholder=""
                value={precoAtacado}
                onChange={(e) => setPrecoAtacado(e.target.value)}
                className="input-text shadow"
              />
            </div>

            <div className=" w-1/2 sm:w-1/3">
              <label className="text-sm text-pink-500">Preço Venda*</label>
              <NumberFormat
                allowNegative={false}
                displayType="number"
                format={currencyFormatter}
                placeholder=""
                required
                value={precoVarejo}
                onChange={(e) => setPrecoVarejo(e.target.value)}
                className="input-text shadow"
              />
            </div>
          </div>

          <div>
            <label className="text-sm text-pink-500">Observação</label>
            <textarea
              rows="4"
              cols="50"
              placeholder=""
              className="block resize-none input-text shadow mb-3 h-24 min-h-20"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
            />
          </div>
          <div>
            <button
              disabled={load}
              type="submit"
              className={`btn btn-pink flex items-center justify-center w-full ${
                load && 'disabled'
              }`}
            >
              <div className="flex justify-center items-center">
                {(load && (
                  <>
                    <Spinner
                      className="mx-1"
                      size={24}
                      singleColor="#fbb6ce"
                      borderSize={4}
                    />
                    aguarde...
                  </>
                )) ||
                !!produtoForm.id ? (
                  <>
                    <MdSave />
                    <span>Salvar</span>
                  </>
                ) : (
                  <>
                    <span>Continuar</span>
                    <MdNavigateNext />
                  </>
                )}
              </div>
            </button>
          </div>
        </div>
      </form>

      {/**
       *
       *
       * modal de listagem de clientes */}
      <CardModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={() => setIsDialogOpen(!isDialogOpen)}
        titulo="Fornecedores"
        style={`"max-w-2xl mt-10 p-0"`}
      >
        <MaterialTable
          title=""
          columns={[
            {
              title: 'Id',
              field: 'id',
              hidden: true,
            },
            {
              title: 'Loja Id',
              field: 'loja_id',
              hidden: true,
            },
            {
              title: 'Nome Responsavel',
              field: 'nome_responsavel',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Razão Social',
              field: 'razao_social',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Nome Fantasia',
              field: 'nome_fantasia',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Pessoa',
              field: 'pessoa',
            },
            {
              title: 'CNPJ',
              field: 'cnpj',
            },
            {
              title: 'CPF',
              field: 'cpf',
            },
            {
              title: 'Incrição Estadual',
              field: 'inscricao_estadual',
            },

            {
              title: 'Cidade',
              field: 'cidade',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },

            {
              title: 'Uf',
              field: 'uf',
            },

            {
              title: 'Contato',
              field: 'contato1',
            },

            {
              title: 'Email',
              field: 'email',
            },
          ]}
          data={fornecedores}
          actions={[
            (rowData) => ({
              icon: 'checked',
              tooltip: 'Selecionar Cliente',

              onClick: () => {
                setFornecedorId(rowData.id);
                setIsDialogOpen(!isDialogOpen);
              },
            }),

            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => loadFornecedores(),
            },
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: false,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </CardModal>
    </>
  );
}

export default memo(Produto);
