const INITIAL_STATE = {
  token: null,
  autonticated: false,
  data: {
    colaboradorId: null,
    foto: null,
    loja: 'Nome da loja',
    lojaId: null,

    userName: 'usuário',
    nivelAcesso: 0,
  },
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_AUTH':
      return action.data;
    case 'REMOVE_AUTH':
      return INITIAL_STATE;
    default:
      return state;
  }
}
