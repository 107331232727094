/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { MdSave, MdArrowBack } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from 'react-md-spinner';

import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Avatar from '../../components/Avatar';

import logoEmp from '../../assets/em-branco.png';

export default function EmpresaForm() {
  const history = useHistory();
  const [id, setId] = useState(null);
  const [nomeFantasia, setNomeFantasia] = useState(null);
  const [razaoSocial, setRazaoSocial] = useState(null);
  const [cnpj, setCnpj] = useState(null);
  const [inscricaoEstadual, setInscricaoEstadual] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const [load, setLoad] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { empresa } = location.state;
      setId(empresa.id);
      setNomeFantasia(empresa.nome_fantasia);
      setRazaoSocial(empresa.razao_social);
      setCnpj(empresa.cnpj);
      setInscricaoEstadual(empresa.inscricao_estadual);
      setLogoPreview(
        `${process.env.REACT_APP_BASE_URL_STORAGE}/${empresa.logo}`
      );
    }
  }, []);

  async function add(data) {
    setLoad(true);
    await api
      .post('/empresa', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        // handle success
        console.log(response);

        // alert("Sucesso!");
        history.goBack();
      })
      .catch((error) => {
        // handle error
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  async function up(id, data) {
    setLoad(true);
    await api
      .post(`/empresa/up/${id}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        // handle success
        console.log(response);

        // alert("Sucesso!");
        history.goBack();
      })
      .catch((error) => {
        // handle error
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append('nome_fantasia', nomeFantasia);
    formData.append('razao_social', razaoSocial);
    formData.append('cnpj', cnpj.replace(/[^0-9]+/g, ''));
    formData.append(
      'inscricao_estadual',
      inscricaoEstadual.replace(/[^0-9]+/g, '')
    );
    if (logo) {
      formData.append('logo', logo);
    }

    if (id) {
      up(id, formData);
    } else {
      add(formData);
    }
  }

  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-20 text-gray-700 p-2">
        <div className="flex justify-between max-w-4xl mx-auto  items-center">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="flex btn btn-pink items-center"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-center text-pink-500 font-semibold -ml-16">
            Empresa
          </h1>
          <div />
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col sm:flex-row max-w-4xl mx-auto"
        >
          <div className=" pt-8 pb-0 w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/4">
            <Avatar
              perfil={logoPreview || logoEmp}
              wh="w-32 h-32 xl:w-40 xl:h-40"
              brd="border-4"
              mx="mx-auto"
            />

            <div className="flex w-32 mx-auto text-base items-center justify-center bg-grey-lighter my-2">
              <label className="w-64 flex flex-col items-center px-4 py-1 bg-white text-pink-500 rounded-lg shadow-lg tracking-wide uppercase border border-pink-400 cursor-pointer hover:bg-pink-400 hover:text-white">
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-1 text-sm leading-normal">Carregar</span>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    setLogo(e.target.files[0]);
                    setLogoPreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </label>
            </div>
          </div>

          <div className="p-4 w-full xl:max-w-3xl">
            <label className="block text-sm text-pink-500">Nome Fantasia</label>
            <input
              type="text"
              placeholder=""
              className="block input-text shadow"
              value={nomeFantasia}
              onChange={(e) => setNomeFantasia(e.target.value)}
            />
            <label className="block text-sm text-pink-500">Razão Sócial</label>
            <input
              type="text"
              placeholder=""
              className="block input-text shadow"
              value={razaoSocial}
              onChange={(e) => setRazaoSocial(e.target.value)}
            />

            <div className="flex  border-pink-400 xl:max-w-3xl">
              <div className="w-1/2 mr-2">
                <label className="block text-sm text-pink-500">CNPJ</label>
                <NumberFormat
                  format="##.###.###/####-##"
                  mask="_"
                  displayType="number"
                  className="block input-text shadow mb-3"
                  value={cnpj}
                  onChange={(e) => setCnpj(e.target.value)}
                />
              </div>
              <div className="w-1/2 ">
                <label className="block text-sm text-pink-500">
                  Inscrição Estadual
                </label>
                <NumberFormat
                  displayType="number"
                  className="block input-text shadow mb-3"
                  value={inscricaoEstadual}
                  onChange={(e) => setInscricaoEstadual(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                disabled={load}
                type="submit"
                className={`btn btn-pink flex items-center justify-center w-full ${
                  load && 'disabled'
                }`}
              >
                {(load && (
                  <div className="flex justify-center items-center">
                    <Spinner
                      className="mx-1"
                      size={24}
                      singleColor="#fbb6ce"
                      borderSize={4}
                    />
                    aguarde...
                  </div>
                )) || (
                  <>
                    <MdSave />
                    <span>Salvar</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
