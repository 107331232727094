/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { FaTelegramPlane } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';

import api from '../../services/api';
import NavBar from '../../components/NavBar';

export default function Detalhes() {
  const history = useHistory();
  const [id, setId] = useState();
  const [clientes, setClientes] = useState([]);

  const location = useLocation();
  useLayoutEffect(() => {
    if (location.state) {
      // console.log(location.state.data);
      setId(location.state.id);
    }
  }, []);

  useEffect(() => {
    (async function loadClient() {
      if (id)
        await api
          .get(`/cliente/${id}`)
          .then((response) => {
            setClientes(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    })();
  }, [id]);

  function editar(edit) {
    let data = {};
    switch (edit) {
      case 'cliente':
        // console.log(clientes.cpf.toString().length);
        data = {
          id: clientes.id,
          nomeResponsavel: clientes.nome_responsavel,
          razaoSocial: clientes.razao_social,
          nomeFantasia: clientes.nome_fantasia,
          pessoa: clientes.pessoa,
          cnpj: clientes.cnpj,
          cpf: clientes.cpf,
          inscricaoEstadual: clientes.inscricao_estadual,
          observacao: clientes.observacao,
        };
        break;
      case 'endereco':
        data = {
          id: clientes.id,
          cep: clientes.cep,
          tipoEndereco: clientes.tipo_endereco,
          cidade: clientes.cidade,
          bairro: clientes.bairro,
          logradouro: clientes.logradouro,
          uf: clientes.uf,
          complemento: clientes.complemento,
        };
        break;
      case 'contato':
        data = {
          id: clientes.id,
          email: clientes.email,
          contato1: clientes.contato1,
          contato1Whatsapp: clientes.contato1_whatsapp,
          contato1Telegram: clientes.contato1_telegram,
          contato2: clientes.contato2,
          contato2Whatsapp: clientes.contato2_whatsapp,
          contato2Telegram: clientes.contato2_telegram,
        };
        break;

      default:
        break;
    }
    history.push('/clientes/cliente', { edit, data });
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20 ">
        <div className="flex justify-between items-center mb-8 max-w-4xl mx-auto">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="flex btn btn-pink items-center"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-center text-pink-500 font-semibold -ml-16">
            Dados Clientes
          </h1>
          <div />
        </div>
        <div className="max-w-4xl mx-auto p-3 bg-white shadow rounded-sm">
          <div className="py-3 ">
            <div className="px-2">
              <div className="flex justify-between pb-2">
                <div>
                  <label className="block text-sm text-pink-500">
                    Nome Responsavel
                  </label>
                  <p>{clientes.nome_responsavel}</p>
                </div>
                <button
                  type="button"
                  onClick={() => editar('cliente')}
                  className="btn btn-pink py-1"
                >
                  Editar
                </button>
              </div>
              <div className="pb-2">
                <label className="block text-sm text-pink-500">
                  Nome Fantasia
                </label>
                <p>{clientes.nome_fantasia}</p>
              </div>
              <div className="pb-2">
                <label className="block text-sm text-pink-500">
                  Razão Social
                </label>
                <p>{clientes.razao_social}</p>
              </div>
              <div className="flex flex-col sm:flex-row items-center pb-2">
                <div className="w-full sm:w-1/5 pb-2">
                  <label className="block text-sm text-pink-500">Pessoa</label>
                  <p>{clientes.pessoa}</p>
                </div>
                <div className="flex w-full">
                  <div className="w-1/2 sm:w-1/3">
                    <label className="block text-sm text-pink-500">
                      CPF/CNPJ
                    </label>
                    <p>
                      {(!!clientes.cpf &&
                        clientes.cpf.toString().length < 11 &&
                        `0${clientes.cpf}`) ||
                        clientes.cpf ||
                        clientes.cnpj}
                    </p>
                  </div>
                  <div className="w-1/2 sm:w-1/4">
                    <label className="block text-sm text-pink-500">
                      Inscrição Estadual
                    </label>
                    <p>{clientes.inscricao_estadual}</p>
                  </div>
                </div>
              </div>

              <div className="pb-2">
                <label className="block text-sm text-pink-500">
                  Observacao
                </label>
                <p>{clientes.observacao}</p>
              </div>
            </div>
          </div>

          <div className="py-3">
            <h1 className="text-xl text-pink-500 py-2 border border-pink-500 border-l-0 border-r-0 border-t-0">
              Endereço
            </h1>
            <div className="py-6 px-2">
              <div className="flex justify-between pb-2">
                <div>
                  <label className="text-sm text-pink-500">CEP</label>
                  <p>{clientes.cep}</p>
                </div>
                <button
                  type="button"
                  onClick={() => editar('endereco')}
                  className="btn btn-pink py-1"
                >
                  Editar
                </button>
              </div>
              <div className="flex pb-2">
                <div className="w-1/2 ">
                  <label className="text-sm text-pink-500">Logradoudo</label>
                  <p>{clientes.logradouro}</p>
                </div>
                <div className="w-1/2">
                  <label className="text-sm text-pink-500">Bairro</label>
                  <p>{clientes.bairro}</p>
                </div>
              </div>
              <div className="flex pb-2">
                <div className="w-1/2 ">
                  <label className="text-sm text-pink-500">Cidade</label>
                  <p>{clientes.cidade}</p>
                </div>
                <div className="w-1/2">
                  <label className="text-sm text-pink-500">UF</label>
                  <p>{clientes.uf}</p>
                </div>
              </div>
              <div>
                <label className="text-sm text-pink-500">complemento</label>
                <p>{clientes.complemento}</p>
              </div>
            </div>
          </div>

          <div className="py-3 ">
            <h1 className="text-xl text-pink-500 py-2 border border-pink-500 border-l-0 border-r-0 border-t-0">
              Contatos
            </h1>
            <div className="py-6 px-2">
              <div className="flex justify-between pb-2">
                <div>
                  <label className="text-sm text-pink-500">Email</label>
                  <p>{clientes.email}</p>
                </div>
                <button
                  type="button"
                  onClick={() => editar('contato')}
                  className="btn btn-pink py-1"
                >
                  Editar
                </button>
              </div>

              <div className="pb-2">
                <label className="text-sm text-pink-500">Contato 1</label>
                <p className="flex">
                  {clientes.contato1}
                  <span className="pl-2">
                    {(clientes.contato1_whatsapp && (
                      <IoLogoWhatsapp title="Whatsapp" />
                    )) ||
                      ''}
                  </span>
                  <span className="pl-2">
                    {(clientes.contato1_telegram && (
                      <FaTelegramPlane title="Telegram" />
                    )) ||
                      ''}
                  </span>
                </p>
              </div>
              <div className="pb-2">
                <label className="text-sm text-pink-500">Contato 2</label>
                <p className="flex">
                  {clientes.contato2}
                  <span className="pl-2">
                    {(clientes.contato2_whatsapp && (
                      <IoLogoWhatsapp title="Whatsapp" />
                    )) ||
                      ''}
                  </span>
                  <span className="pl-2">
                    {(clientes.contato2_telegram && (
                      <FaTelegramPlane title="Telegram" />
                    )) ||
                      ''}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
