/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useLayoutEffect, memo } from 'react';
import { MdSave } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Spinner from 'react-md-spinner';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

function Contato({
  setContato = () => {},
  contato = {},
  load = false,
  setLoad = () => {},
  handleClienteSubmit = () => {},
}) {
  const history = useHistory();

  const [email, setEmail] = useState();
  const [contato1, setContato1] = useState();
  const [contato1Whatsapp, setContato1Whatsapp] = useState();
  const [contato1Telegram, setContato1Telegram] = useState();
  const [contato2, setContato2] = useState();
  const [contato2Whatsapp, setContato2Whatsapp] = useState();
  const [contato2Telegram, setContato2Telegram] = useState();

  const [loadC, setLoadC] = useState(false);

  useLayoutEffect(() => {
    setEmail((!!contato && contato.email) || '');
    setContato1((!!contato && contato.contato1) || '');
    setContato1Whatsapp((!!contato && contato.contato1Whatsapp) || false);
    setContato1Telegram(
      (!!contato && contato.contato1TelegrasetContato1Telegram) || false
    );
    setContato2((!!contato && contato.contato2) || '');
    setContato2Whatsapp((!!contato && contato.contato2Whatsapp) || false);
    setContato2Telegram(
      (!!contato && contato.contato1TelegrasetContato2Telegram) || false
    );
  }, []);

  async function up(id) {
    setLoadC(true);
    const data = {
      email,
      contato1: contato1.replace(/[^0-9]+/g, ''),
      contato1_whatsapp: contato1Whatsapp,
      contato1_telegram: contato1Telegram,
      contato2: contato2.replace(/[^0-9]+/g, ''),
      contato2_whatsapp: contato2Whatsapp,
      contato2_telegram: contato2Telegram,
    };

    // console.log(data);

    await api
      .put(`/cliente/${id}`, data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoadC(false);
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!contato.id) {
      setContato(
        email,
        contato1,
        contato1Whatsapp,
        contato1Telegram,
        contato2,
        contato2Whatsapp,
        contato2Telegram
      );

      await handleClienteSubmit({
        email,
        contato1,
        contato1Whatsapp,
        contato1Telegram,
        contato2,
        contato2Whatsapp,
        contato2Telegram,
      });
    } else {
      up(contato.id);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="px-2 py-4">
      <div className="">
        <label className="text-sm text-pink-500">Email</label>

        <input
          type="email"
          placeholder=""
          className="input-text shadow"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="flex flex-col sm:flex-row">
          <div className="mr-2 w-full sm:w-1/2">
            <label className="text-sm text-pink-500">Contato 1</label>
            <NumberFormat
              format="(##) #####-####"
              mask="_"
              displayType="number"
              required
              placeholder=""
              className="input-text shadow"
              value={contato1}
              onChange={(e) => setContato1(e.target.value)}
            />
          </div>
          <div className="mr-2 w-full flex items-center">
            <label className=" m-4 pt-4 block text-pink-500 font-semibold">
              <input
                className="mr-2 leading-tight"
                type="checkbox"
                checked={contato1Whatsapp}
                onChange={(e) => setContato1Whatsapp(e.target.checked)}
              />
              <span className="text-sm">Whatsapp</span>
            </label>
            <label className=" m-4 pt-4 block text-pink-500 font-semibold">
              <input
                className="mr-2 leading-tight"
                type="checkbox"
                checked={contato1Telegram}
                onChange={(e) => setContato1Telegram(e.target.checked)}
              />
              <span className="text-sm">Telegram</span>
            </label>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row">
          <div className="mr-2 w-full sm:w-1/2">
            <label className="text-sm text-pink-500">Contato 2</label>
            <NumberFormat
              format="(##) #####-####"
              mask="_"
              displayType="number"
              placeholder=""
              className="input-text shadow"
              value={contato2}
              onChange={(e) => setContato2(e.target.value)}
            />
          </div>
          <div className="mr-2 w-full flex items-center">
            <label className=" m-4 pt-4 block text-pink-500 font-semibold">
              <input
                className="mr-2 leading-tight"
                type="checkbox"
                checked={contato2Whatsapp}
                onChange={(e) => setContato2Whatsapp(e.target.checked)}
              />
              <span className="text-sm">Whatsapp</span>
            </label>
            <label className=" m-4 pt-4 block text-pink-500 font-semibold">
              <input
                className="mr-2 leading-tight"
                type="checkbox"
                checked={contato2Telegram}
                onChange={(e) => setContato2Telegram(e.target.checked)}
              />
              <span className="text-sm">Telegram</span>
            </label>
          </div>
        </div>

        <div>
          <button
            disabled={load || loadC}
            type="submit"
            className={`btn btn-pink flex items-center justify-center w-full ${
              load && 'disabled'
            }`}
          >
            {((load || loadC) && (
              <div className="flex justify-center items-center">
                <Spinner
                  className="mx-1"
                  size={24}
                  singleColor="#fbb6ce"
                  borderSize={4}
                />
                aguarde...
              </div>
            )) || (
              <>
                <MdSave />
                <span>Salvar</span>
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

export default memo(Contato);
