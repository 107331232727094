/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import Avatar from './Avatar';
import { removeAuth } from '../store/actions/auth';

function AccountDropdown({ perfil = '' }) {
  const [openDrop, setOpenDrop] = useState(false);

  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleEscape = (e) => {
    if (e.key === 'Esc' || e.key === 'Escape') {
      setOpenDrop(false);
    }
    document.removeEventListener('keydown', handleEscape);
  };
  document.addEventListener('keydown', handleEscape);

  function handleSair() {
    dispatch(removeAuth());
  }

  return (
    <div className="lg:py-0 py-4 relative hidden lg:block ">
      <button
        type="button"
        onClick={() => setOpenDrop(!openDrop)}
        className="relative z-10 flex items-center hover:opacity-75 focus:outline-none focus:opacity-75"
      >
        <Avatar perfil={perfil} wh="w-10 h-10" brd="border-2" />
      </button>

      {openDrop && (
        <>
          <button
            type="button"
            onClick={() => setOpenDrop(!openDrop)}
            tabIndex={-1}
            className="fixed inset-0 w-full h-full cursor-default"
          />
          <div className="absolute right-0 mt-1 w-48 py-2 bg-white rounded-lg shadow-md">
            <Link
              to="/perfil"
              className="block px-4 py-2 text-gray-800 hover:bg-pink-400 hover:text-white focus:bg-pink-400 focus:text-white focus:outline-none"
            >
              Perfil
            </Link>
            {Number(auth.data.nivelAcesso) === 2 && (
              <Link
                to="/definicoes"
                className="block px-4 py-2 text-gray-800 hover:bg-pink-400 hover:text-white focus:bg-pink-400 focus:text-white focus:outline-none"
              >
                Definições
              </Link>
            )}
            <Link
              to=""
              onClick={handleSair}
              className="block px-4 py-2 text-gray-800 hover:bg-pink-400 hover:text-white focus:bg-pink-400 focus:text-white focus:outline-none"
            >
              Sair
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default memo(AccountDropdown);
