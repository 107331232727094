import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import NavBar from '../../components/NavBar';

export default function Promocoes() {
  const history = useHistory();
  const [promocoes, setPromocoes] = useState([]);
  const [load, setLoad] = useState(false);

  const auth = useSelector((state) => state.auth);

  async function loadPromocoes() {
    setLoad(true);
    await api
      .get(`promocao/loja/${auth.data.lojaId}`)
      .then((response) => {
        setPromocoes(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    loadPromocoes();
  }, []);

  async function handleRemove(id) {
    setLoad(true);
    await api
      .delete(`promocao/${id}`)
      .then(() => {
        setPromocoes(promocoes.filter((rest) => rest.id !== id));
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
        console.log('finally');
      });
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20 max-w-3xl">
        <button
          type="button"
          onClick={() => history.push('/promocoes/promocao')}
          className="btn btn-pink flex items-center"
        >
          <MdAdd />
          Novo
        </button>

        <MaterialTable
          title="Promoção"
          columns={[
            {
              title: 'Id',
              field: 'id',
              hidden: true,
            },
            {
              title: 'Ativo',
              field: 'ativo',
              lookup: { 1: 'Ativo', 0: 'Inativo' },
            },

            {
              title: 'Descrição',
              field: 'descricao',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            {
              title: 'Desconto',
              field: 'desconto',
              // type: "currency",
              // currencySetting: { locale: 'br', currencyCode: "BRL" },
              render: (rowData) => (
                <p className="">
                  {!rowData.porcentagem
                    ? parseFloat(rowData.desconto).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : `${parseFloat(rowData.desconto).toLocaleString()}%`}
                </p>
              ),
            },
            {
              title: 'Abertura',
              field: 'abertura',
              type: 'date',
            },
            {
              title: 'Fechamento',
              field: 'fechamento',
              type: 'date',
            },
            {
              title: 'Observação',
              field: 'observacao',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
          ]}
          data={promocoes}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Editar Promoção',
              onClick: (event, rowData) =>
                history.push('/promocoes/promocao', { data: rowData }),
            },
            /** 
            (rowData) => ({
              icon: "delete",
              tooltip: "Remover Promoção",
              onClick: () => {
                handleRemove(rowData.id);
              },
              disabled: load,
            }), */
            {
              icon: 'refresh',
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: () => loadPromocoes(),
            },
          ]}
          options={{
            actionsColumnIndex: 0,
            exportButton: true,
          }}
          // other props
          localization={{
            pagination: {
              labelRowsSelect: 'Linhas',
              labelDisplayedRows: '{from}-{to} de {count}',
              firstTooltip: 'Primeiro',
              previousTooltip: 'Voltar',
              nextTooltip: 'Próxima',
              lastTooltip: 'Ultimo',
            },
            toolbar: {
              exportTitle: 'Exportar',
              exportName: 'Exportar CSV',
              searchPlaceholder: 'Buscar',
              nRowsSelected: '{0} linha(s) selecionada(s)',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Não há registros a serem exibidos',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </div>
    </>
  );
}
