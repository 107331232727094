const INITIAL_STATE = {
  barCode: null,
};

export default function produtoBarCode(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_PROD':
      return { barCode: action.data };
    case 'REMOVE_PROD':
      return INITIAL_STATE;
    default:
      return state;
  }
}
