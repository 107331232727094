import React, { useState, useEffect } from 'react';

import {
  FaUserFriends,
  FaUserTie,
  FaBoxes,
  FaMoneyCheckAlt,
} from 'react-icons/fa';
import { GoGraph } from 'react-icons/go';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { GiPresent } from 'react-icons/gi';
import { RiMoneyPoundCircleLine } from 'react-icons/ri';
import {
  MdWork,
  MdAttachMoney,
  MdAdd,
  MdAddShoppingCart,
} from 'react-icons/md';
import { FiTruck } from 'react-icons/fi';
import { BsArrowDown, BsArrowUp, BsGraphUp } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { addCaixa, removeCaixa } from '../store/actions/caixaAberto';

import api from '../services/api';
import NavBar from '../components/NavBar';

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const caixaAberto = useSelector((state) => state.caixaAberto);
  const [caixaItem, setCaixaItem] = useState([]);

  async function loadCaixa(caixaId) {
    await api
      .get(`caixa/${caixaId}`)
      .then((response) => {
        setCaixaItem(response.data.caixa_item);
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        console.log('finally');
      });
  }

  useEffect(() => {
    (async function loadCaixaAberto() {
      // console.log("lojaId: " + auth.data.lojaId);

      await api
        .get(`caixa/loja/${auth.data.lojaId}`)
        .then((response) => {
          if (response.data) {
            const caixaId = response.data[0].id;
            const { status } = response.data[0];
            dispatch(
              addCaixa({
                caixaId: Number(status) ? caixaId : null,
                status: !!Number(status),
              })
            );
            loadCaixa(caixaId);
          }
        })
        .catch((error) => {
          dispatch(removeCaixa());
          console.log(error.response);
        })
        .finally(() => {
          console.log('finally');
        });
    })();
  }, [auth]);

  return (
    <>
      {/** menu */}
      <NavBar />
      {/** container */}
      <div className="container mx-auto pt-16 ">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-7 lg:col-span-7 px-2 py-4">
            <h1 className="text-pink-500 text-xl">Cadastro</h1>
            <div className="grid grid-cols-2 gap-2 p-1 text-sm sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3">
              <div className="flex-col p-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                <div className="pb-3">
                  <FaUserFriends size={81} className="mx-auto" />
                </div>
                <Link to="/clientes" className="btn btn-pink py-2">
                  Clientes
                </Link>
              </div>
              {Number(auth.data.nivelAcesso) === 2 && (
                <>
                  <div className="flex-col pt-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div className="pb-3">
                      <FiTruck size={58} className="mx-auto m-3" />
                    </div>
                    <Link
                      to="/fornecedores"
                      className="btn btn-pink py-2 sm:px-2"
                    >
                      Fornecedores
                    </Link>
                  </div>
                  <div className="flex-col p-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div className="pb-3">
                      <FaBoxes size={66} className="mx-auto m-2" />
                    </div>
                    <Link to="/produtos" className="btn btn-pink py-2">
                      Produtos
                    </Link>
                  </div>
                  <div className="flex-col pt-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div className="pb-3">
                      <FaUserTie size={58} className="mx-auto m-3" />
                    </div>
                    <Link
                      to="/colaboradores"
                      className="btn btn-pink py-2 sm:px-2"
                    >
                      Funcionários
                    </Link>
                  </div>
                  <div className="flex-col pt-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div className="pb-3">
                      <GiPresent size={58} className="mx-auto m-3" />
                    </div>
                    <Link to="/promocoes" className="btn btn-pink py-2 sm:px-2">
                      Promoções
                    </Link>
                  </div>
                  <div className="flex-col pt-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div className="pb-3">
                      <RiMoneyPoundCircleLine
                        size={58}
                        className="mx-auto m-3"
                      />
                    </div>
                    <Link
                      to="/formaspagamento"
                      className="btn btn-pink py-2 whitespace-no-wrap sm:px-2"
                    >
                      Formas Pagamento
                    </Link>
                  </div>
                  <div className="flex-col pt-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div className="pb-3">
                      <MdWork size={58} className="mx-auto m-3" />
                    </div>
                    <Link to="/cargos" className="btn btn-pink py-2">
                      Cargos
                    </Link>
                  </div>
                </>
              )}
              <div className="flex-col pt-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                <div className="pb-3">
                  <FaMoneyCheckAlt size={58} className="mx-auto m-3" />
                </div>
                <Link to="/caixas" className="btn btn-pink py-2">
                  Caixas
                </Link>
              </div>
              {Number(auth.data.nivelAcesso) === 2 && (
                <>
                  <div className="hidden flex-col pt-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div className="pb-3">
                      <MdAddShoppingCart size={58} className="mx-auto m-3" />
                    </div>
                    <Link to="/" className="btn btn-pink py-2 truncate">
                      Venda Atacado
                    </Link>
                  </div>
                </>
              )}
            </div>
            {Number(auth.data.nivelAcesso) === 2 && (
              <div className="hidden">
                <h1 className="text-pink-500 text-xl mt-2">Relatório</h1>
                <div className="grid grid-cols-2 gap-2 p-1 text-sm sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3">
                  <div className="flex-col p-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div>
                      <BsGraphUp size={57} className="mx-auto m-3" />
                    </div>
                    <button type="button" className="btn btn-pink py-1">
                      Vendas
                    </button>
                  </div>
                  <div className="flex-col p-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div>
                      <AiOutlineFundProjectionScreen
                        size={66}
                        className="mx-auto m-2"
                      />
                    </div>
                    <button type="button" className="btn btn-pink py-1">
                      Produtos
                    </button>
                  </div>
                  <div className="p-2 pb-3 text-pink-400 text-center bg-pink-250 rounded-lg shadow ">
                    <div>
                      <GoGraph size={66} className="mx-auto m-2" />
                    </div>
                    <button type="button" className="btn btn-pink py-1">
                      Clientes
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-span-12 md:col-span-5 lg:col-span-5">
            <div className="flex justify-between">
              <button
                type="button"
                onClick={() => history.push('/caixas/caixa')}
                disabled={!caixaAberto.status}
                className={`btn btn-pink flex items-center py-1 mx-2 truncate ${
                  !caixaAberto.status &&
                  'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
                }`}
              >
                <MdAttachMoney className="text-lg" />
                Caixa do Dia
              </button>

              <button
                type="button"
                onClick={() => history.push('/caixas/caixa/lancamento')}
                disabled={!caixaAberto.status}
                className={`btn btn-pink flex items-center truncate mx-2 ${
                  !caixaAberto.status &&
                  'opacity-50 cursor-not-allowed hover:opacity-50 focus:opacity-50'
                }`}
              >
                <MdAdd />
                Novo lançamento
              </button>
            </div>
            <div className="px-2 pb-2 content-center">
              <MaterialTable
                title="Últimos Lançamentos"
                columns={[
                  {
                    title: 'Id',
                    field: 'id',
                  },
                  {
                    title: '',
                    field: 'tipo_movimento',
                    type: 'numeric',
                    render: (rowData) => (
                      <div>
                        {(rowData.tipo_movimento === 'entrada' && (
                          <p className="flex content-center">
                            <BsArrowDown
                              title="Entrada estoque"
                              className="text-green-600 text-xl font-bold"
                            />
                            <span className="pl-1">
                              {/* rowData.tipo_movimento */}
                            </span>
                          </p>
                        )) || (
                          <p className="flex content-center">
                            <BsArrowUp
                              title="Saida estoque"
                              className="text-red-600 text-xl font-bold"
                            />
                            <span className="pl-1">
                              {/* rowData.tipo_movimento */}
                            </span>
                          </p>
                        )}
                      </div>
                    ),
                  },

                  {
                    title: 'Lançamento',
                    field: 'created_at',
                    type: 'time',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },

                  {
                    title: 'Valor',
                    field: 'valor_final',
                    // type: "numeric",
                    type: 'currency',
                    currencySetting: { locale: 'br', currencyCode: 'brl' },
                  },

                  {
                    title: 'Descrição',
                    field: 'descricao',
                    cellStyle: {
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    title: 'Status',
                    field: 'comanda.status',
                    lookup: { 0: 'Fechado', 1: 'Aberto' },
                  },
                ]}
                data={caixaItem}
                actions={[
                  (rowData) => ({
                    icon: 'receipt_long',
                    tooltip: 'Abrir venda',
                    onClick: () => {
                      history.push('/caixas/caixa/lancamento', {
                        id: rowData.id,
                        caixa_id: rowData.id,
                        comanda_id: rowData.comanda_id,
                        comanda_status: rowData.comanda.status,
                        item_apelido: rowData.item_apelido,
                      });
                      // console.log(rowData);
                    },
                    disabled: !rowData.comanda_id || !rowData.comanda.status,
                  }),
                  {
                    icon: 'refresh',
                    tooltip: 'Atualizar',
                    isFreeAction: true,
                    onClick: () => loadCaixa(caixaAberto.caixaId),
                  },
                ]}
                options={{
                  actionsColumnIndex: 0,
                  // exportButton: true,
                  search: false,
                }}
                // other props
                localization={{
                  pagination: {
                    labelRowsSelect: 'Linhas',
                    labelDisplayedRows: '{from}-{to} de {count}',
                    firstTooltip: 'Primeiro',
                    previousTooltip: 'Voltar',
                    nextTooltip: 'Próxima',
                    lastTooltip: 'Ultimo',
                  },
                  toolbar: {
                    exportTitle: 'Exportar',
                    exportName: 'Exportar CSV',
                    searchPlaceholder: 'Buscar',
                    nRowsSelected: '{0} linha(s) selecionada(s)',
                  },
                  header: {
                    actions: 'Ações',
                  },
                  body: {
                    emptyDataSourceMessage: 'Não há registros a serem exibidos',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
