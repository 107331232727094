/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useState, useEffect } from 'react';
import { MdClose, MdMenu } from 'react-icons/md';
import { FaBell } from 'react-icons/fa';
import { BiWindowOpen } from 'react-icons/bi';
import { useHistory, Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { removeAuth } from '../store/actions/auth';
import { removeCaixa } from '../store/actions/caixaAberto';

import api from '../services/api';

import AccountDropdown from './AccountDropdown';
import Avatar from './Avatar';
import CardModal from './CardModal';

import logo from '../assets/em-branco.png';
import perfil from '../assets/user-woman.svg';

function NavBar() {
  const [openMenu, setOpenMenu] = useState(false);
  const [logoEmpresa, setLogoEmpresa] = useState();

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [estoquesBaixos, setEstoquesBaixos] = useState([]);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const history = useHistory();

  useEffect(() => {
    (async function loadEstoquesBaixos() {
      await api
        .get(`estoquebaixo/loja/${auth.data.lojaId}`)
        .then((response) => {
          setEstoquesBaixos(response.data);
        })
        .catch((error) => console.log(error.response))
        .finally(() => console.log('finally'));
    })();
  }, []);

  useEffect(() => {
    (async function loadEmpresa() {
      await api
        .get(`empresa/${1}`)
        .then((response) => {
          setLogoEmpresa(response.data.logo);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data) {
              dispatch(removeAuth());
              dispatch(removeCaixa());
            }
          }
        })
        .finally(() => {
          console.log('finally');
        });
    })();
  }, []);

  const handleEscapeN = (e) => {
    if (e.key === 'Esc' || e.key === 'Escape') {
      setOpenMenu(false);
    }
    document.removeEventListener('keydown', handleEscapeN);
  };
  document.addEventListener('keydown', handleEscapeN);

  function handleSair() {
    dispatch(removeAuth());
    dispatch(removeCaixa());
  }

  return (
    <>
      {openMenu && (
        <button
          type="button"
          onClick={() => setOpenMenu(!openMenu)}
          className="fixed left-0 right-0 top-0 bottom-0 w-full h-full cursor-default"
        />
      )}
      <nav className="bg-pink-200 w-full fixed shadow z-20">
        <div className="relative container mx-auto flex py-1 px-2 items-center justify-between flex-wrap ">
          <div className="flex items-center flex-shrink-0 text-white pl-12 pr-20 md:pr-2 w-full md:w-auto">
            <div className="absolute left-0 w-12 h-12 overflow-hidden rounded-lg mx-2 shadow-lg">
              <Link to="/">
                <img
                  src={
                    logoEmpresa
                      ? `${process.env.REACT_APP_BASE_URL_STORAGE}/${logoEmpresa}`
                      : '' || logo
                  }
                  alt="Madamy acessórios"
                  className="object-cover object-center rounded-lg h-full w-full "
                />
              </Link>
            </div>

            <Link
              to="/"
              className="truncate font-smaller text-xl text-pink-500 m-2"
            >
              <span className="">{auth.data.loja}</span>
            </Link>
          </div>

          <div className=" absolute right-0 top-0 py-2 flex block lg:hidden">
            <button
              type="button"
              className="mr-3 "
              onClick={() => setIsAlertDialogOpen(!isAlertDialogOpen)}
            >
              <div className="flex text-lg text-pink-500">
                <FaBell />
                <div className="-ml-1 rounded-full">
                  <span className="text-xxs p-px leading-none shadow bg-red-500 text-pink-250 font-bold rounded-full">
                    {estoquesBaixos.length}
                  </span>
                </div>
              </div>
            </button>

            <button
              type="button"
              onClick={() => setOpenMenu(!openMenu)}
              className="flex items-center px-3 py-2 text-pink-500 hover:text-pink-600 focus:outline-none"
            >
              {(!openMenu && <MdMenu size={24} />) || <MdClose size={24} />}
            </button>
          </div>

          <div
            className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto z-10 mx-3 ${
              !openMenu ? 'hidden' : ''
            }`}
          >
            <div className="text-md lg:text-right lg:flex-grow pt-2 lg:pt-0 flex-grow lg:flex lg:items-center justify-end items-center">
              <button
                type="button"
                className="mr-3 hidden lg:block"
                onClick={() => setIsAlertDialogOpen(!isAlertDialogOpen)}
              >
                <div className="flex text-lg text-pink-500">
                  <FaBell />
                  <div className="-ml-1">
                    <span className="text-xxs p-px leading-none shadow bg-red-500 text-pink-250 font-bold rounded-full">
                      {estoquesBaixos.length}
                    </span>
                  </div>
                </div>
              </button>

              <Link
                to="/clientes"
                className="block mt-2 lg:inline-block lg:mt-0 text-pink-500 hover:text-pink-600 mr-4 focus:text-pink-600 focus:outline-none"
              >
                Cliente
              </Link>

              <Link
                to="/caixas/caixa"
                className="block mt-2 lg:inline-block lg:mt-0 text-pink-500 hover:text-pink-600 mr-4 focus:text-pink-600 focus:outline-none"
              >
                Venda
              </Link>
              {Number(auth.data.nivelAcesso) === 2 && (
                <>
                  <Link
                    to="/produtos"
                    className="block mt-2 lg:inline-block lg:mt-0 text-pink-500 hover:text-pink-600 mr-4 focus:text-pink-600 focus:outline-none"
                  >
                    Produto
                  </Link>
                  <Link
                    to="/"
                    className="hidden mt-2 lg:mt-0 text-pink-500 hover:text-pink-600 mr-4 focus:text-pink-600 focus:outline-none"
                  >
                    Relatórios
                  </Link>
                </>
              )}
            </div>

            <span className="text-sm mr-3 max-w-6xs truncate text-pink-600 hidden flex items-center lg:block">
              {auth.data.userName.toUpperCase()}
            </span>
            <AccountDropdown
              perfil={
                auth.data.foto
                  ? `${process.env.REACT_APP_BASE_URL_STORAGE}/${auth.data.foto}`
                  : perfil
              }
            />

            <div className="py-4 mt-4 border-t border-pink-350 lg:hidden">
              <div className="flex items-center content-start">
                <Avatar
                  perfil={
                    auth.data.foto
                      ? `${process.env.REACT_APP_BASE_URL_STORAGE}/${auth.data.foto}`
                      : perfil
                  }
                  wh="w-10 h-10"
                  brd="border-2"
                />
                <span className="ml-2 text-sm flex items-center font-medium tracking-tight text-pink-500 lg:hidden">
                  {auth.data.userName}
                </span>
              </div>

              <div className="right-0 mt-1 w-full text-sm">
                <Link
                  to="/perfil"
                  className="block mt-3 lg:inline-block lg:mt-0 text-pink-500 hover:text-pink-600 mr-4 focus:text-pink-600 focus:outline-none"
                >
                  Perfil
                </Link>
                {Number(auth.data.nivelAcesso) === 2 && (
                  <Link
                    to="/definicoes"
                    className="block mt-2 lg:inline-block lg:mt-0 text-pink-500 hover:text-pink-600 mr-4 focus:text-pink-600 focus:outline-none"
                  >
                    Definições
                  </Link>
                )}
                <Link
                  to=""
                  onClick={handleSair}
                  className="block mt-2 lg:inline-block lg:mt-0 text-pink-500 hover:text-pink-600 mr-4 focus:text-pink-600 focus:outline-none"
                >
                  Sair
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/**
       *
       *
       * modal de cupom de compra */}
      <CardModal
        isDialogOpen={isAlertDialogOpen}
        setIsDialogOpen={() => setIsAlertDialogOpen(!isAlertDialogOpen)}
        titulo="Estoque Baixo"
        style={`"max-w-md mt-10 p-2 w-full"`}
      >
        <table className="table-auto w-full">
          <tbody>
            {estoquesBaixos.map((estoque) => {
              console.log(estoque);
              return (
                <tr key={estoque.id}>
                  <td className="border">
                    <button
                      type="button"
                      onClick={() => {
                        history.push('/produtos/estoque', {
                          id: estoque.produto_id,
                        });
                      }}
                      className="p-2 w-full h-full flex items-center"
                    >
                      <BiWindowOpen className="mx-2" />
                      {estoque.produto.produto}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardModal>
    </>
  );
}

export default memo(NavBar);
