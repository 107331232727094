/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { MdAdd, MdArrowBack } from 'react-icons/md';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { useLocation, useHistory } from 'react-router-dom';

import api from '../../../services/api';
import NavBar from '../../../components/NavBar';

export default function Estoque() {
  const history = useHistory();

  const [estoqueInicial, setEstoqueInicial] = useState(0);

  const [estoque, setEstoque] = useState([]);
  const [itemEstoque, setItemEstoque] = useState([]);
  const [produto, setProduto] = useState([]);

  const location = useLocation();

  useEffect(() => {
    console.log(location.state.id);
    if (location.state) {
      (async function loadEstoque() {
        await api
          .get(`estoque/${location.state.id}`)
          .then((response) => {
            // console.log(response.data);
            const countItemEstoque = response.data.item_estoque.length;
            setEstoque(response.data);
            setItemEstoque(response.data.item_estoque);
            setProduto(response.data.produto);
            setEstoqueInicial(
              response.data.item_estoque[countItemEstoque - 1].quantidade
            );
            // console.log(response.data.item_estoque);
          })
          .catch((error) => {
            console.log(error);
          });
      })();
    }
  }, []);
  /**
  async function handleRemove(id) {
    await api
      .delete(`itemestoque/${id}`)
      .then(() => {
        console.log('removido');
        setItemEstoque(itemEstoque.filter((item) => item.id !== id));
      })
      .catch((error) => console.log(error));
  }
 */
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="btn btn-pink mr-2"
            >
              <MdArrowBack />
            </button>
            <h1 className="text-2xl text-pink-500 text-center">Estoque</h1>
            <div className="px-8" />
          </div>

          <div className="flex flex-col sm:flex-row">
            <div className="w-full sm:w-1/2 shadow rounded m-1 p-5 bg-white">
              {/** */}
              <div className="flex justify-between">
                <div className="mb-2">
                  <label className="text-sm text-pink-500">Código</label>
                  <p>{produto.codigo_barras}</p>
                </div>
                <div className="mb-2">
                  <label className="text-sm text-pink-500">Produto</label>
                  <p>{produto.produto}</p>
                </div>
                <button
                  onClick={() =>
                    history.push('/produtos/estoque/editar', {
                      estoque,
                      produto: produto.produto,
                      codigo_barras: produto.codigo_barras,
                    })
                  }
                  type="button"
                  className="btn btn-pink py-1 h-8"
                >
                  Editar
                </button>
              </div>
              {/** */}
              <div className="flex justify-between">
                <div className="mb-2">
                  <label className="text-sm text-pink-500">Descrição</label>
                  <p>{estoque.descricao}</p>
                </div>
                <div className="mb-2 mr-10">
                  <label className="text-sm text-pink-500">Status</label>
                  <p>{(Number(estoque.ativo) === 1 && 'Ativo') || 'Inativo'}</p>
                </div>
              </div>
              {/** */}
              <div className="mb-2 mr-10">
                <label className="text-sm text-pink-500">
                  Local Armazenamento
                </label>
                <p>{estoque.local_armazenamento}</p>
              </div>
              {/** */}
              <div className="mb-2 mr-10">
                <label className="text-sm text-pink-500">Observação</label>
                <p>{estoque.observacao}</p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 shadow rounded m-1 p-5 bg-white">
              <div className="flex py-5 border border-pink-300 border-l-0 border-r-0 border-t-0">
                <div className="mb-2 mr-5">
                  <label className="text-sm text-pink-500">Mínimo</label>
                  <p>{estoque.quantidade_min ? estoque.quantidade_min : 0}</p>
                </div>
                {/** */}
                <div className="mb-2 mr-5">
                  <label className="text-sm text-pink-500">Máximo</label>
                  <p>{estoque.quantidade_max ? estoque.quantidade_max : 0}</p>
                </div>
                {/** */}
                <div className="mb-2 mr-5">
                  <label className="text-sm text-pink-500">Inicial</label>
                  <p>{estoqueInicial}</p>
                </div>
              </div>
              <div className="flex justify-content-around">
                <div className="w-1/2">
                  <div className="mb-2 mr-5">
                    <label className="text-sm text-pink-500">Entrou</label>
                    <p className="text-xl text-green-600">
                      +
                      {Number(
                        estoque.estoque_entrada ? estoque.estoque_entrada : 0
                      )}
                    </p>
                  </div>
                  {/** */}
                  <div className="mb-2 mr-5">
                    <label className="text-sm text-pink-500">Saiu</label>
                    <p className="text-xl text-red-600">
                      {Number(
                        estoque.estoque_saida ? estoque.estoque_saida : 0
                      )}
                    </p>
                  </div>
                </div>
                {/** */}
                <div className="mb-2 mr-5 w-1/2">
                  <label className="text-sm text-pink-500">Estoque Atual</label>
                  <p className="text-blue-700 text-3xl">
                    {Number(estoque.estoque_total ? estoque.estoque_total : 0)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex">
            <button
              type="button"
              onClick={() =>
                history.push('/produtos/estoque/lancamento', {
                  codigo_barras: estoque.codigo_barras,
                  estoque_id: estoque.id,
                  produto: produto.produto,
                })
              }
              className="btn btn-pink flex items-center"
            >
              <MdAdd />
              Lançamento
            </button>
          </div>

          <MaterialTable
            title="Estoque"
            columns={[
              {
                title: 'Id',
                field: 'id',
                // hidden: true,
              },
              {
                title: 'data',
                field: 'created_at',
                type: 'datetime',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
              {
                title: 'Mov.',
                field: 'tipo_movimento',
                render: (rowData) => (
                  <div>
                    {(rowData.tipo_movimento === 'entrada' && (
                      <p className="flex content-center">
                        <BsArrowDown
                          title="Entrada estoque"
                          className="text-green-600 text-xl font-bold"
                        />
                        <span className="pl-1">{rowData.tipo_movimento}</span>
                      </p>
                    )) || (
                      <p className="flex content-center">
                        <BsArrowUp
                          title="saida estoque"
                          className="text-red-600 text-xl font-bold"
                        />
                        <span className="pl-1">{rowData.tipo_movimento}</span>
                      </p>
                    )}
                  </div>
                ),
              },
              {
                title: 'Quantidade',
                field: 'quantidade',
                type: 'numeric',
              },
              {
                title: 'Descricao',
                field: 'descricao',
                cellStyle: {
                  whiteSpace: 'nowrap',
                },
              },
            ]}
            data={itemEstoque}
            actions={
              [
                /*
              (rowData) => ({
                icon: "delete",
                tooltip: "Remover Grupo",
                onClick: (event, rowData) => {
                  handleRemove(rowData.id);
                },
                disabled: rowData.birthYear < 2000,
              }),
              */
              ]
            }
            options={{
              rowStyle: {
                wordWrap: 'break-word',
                width: 50,
              },
              actionsColumnIndex: 0,
              exportButton: true,
              tableLayout: 'auto',
              padding: 'dense',
            }}
            // other props
            localization={{
              pagination: {
                labelRowsSelect: 'Linhas',
                labelDisplayedRows: '{from}-{to} de {count}',
                firstTooltip: 'Primeiro',
                previousTooltip: 'Voltar',
                nextTooltip: 'Próxima',
                lastTooltip: 'Ultimo',
              },
              toolbar: {
                exportTitle: 'Exportar',
                exportName: 'Exportar CSV',
                searchPlaceholder: 'Buscar',
                nRowsSelected: '{0} linha(s) selecionada(s)',
              },
              header: {
                actions: 'Ações',
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro',
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
