import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import api from './services/api';

import Login from './pages/Login';
import Home from './pages/Home';

import Clientes from './pages/Clientes';
import ClienteForm from './pages/Clientes/ClienteForm';
import ClienteDetalhes from './pages/Clientes/Detalhes';
import RegCompras from './pages/Clientes/RegCompras';

import Fornecedores from './pages/Fornecedores';
import FornecedorForm from './pages/Fornecedores/FornecedorForm';
import FornecedorDetalhes from './pages/Fornecedores/Detalhes';

import Produtos from './pages/Produtos';
import ProdutoForm from './pages/Produtos/ProdutoForm';
import ProdutoDetalhes from './pages/Produtos/Detalhes';

import Estoque from './pages/Produtos/Estoque';
import EstoqueLancamento from './pages/Produtos/Estoque/EstoqueLancamento';
import EstoqueEdit from './pages/Produtos/Estoque/EstoqueEdit';

import GrupoProdutos from './pages/Produtos/Grupos';
import GrupoProdutoForm from './pages/Produtos/Grupos/GrupoForm';

import Caixas from './pages/Caixas';
import Caixa from './pages/Caixas/Caixa';
import Lancamento from './pages/Caixas/Caixa/Lancamento';

import Comandas from './pages/Comandas';

import Colaboradores from './pages/Colaboradores';
import ColaboradorForm from './pages/Colaboradores/ColaboradorForm';

import Perfil from './pages/Definicoes/Perfil';
import Definicoes from './pages/Definicoes';
import LojaForm from './pages/Definicoes/LojaForm';
import EmpresaForm from './pages/Definicoes/EmpresaForm';

import Cargos from './pages/Cargos';
import CargoForm from './pages/Cargos/CargoForm';

import FormasPagamento from './pages/FormasPagamento';
import FormasPagamentoForm from './pages/FormasPagamento/FomasPagamentoForm';

import Promocoes from './pages/Promocoes';
import PromocaoForm from './pages/Promocoes/PromocaoForm';

import BarCode from './pages/BarCode';
import Relatorios from './pages/Relatorios';

function CustomRouter({ isPrivate = false, ...rest }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  api.defaults.headers.Authorization = `Bearer ${auth.token}`;

  useEffect(() => {
    if (api.auth) {
      return <Redirect to="/login" />;
    }
  }, [auth]);

  if (isPrivate && !auth.autenticated) {
    return <Redirect to="/login" />;
  }
  if (!isPrivate && !!auth.autenticated) {
    return <Redirect to="/" />;
  }
  return <Route {...rest} />;
}

export default function Routes() {
  const auth = useSelector((state) => state.auth);
  return (
    <BrowserRouter>
      <Switch>
        <CustomRouter path="/login" component={Login} />
        <CustomRouter isPrivate path="/" component={Home} exact />
        {/* autorizado ao funcionário */}
        <CustomRouter isPrivate path="/clientes" component={Clientes} exact />
        <CustomRouter
          isPrivate
          path="/clientes/cliente"
          component={ClienteForm}
        />
        <CustomRouter
          isPrivate
          path="/clientes/detalhes"
          component={ClienteDetalhes}
        />
        <CustomRouter
          isPrivate
          path="/clientes/compras"
          component={RegCompras}
        />
        {/** sessão caixa */}
        <CustomRouter isPrivate path="/caixas" component={Caixas} exact />
        <CustomRouter isPrivate path="/caixas/caixa" component={Caixa} exact />
        <CustomRouter
          isPrivate
          path="/caixas/caixa/lancamento"
          component={Lancamento}
          exact
        />
        <CustomRouter
          isPrivate
          path="/caixas/caixa/lancamento/barcode"
          component={BarCode}
        />
        <CustomRouter
          isPrivate
          path="/produtos/produto/barcode"
          component={BarCode}
        />
        <CustomRouter isPrivate path="/perfil" component={Perfil} />
        {!auth.autenticated && (
          <CustomRouter component={() => <Redirect to="/login" />} />
        )}
        {Number(auth.data.nivelAcesso) === 2 && (
          <>
            {/** */}
            <CustomRouter
              isPrivate
              path="/fornecedores"
              component={Fornecedores}
              exact
            />
            <CustomRouter
              isPrivate
              path="/fornecedores/fornecedor"
              component={FornecedorForm}
            />
            <CustomRouter
              isPrivate
              path="/fornecedores/detalhes"
              component={FornecedorDetalhes}
            />
            {/** */}
            <CustomRouter
              isPrivate
              path="/produtos"
              component={Produtos}
              exact
            />
            <CustomRouter
              isPrivate
              path="/produtos/produto"
              component={ProdutoForm}
            />
            <CustomRouter
              isPrivate
              path="/produtos/detalhes"
              component={ProdutoDetalhes}
            />
            {/** */}
            <CustomRouter
              isPrivate
              path="/produtos/estoque"
              component={Estoque}
              exact
            />
            <CustomRouter
              isPrivate
              path="/produtos/estoque/lancamento"
              component={EstoqueLancamento}
            />
            <CustomRouter
              isPrivate
              path="/produtos/estoque/editar"
              component={EstoqueEdit}
            />
            {/** */}
            <CustomRouter
              isPrivate
              path="/produtos/grupos"
              component={GrupoProdutos}
              exact
            />
            <CustomRouter
              isPrivate
              path="/produtos/grupos/grupo"
              component={GrupoProdutoForm}
            />
            <CustomRouter isPrivate path="/comandas" component={Comandas} />
            {/** */}
            <CustomRouter
              isPrivate
              path="/colaboradores"
              component={Colaboradores}
              exact
            />
            <CustomRouter
              isPrivate
              path="/colaboradores/colaborador"
              component={ColaboradorForm}
            />
            {/** */}
            <CustomRouter isPrivate path="/definicoes" component={Definicoes} />
            <CustomRouter isPrivate path="/lojaform" component={LojaForm} />
            <CustomRouter
              isPrivate
              path="/empresaform"
              component={EmpresaForm}
            />
            {/** */}
            <CustomRouter isPrivate path="/cargos" component={Cargos} exact />
            <CustomRouter
              isPrivate
              path="/cargos/cargo"
              component={CargoForm}
            />
            {/** */}
            <CustomRouter
              isPrivate
              path="/formaspagamento"
              component={FormasPagamento}
              exact
            />
            <CustomRouter
              isPrivate
              path="/formaspagamento/forma"
              component={FormasPagamentoForm}
            />
            {/** */}
            <CustomRouter
              isPrivate
              path="/promocoes"
              component={Promocoes}
              exact
            />
            <CustomRouter
              isPrivate
              path="/promocoes/promocao"
              component={PromocaoForm}
            />
            <CustomRouter isPrivate path="/relatorio" component={Relatorios} />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}
