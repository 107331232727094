/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdSave, MdArrowBack } from 'react-icons/md';
import Spinner from 'react-md-spinner';

import NavBar from '../../components/NavBar';

import api from '../../services/api';

export default function CargoForm() {
  const history = useHistory();

  const [id, setId] = useState();
  const [cargo, setCargo] = useState();
  const [descricao, setDescricao] = useState();
  const [funcao, setFuncao] = useState();
  const [nivelAcesso, setNivelAcesso] = useState(1);
  const [ativo, setAtivo] = useState(true);

  const [load, setLoad] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const {
        id,
        cargo,
        descricao,
        funcao,
        nivel_acesso,
        ativo,
      } = location.state.data;
      setId(id);
      setCargo(cargo);
      setDescricao(descricao);
      setFuncao(funcao);
      setNivelAcesso(nivel_acesso);
      setAtivo(ativo);
    }
  }, []);

  async function add(data) {
    setLoad(true);
    await api
      .post(`cargo`, data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoad(false);
      });
  }
  async function up(id, data) {
    setLoad(true);
    await api
      .put(`cargo/${id}`, data)
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const data = {
      cargo,
      descricao,
      funcao,
      nivel_acesso: nivelAcesso,
      ativo,
    };
    if (id) {
      up(id, data);
    } else {
      add(data);
    }
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20">
        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="btn btn-pink"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-pink-500">Cadastrar Gargos</h1>
          <div className="mx-8" />
        </div>
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
          <label className="block text-sm text-pink-500">Cargo</label>
          <input
            type="text"
            placeholder=""
            className="block input-text shadow"
            required
            value={cargo}
            onChange={(e) => setCargo(e.target.value)}
          />
          <label className="block text-sm text-pink-500">Função</label>
          <input
            type="text"
            placeholder=""
            className="block input-text shadow"
            value={funcao}
            onChange={(e) => setFuncao(e.target.value)}
          />
          <label className="block text-sm text-pink-500">Descrição</label>
          <input
            type="text"
            placeholder=""
            className="block input-text shadow"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
          <div className="flex xl:max-w-3xl">
            <div className="w-1/2 px-1 py-2">
              <label className="block text-sm text-pink-500">
                Nivel Acesso
              </label>
              <div className="flex items-center ">
                <div className="relative w-full">
                  <select
                    className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                    id="grid-state"
                    value={nivelAcesso}
                    onChange={(e) => setNivelAcesso(e.target.value)}
                  >
                    <option value="2">Admin</option>
                    <option value="1">Vendedor</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 mx-1 my-2">
              <label className="block text-sm text-pink-500">Ativo</label>

              <div className="relative w-full">
                <select
                  className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                  id="grid-state"
                  value={ativo}
                  onChange={(e) => setAtivo(e.target.value)}
                >
                  <option value="1">Ativo</option>
                  <option value="0">Inativo</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <button
            disabled={load}
            type="submit"
            className={`btn btn-pink flex items-center justify-center w-full ${
              load && 'disabled'
            }`}
          >
            {(load && (
              <div className="flex justify-center items-center">
                <Spinner
                  className="mx-1"
                  size={24}
                  singleColor="#fbb6ce"
                  borderSize={4}
                />
                aguarde...
              </div>
            )) || (
              <>
                <MdSave />
                <span>Salvar</span>
              </>
            )}
          </button>
        </form>
      </div>
    </>
  );
}
