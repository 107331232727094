import { combineReducers } from 'redux';

import auth from './auth';
import caixaAberto from './caixaAberto';
import produtoBarCode from './produtoBarCode';

export default combineReducers({
  auth,
  caixaAberto,
  produtoBarCode,
});
