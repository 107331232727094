import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { MdAdd, MdSave, MdArrowBack } from "react-icons/md";

import api from "../../../services/api";
import NavBar from "../../../components/NavBar";

export default function Grupos({ history }) {
  const [grupoProdutos, setGrupoProdutos] = useState([]);

  useEffect(() => {
    (async function loadCategorias() {
      await api
        .get("grupoproduto")
        .then((response) => setGrupoProdutos(response.data))
        .catch((error) => console.log(error));
    })();
  }, []);

  async function handleRemove(id) {
    await api
      .delete(`grupoproduto/${id}`)
      .then(() => {
        setGrupoProdutos(
          grupoProdutos.filter((grupoProduto) => grupoProduto.id !== id)
        );
      })
      .catch((error) => console.log(error));
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto p-2 pt-20">
        <div className="max-w-2xl mx-auto">
          <div className="flex">
            <button onClick={() => history.goBack()} className="btn btn-pink mr-2">
              <MdArrowBack />
            </button>
            <button
              onClick={() => history.push("grupos/grupo")}
              className="btn btn-pink flex items-center"
            >
              <MdAdd />
              Novo
            </button>
          </div>

          <MaterialTable
            title="Grupo Produtos"
            columns={[
              {
                title: "Id",
                field: "id",
                hidden: true,
              },
              {
                title: "Grupo",
                field: "grupo",
              },
              {
                title: "Ativo",
                field: "ativo",
                lookup: { 1: "Ativo", 0: "Inativo" },
              },
            ]}
            data={grupoProdutos}
            actions={[
              {
                icon: "edit",
                tooltip: "Editar Usuário",
                onClick: (event, rowData) =>
                  history.push("grupos/grupo", {
                    data: rowData,
                  }),
              },
              (rowData) => ({
                icon: "delete",
                tooltip: "Remover Grupo",
                onClick: (event, rowData) => {
                  handleRemove(rowData.id);
                },
                disabled: rowData.birthYear < 2000,
              }),
            ]}
            options={{
              actionsColumnIndex: 0,
              exportButton: true,
            }}
            // other props
            localization={{
              pagination: {
                labelRowsSelect: "Linhas",
                labelDisplayedRows: "{from}-{to} de {count}",
                firstTooltip: "Primeiro",
                previousTooltip: "Voltar",
                nextTooltip: "Próxima",
                lastTooltip: "Ultimo",
              },
              toolbar: {
                exportTitle: "Exportar",
                exportName: "Exportar CSV",
                searchPlaceholder: "Buscar",
                nRowsSelected: "{0} linha(s) selecionada(s)",
              },
              header: {
                actions: "Ações",
              },
              body: {
                emptyDataSourceMessage: "Não há registros a serem exibidos",
                filterRow: {
                  filterTooltip: "Filtro",
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
