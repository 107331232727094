import React, { useState, useLayoutEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import NavBar from '../../components/NavBar';
import StepsIndicators from '../../components/StepsIndicatorsProd';

import Produto from './Produto';
import Estoque from './EstoqueInicial';

export default function ProdutoForm() {
  const history = useHistory();

  const [step, setStep] = useState(1);

  const [produto, setProduto] = useState({});
  const [estoque, setEstoque] = useState({});

  const [edit, setEdit] = useState();

  const location = useLocation();

  useLayoutEffect(() => {
    if (location.state) {
      const data = location.state;
      setEdit(data.edit);
      // console.log(data.edit, data.data);

      switch (data.edit) {
        case 'produto':
          setProduto(data.data);
          setStep(1);
          break;
        case 'estoque':
          setEstoque(data.data);
          setStep(2);
          break;

        default:
          break;
      }
    }
  }, []);

  function nextStep() {
    setStep(step + 1);
  }

  function Steps() {
    switch (step) {
      case 1:
        return (
          <Produto
            setProdutoForm={setProduto}
            produtoForm={produto}
            nextStep={nextStep}
            history={history}
          />
        );
      case 2:
        return (
          <Estoque
            setEstoque={setEstoque}
            produto={produto}
            estoque={estoque}
            nextStep={nextStep}
            history={history}
          />
        );

      default:
        break;
    }
  }

  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-20 max-w-3xl">
        <div className="flex justify-between items-center px-2">
          <button
            onClick={() => history.goBack()}
            type="button"
            className="btn btn-pink"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-pink-500 text-center">
            Cadastrar Produto
          </h1>
          <div className="mx4-4" />
        </div>
        {!edit && (
          <StepsIndicators step={step} setStep={setStep} form="Produto" />
        )}

        <Steps />
      </div>
    </>
  );
}
