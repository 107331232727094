/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdSave, MdArrowBack } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import Spinner from 'react-md-spinner';

import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Avatar from '../../components/Avatar';

import perfil from '../../assets/user-woman.svg';

export default function ColaboradorForm() {
  const history = useHistory();
  const location = useLocation();

  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [endereco, setEndereco] = useState('');
  const [contato1, setContato1] = useState('');
  const [contato2, setContato2] = useState('');
  const [email, setEmail] = useState('');
  const [foto, setFoto] = useState('');
  const [fotoPreview, setFotoPreview] = useState('');
  const [observacao, setObservacao] = useState('');
  const [status, setStatus] = useState(1);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [lojaId, setLojaId] = useState(0);
  const [cargoId, setCargoId] = useState(0);

  const [cargos, setCargos] = useState([]);

  const [load, setLoad] = useState(false);
  const [loadCargos, setLoadCargos] = useState(false);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (location.state) {
      // console.log(location.state.data);
      const {
        id,
        nome,
        endereco,
        contato1,
        contato2,
        email,
        foto,
        observacao,
        status,
        cargo_id,
        users,
      } = location.state.data;
      setId(id);
      setNome(nome);
      setEndereco(endereco);
      setContato1(contato1);
      setContato2(contato2);
      setEmail(email);
      setFotoPreview(`${process.env.REACT_APP_BASE_URL_STORAGE}/${foto}`);
      setObservacao(observacao);
      setStatus(status);
      setUserName(users.user_name);
      setCargoId(cargo_id);
      // console.log(users);
    }
  }, []);

  useLayoutEffect(() => {
    (async function loadCargos() {
      setLoadCargos(true);
      await api
        .get('cargo')
        .then((response) => {
          setCargos(response.data);
          setCargoId(response.data[0].id);
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          setLoadCargos(false);
        });
    })();
  }, []);

  async function add(data) {
    setLoad(true);
    await api
      .post('colaborador', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  async function up(data, id) {
    setLoad(true);
    await api
      .post(`colaborador/up/${id}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        console.log(response.data);
        history.goBack();
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('loja_id', auth.data.lojaId);
    formData.append('cargo_id', cargoId);
    formData.append('nome', nome);
    formData.append('endereco', endereco);
    formData.append('contato1', contato1.replace(/[^0-9]+/g, ''));
    formData.append('contato2', contato2.replace(/[^0-9]+/g, ''));
    formData.append('email', email);
    formData.append('status', status);
    formData.append('observacao', observacao);

    if (password) {
      formData.append('user_name', userName);
      formData.append('password', password);
      formData.append('password_confirmation', passwordConfirmation);
    }

    if (foto) {
      formData.append('foto', foto);
    }
    if (id) {
      up(formData, id);
    } else {
      add(formData);
    }
  }
  return (
    <>
      <NavBar />
      <div className="container mx-auto pt-20 text-gray-700">
        <div className="flex justify-between max-w-6xl mx-auto px-4 items-center">
          <button
            type="button"
            onClick={() => history.goBack()}
            className="flex btn btn-pink items-center"
          >
            <MdArrowBack />
          </button>
          <h1 className="text-2xl text-center text-pink-500 font-semibold -ml-16">
            Cadastro Funcionário
          </h1>
          <div />
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row">
          <div className="p-4 pb-0 w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/4">
            <Avatar
              perfil={fotoPreview || perfil}
              wh="w-32 h-32 xl:w-40 xl:h-40"
              brd="border-4"
              mx="mx-auto"
            />
            <div className="flex w-32 mx-auto text-base items-center justify-center bg-grey-lighter my-2">
              <label className="w-64 flex flex-col items-center px-4 py-1 bg-white text-pink-500 rounded-lg shadow-lg tracking-wide uppercase border border-pink-400 cursor-pointer hover:bg-pink-400 hover:text-white">
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-1 text-sm leading-normal">Carregar</span>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    setFoto(e.target.files[0]);
                    setFotoPreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </label>
            </div>
          </div>
          <div className="p-4 w-full xl:max-w-3xl">
            <label className="block text-sm text-pink-500">Nome</label>
            <input
              type="text"
              placeholder="ex. João Fernandes"
              className="block input-text shadow"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <label className="block text-sm text-pink-500">
              Endereço Completo
            </label>
            <input
              type="text"
              placeholder="ex. Rua Antônio Azevedo, 300, centro, São Benedito"
              className="block input-text shadow"
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
            />
            <label className="block text-sm text-pink-500">Email</label>
            <input
              type="email"
              placeholder="ex. joaofernandes@email.com"
              className="block input-text shadow"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="flex w-full xl:max-w-3xl">
              <div className="w-1/2 pr-2">
                <label className="block text-sm text-pink-500">Contato 1</label>
                <NumberFormat
                  format="(##) #####-####"
                  mask="_"
                  displayType="number"
                  placeholder="(88) 90000-0000"
                  className="block input-text shadow"
                  value={contato1}
                  onChange={(e) => setContato1(e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm text-pink-500">Contato 2</label>
                <NumberFormat
                  format="(##) #####-####"
                  mask="_"
                  displayType="number"
                  placeholder="(88) 9 0000-0000"
                  className="block input-text shadow"
                  value={contato2}
                  onChange={(e) => setContato2(e.target.value)}
                />
              </div>
            </div>

            <div className="flex xl:max-w-3xl">
              <div className="w-1/2 px-1 py-2">
                <label className="block text-sm text-pink-500">Cargo</label>

                <div className="relative w-full">
                  <select
                    className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                    id="grid-state"
                    value={cargoId}
                    onChange={(e) => setCargoId(e.target.value)}
                  >
                    {cargos.map((cargo) => (
                      <option key={cargo.id} value={cargo.id}>
                        {cargo.cargo}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="w-1/2 mx-1 my-2">
                <label className="block text-sm text-pink-500">Status</label>

                <div className="relative w-full">
                  <select
                    className="block rounded-lg shadow bg-white appearance-none w-full text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
                    id="grid-state"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="1">Ativo</option>
                    <option value="2">Inativo</option>
                    <option value="3">Licensa</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex py-2 my-1 border border-l-0 border-r-0  border-opacity-50 border-pink-400 xl:max-w-3xl">
              <div className="w-1/2 px-1 py-2">
                <label className="block text-sm text-pink-500">Usuário</label>
                <input
                  disabled={!!id}
                  type="text"
                  placeholder="joao02"
                  className={`block input-text shadow mb-3 ${
                    id ? 'text-gray-600' : ''
                  }`}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="w-1/2 px-1 py-2">
                <label className="block text-sm text-pink-500">Senha</label>
                <input
                  disabled={!!id}
                  type="password"
                  placeholder="********"
                  className={`block input-text shadow mb-3 ${
                    id ? 'text-gray-600' : ''
                  }`}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="w-1/2 px-1 py-2">
                <label className="block text-sm text-pink-500">
                  Confirmação Senha
                </label>
                <input
                  disabled={!!id}
                  type="password"
                  placeholder="********"
                  className={`block input-text shadow mb-3 ${
                    id ? 'text-gray-600' : ''
                  }`}
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </div>
            </div>

            <label className="block text-sm text-pink-500">Observações</label>
            <textarea
              rows="4"
              cols="50"
              placeholder="ex. Defite de atenção"
              className="block resize-none input-text shadow mb-3 h-24 min-h-20"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
            />
            <div>
              <button
                disabled={load}
                type="submit"
                className={`btn btn-pink flex items-center justify-center w-full ${
                  load && 'disabled'
                }`}
              >
                {(load && (
                  <div className="flex justify-center items-center">
                    <Spinner
                      className="mx-1"
                      size={24}
                      singleColor="#fbb6ce"
                      borderSize={4}
                    />
                    aguarde...
                  </div>
                )) || (
                  <>
                    <MdSave />
                    <span>Salvar</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
