import React, { memo, useEffect } from 'react';
import Quagga from 'quagga';
import { useDispatch } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { addProd } from '../store/actions/produtoBarCode';
import scanMarker from '../assets/scan-marker.svg';
import logoLoja from '../assets/logo-loja.svg';

function BarCode() {
  const history = useHistory();
  const dispatch = useDispatch();
  // const produtoBarCode = useSelector((state) => state.produtoBarCode);

  let cont = 0;
  const onDetected = (result) => {
    Quagga.offDetected(onDetected);
    cont += 1;
    const cod = result.codeResult.code;
    dispatch(addProd(cod));
    if (cont === 1) history.goBack();
    console.log(cont);
  };

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: document.getElementById('video'),
            contraints: {
              facingMode: 'environment',
            },
          },
          numOfWorkers: 1,
          locate: true,
          decoder: {
            readers: ['code_128_reader'],
          },
        },
        (err) => {
          if (err) {
            console.log(err);
            alert(
              'Erro ao abrir a camera do dispositivo, por favor dê permição de uso.'
            );
            return;
          }
          Quagga.start();
        },
        Quagga.onDetected(onDetected)
      );
    }
  }, []);
  useEffect(() => {
    return function cleanup() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        Quagga.stop();
      }
    };
  });

  return (
    <>
      <div className="" id="video" />
      <div className="container mx-auto fixed inset-0 flex flex-col justify-center items-center">
        <button
          onClick={() => history.goBack()}
          type="button"
          className="btn btn-pink fixed left-0 top-0 m-3"
        >
          <MdArrowBack />
        </button>

        <div className="max-w-sm mx-auto object-contain">
          <img src={scanMarker} alt="Scan marker" />
        </div>

        <div className="object-contain w-12 h-12 fixed bottom-0 mb-1">
          <img src={logoLoja} alt="Gestor de lojas" />
        </div>
      </div>
    </>
  );
}
export default memo(BarCode);
