import React, { memo, useEffect } from 'react';
import { MdClose } from 'react-icons/md';

function CardModal({
  isDialogOpen = false,
  setIsDialogOpen = false,
  titulo = 'Titulo do modal',
  style = 'max-w-lg',
  children = 'Conteúdo',
}) {
  useEffect(() => {
    if (isDialogOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isDialogOpen]);

  return (
    <>
      {/** <!-- overlay --> */}
      <div
        className={` overflow-auto pt-2 bg-black bg-opacity-25 ${
          (isDialogOpen &&
            'fixed inset-0 z-20 flex items-start justify-center visibility') ||
          'hidden'
        }`}
      >
        {/** <!-- dialog --> */}
        <div
          className={`bg-white shadow-2xl mt-4 mb-2 m-2 sm:mt-8 rounded ${style}`}
        >
          <div className="flex justify-between items-center pt-2 pl-3 pr-2 text-xl">
            <h6 className="text-lg">{titulo}</h6>
            <button
              type="button"
              className="p-2"
              onClick={() => setIsDialogOpen(!isDialogOpen)}
            >
              <MdClose />
            </button>
          </div>
          <div className="pt-2">
            {/** <!-- content --> */}
            {children}
          </div>
        </div>
        {/** <!-- /dialog --> */}
      </div>
      {/* <!-- /overlay -->   */}
    </>
  );
}

export default memo(CardModal);
