import React, { memo } from 'react';

const Avatar = ({ perfil = '', wh = '', brd = '', mx = '' }) => (
  <div
    className={`${wh} ${wh} rounded-full overflow-hidden ${brd} border-pink-500 ${mx} shadow-lg `}
  >
    <img
      src={perfil}
      alt="perfil"
      className="object-cover object-center h-full w-full"
    />
  </div>
);
export default memo(Avatar);
